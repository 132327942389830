@charset "UTF-8";
/*$color-verde: #4caf50;*/
/*$color-rojo: #ff6347;
$color-verde: #009781;
$color-azul: $color-principal;
$color-amarillo: #FDAB3D;
$color-naranja: #ff6e40;
$color-morado: #6f27b0;*/
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,100..900;1,100..900&display=swap");
body,
html {
  height: 100%;
  overflow: auto;
}

.footer {
  bottom: 0;
  height: 2em;
  left: 0;
  position: relative;
  line-height: 22px;
}

body {
  font-family: "Roboto", sans-serif;
  background-color: #f7f7f7;
  transition: all 0.3s ease-in-out;
  /*position: relative;
  padding-bottom: 50px;*/
}

/*
.text-center.mt-4.copyright-cont {
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
}*/
/*.copyright-danobat a {
  color: #0000e6 !important;
}*/
/*login*/
.login-container {
  margin: 0 50px 0 0;
}

.menu-zabalduta .login-container {
  margin: 0 250px 0 0;
}

.card.login {
  width: 275px;
  margin: 125px auto 0 auto;
  background-color: rgba(241, 241, 241, 0.39);
}

.login-logo {
  margin: 35px auto 10px auto;
  height: 75px;
  width: 191px;
  float: none;
  background-image: url(images/logo.png);
  background-position: left;
  background-repeat: no-repeat;
  transition: 0.3s ease all;
  background-size: cover;
}

/*.login-logo-danobat {
  width: 80%;
  margin: 30px auto 10px auto;
  display: block;
}*/
.card.login .card-header {
  text-align: center;
}

.card.login button {
  padding: 10px;
  border-radius: 5px;
  margin-top: 15px;
}

#particles-js {
  /*padding-top: 150px;*/
  padding-right: 0px;
  /*padding-bottom: 150px;*/
  padding-left: 0px;
  margin-top: 0px;
  margin-right: 0px;
  margin-bottom: 0px;
  margin-left: 0px;
  background-color: #1f232b;
  box-shadow: 0 0 0 0 #ffffff;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.bg-login {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-image: url(images/eris-bg.jpg);
  background-size: cover;
}

/*DANOBAT*/
/*
.card.login button.btn.mr-1.btn-success.w-100.k-button {
  background-color: #01005d !important;
  border-color: #01005d !important;
}

.card.login h3.card-header {
  color: #01005d !important;
}

*/
/*DANOBAT END*/
/*login amaiera*/
/*Comparativa tiempos*/
.horasPredictivas-bloque .card,
.horasEstimadas-bloque .card {
  background-color: #54bfd3 !important;
  color: #f5f5f5;
}

.horasPredctivasReales-bloque .card,
.horasReales-bloque .card {
  background-color: #f2a225 !important;
  color: #f5f5f5;
}

.desvioPredictivas-bloque .card,
.desvio-bloque .card {
  background-color: #64a996 !important;
  color: #f5f5f5;
}

.porcentajePredictivas-bloque .card,
.porcentaje-bloque .card {
  background-color: #ee8625 !important;
  color: #f5f5f5;
}

/*Comparativa tiempos amaiera*/
/*INFORME ELÉCTRICO*/
.consumo-total-lista td.k-touch-action-auto {
  padding: 2px 0 2px 10px;
}

.consumo-total-lista .k-grid-table-wrap tr.k-alt, .consumo-total-lista .k-grid-table-wrap tr {
  background-color: transparent;
}

.consumo-total-lista-header {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  background-color: #f7f7f7;
  padding: 10px;
}

.cont-icono-electrico i.icon-consumo {
  font-size: 45px;
  color: #03a9f4;
}

label.datos-electricos-label {
  color: #009680;
}

label.dato-electrico-top {
  font-size: 20px;
}

.cont-icono-electrico {
  margin: 0 10px 0 0;
}

.card.consumo-total-lista kendo-grid.k-widget.k-grid {
  padding: 10px;
}

.card.consumo-total-lista kendo-grid.k-widget.k-grid .k-grid-content {
  overflow-y: auto;
}

.consumo-total-lista-header .cont-btn-atras button.btn {
  padding: 0;
}

.consumo-total-lista-header .cont-btn-atras {
  width: 16px;
  margin-right: 4px;
}

/*INFORME ELÉCTRICO AMAIERA*/
/*Informe tiempos*/
body div.tooltip-bloxpot {
  font-size: 11px;
  background-color: #fff;
  border: 3px solid #48328b;
  padding: 15px 5px 5px;
  z-index: 1501 !important;
  line-height: 0;
}

/*Informe tiempos amaiera*/
/*Informe incidencias*/
.icono-ficheros-incidencias {
  font-size: 16px;
  color: #22c4c4;
}

/*Informe incidencias amaiera*/
/*orokorrak*/
.invalid-dropdown > span,
.k-textbox.k-invalid.is-invalid,
.k-textbox.k-state-invalid.is-invalid,
.k-textbox.ng-invalid.is-invalid,
.k-textbox.ng-invalid.ng-dirty.is-invalid,
.k-combobox.k-state-invalid.is-invalid .k-dropdown-wrap,
.k-combobox.ng-invalid.is-invalid .k-dropdown-wrap,
.k-combobox.ng-invalid.ng-dirty.is-invalid .k-dropdown-wrap,
.k-textbox.is-invalid .k-input,
.invalid-multiselect > div,
.k-numerictextbox.is-invalid .k-numeric-wrap .k-input {
  border: solid 1px #ff6347 !important;
}

.sin-boton-x .k-clear-value {
  display: none;
}

.cont-mensaje-sindatos {
  position: absolute;
  top: 0;
  background: #f7f7f773;
  left: 0;
  right: 0;
  bottom: 0;
  text-align: center;
}

.mensaje-sindatos {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  /* vendor prefixes omitted due to brevity */
}

.invalid-feedback-dropdown,
.invalid-feedback-modal {
  display: block;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
}

.form-group {
  margin-bottom: 10px;
}

.nolabel {
  margin-top: 26px;
}

/*botoiak*/
.btn {
  border-radius: 0;
  padding: 3px 8px;
  font-size: 13px;
}

.btn:hover {
  opacity: 0.8;
}

.btn-success {
  background-color: #22c4c4;
  border-color: #22c4c4;
}

.btn-outline-success {
  color: #22c4c4;
  border-color: #22c4c4;
}

button.btn.btn-deselccionado {
  background-color: #D8D8D8;
  color: #ffffff;
}

.btn-outline-success.focus,
.btn-outline-success:focus {
  background-color: transparent;
  border-color: #22c4c4;
}

.btn-outline-success:hover {
  background-color: #22c4c4;
  border-color: #22c4c4;
}

.btn-success.focus,
.btn-success:focus,
.btn-success:hover {
  background-color: #22c4c4;
  border-color: #22c4c4;
  box-shadow: none;
}

.btn-primary {
  background-color: #4287cc;
  border-color: #4287cc;
}

.btn-outline-primary {
  color: #4287cc;
  border-color: #4287cc;
}

.btn-primary.focus,
.btn-primary:focus,
.btn-primary:hover {
  background-color: #4287cc;
  border-color: #4287cc;
}

.btn-outline-primary.focus,
.btn-outline-primary:focus,
.btn-outline-primary:hover {
  background-color: #4287cc;
  border-color: #4287cc;
}

.btn-danger.focus,
.btn-danger:focus,
.btn-danger {
  background-color: #f16a72;
  border-color: #f16a72;
}

.btn-excel {
  background-color: #28a745;
  border-color: #28a745;
  color: #ffffff;
}

.btn-outline-success:hover svg.icon-btn-planif path,
.btn-success svg.icon-btn-planif path {
  fill: white !important;
}

.btn-excel.focus,
.btn-excel:focus,
.btn-excel:hover {
  background-color: #28a745;
  border-color: #28a745;
}

.btn-verde {
  background-color: #009680;
  border-color: #009680;
  color: #ffffff;
}

.btn-outline-verde {
  color: #009680;
  border-color: #009680;
}

.btn-outline-verde.focus,
.btn-outline-verde:focus {
  background-color: transparent;
  border-color: #009680;
}

.btn-outline-verde:hover {
  background-color: #009680;
  border-color: #009680;
}

.btn-verde.focus,
.btn-verde:focus,
.btn-verde:hover {
  background-color: #009680;
  border-color: #009680;
  color: #ffffff;
}

.btn-azul {
  background-color: #03a9f4;
  border-color: #03a9f4;
  color: #ffffff;
}

.btn-outline-azul {
  color: #03a9f4;
  border-color: #03a9f4;
}

.btn-outline-azul.focus,
.btn-outline-azul:focus {
  background-color: transparent;
  border-color: #03a9f4;
}

.btn-outline-azul:hover {
  background-color: #03a9f4;
  border-color: #03a9f4;
}

.btn-azul.focus,
.btn-azul:focus,
.btn-azul:hover {
  background-color: #03a9f4;
  border-color: #03a9f4;
  color: #ffffff;
}

.btn-amarillo {
  background-color: #FDAB3D;
  border-color: #FDAB3D;
  color: #ffffff;
}

.btn-outline-amarillo {
  color: #FDAB3D;
  border-color: #FDAB3D;
}

.btn-outline-amarillo.focus,
.btn-outline-amarillo:focus {
  background-color: transparent;
  border-color: #FDAB3D;
}

.btn-outline-amarillo:hover {
  background-color: #FDAB3D;
  border-color: #FDAB3D;
}

.btn-amarillo.focus,
.btn-amarillo:focus,
.btn-amarillo:hover {
  background-color: #FDAB3D;
  border-color: #FDAB3D;
  color: #ffffff;
}

.btn-naranja {
  background-color: #ee8625;
  border-color: #ee8625;
}

.btn-outline-naranja {
  color: #ee8625;
  border-color: #ee8625;
}

.btn-outline-naranja.focus,
.btn-outline-naranja:focus {
  background-color: transparent;
  border-color: #ee8625;
}

.btn-outline-naranja:hover {
  background-color: #ee8625;
  border-color: #ee8625;
}

.btn-naranja.focus,
.btn-naranja:focus,
.btn-naranja:hover {
  background-color: #ee8625;
  border-color: #ee8625;
  color: #ffffff;
}

.btn-morado {
  background-color: #AF85C4;
  border-color: #AF85C4;
  color: #ffffff;
}

.btn-outline-morado {
  color: #AF85C4;
  border-color: #AF85C4;
}

.btn-outline-morado.focus,
.btn-outline-morado:focus {
  background-color: transparent;
  border-color: #AF85C4;
}

.btn-outline-morado:hover {
  background-color: #AF85C4;
  border-color: #AF85C4;
}

.btn-morado.focus,
.btn-morado:focus,
.btn-morado:hover {
  background-color: #AF85C4;
  border-color: #AF85C4;
  color: #ffffff;
}

.k-button-primary,
.k-button.k-primary {
  border-color: #22c4c4;
  color: #ffffff;
  background-color: #22c4c4;
}

.btn-editar {
  background-color: #36a884;
  color: #ffffff;
}

.btn-nuevo {
  background-color: #4287cc;
  color: #ffffff;
}

.btn-eliminar {
  background-color: #f16a72;
  color: #ffffff;
}

.btn-copiar {
  background-color: #AF85C4;
  color: #ffffff;
}

/*botoiak amaiera*/
.color-rojo {
  color: #ff6347;
}

.color-verde {
  color: #009680;
}

.color-azul {
  color: #03a9f4;
}

.color-amarillo {
  color: #FDAB3D;
}

.color-naranja {
  color: #ee8625;
}

.color-morado {
  color: #AF85C4;
}

.bg-rojo {
  background-color: #ff6347;
  font-size: 13px;
  line-height: 22px;
  color: #ffffff;
}

.bg-verde {
  background-color: #009680;
  color: #ffffff;
}

.bg-azul {
  background-color: #03a9f4;
}

.bg-amarillo {
  background-color: #FDAB3D;
}

.bg-naranja {
  background-color: #ee8625;
}

.bg-morado {
  background-color: #AF85C4;
}

.btn:disabled {
  opacity: 0.3;
}

.k-button-group .k-button:active,
.k-button-group .k-button.k-state-active,
.k-button-group .k-button.k-state-selected,
.k-button-group > input[type=radio]:checked + .k-button,
.k-button-group > input[type=checkbox]:checked + .k-button {
  border-color: #22c4c4;
  background-color: #22c4c4;
  z-index: 0;
}

::selection {
  background: #20c997 !important;
  /* WebKit/Blink Browsers */
}

::-moz-selection {
  background: #20c997 !important;
  /* Gecko Browsers */
}

.k-text-selection ::selection,
.k-block ::selection,
.k-panel ::selection,
.k-pane-wrapper ::selection,
.k-view ::selection,
.k-progressbar ::selection,
.k-numerictextbox ::selection,
.k-flatcolorpicker ::selection,
.k-combobox ::selection,
.k-datepicker ::selection,
.k-timepicker ::selection,
.k-datetimepicker ::selection,
.k-dateinput ::selection,
.k-dropdown ::selection,
.k-dropdowntree ::selection,
.k-multiselect ::selection,
.k-maskedtextbox ::selection,
.k-rating ::selection,
.k-window ::selection,
.k-drawer ::selection,
.k-notification ::selection,
.k-breadcrumb ::selection,
.k-pager-wrap ::selection,
.k-panelbar ::selection,
.k-card ::selection,
.k-splitter ::selection,
.k-spreadsheet ::selection,
.k-taskboard ::selection,
.k-scheduler ::selection,
.k-chat ::selection,
.k-mediaplayer ::selection,
.k-pdf-viewer ::selection,
kendo-scrollview.k-scrollview-wrap ::selection,
kendo-scrollview.k-scrollview ::selection,
div.k-scrollview ::selection,
.k-chart ::selection,
.k-sparkline ::selection,
.k-stockchart ::selection {
  background: #20c997 !important;
}

.k-tabstrip-items .k-item,
a,
a:hover {
  color: #22c4c4;
}

.k-tabstrip-top > .k-tabstrip-items .k-item {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.k-tabstrip-items .k-item,
.k-tabstrip-items .k-item {
  background-color: #f1f1f1;
  border-left: solid 1px #ebebeb;
  border-top: solid 1px #ebebeb;
}

.k-tabstrip-items .k-state-active {
  background-color: white;
}

.k-tabstrip-content:focus,
.k-tabstrip-content.k-state-focused,
.k-tabstrip > .k-content:focus,
.k-tabstrip > .k-content.k-state-focused {
  outline-width: 0;
  outline-style: dotted;
  outline-offset: 0;
}

h3 {
  font-size: 15px;
  color: #22c4c4;
  overflow: hidden;
  text-transform: uppercase;
  padding: 0 0 5px 0;
  border-bottom: solid 1px #22c4c4;
  font-family: "Roboto", sans-serif;
}

h4 {
  font-size: 15px;
  color: #3366CC;
  overflow: hidden;
  text-transform: uppercase;
  padding: 0 0 5px 0;
  border-bottom: solid 1px #3366CC;
  font-family: "Roboto", sans-serif;
}

textarea.k-textarea {
  height: 7em !important;
  resize: none !important;
}

.k-textbox,
textarea.k-textarea {
  /*width: 100% !important;*/
  /* en el filtro del grid se ve mal*/
  width: 100%;
}

.modal-header .close {
  padding: 5px;
  outline: 0;
}

.k-grid td.k-state-selected,
.k-grid tr.k-state-selected > td {
  background-color: rgba(34, 196, 196, 0.45);
}

.k-checkbox:checked {
  border-color: #22c4c4;
  background-color: #22c4c4;
}

.k-checkbox:indeterminate,
.k-checkbox.k-state-indeterminate {
  color: #22c4c4;
}

.k-input {
  font-size: 13px;
}

.k-numerictextbox .k-input {
  text-align: right;
}

.k-dropdown .k-dropdown-wrap,
.k-dropdowntree .k-dropdown-wrap {
  background-image: none;
  background: transparent;
}

kendo-textbox.k-textbox {
  width: 100%;
  height: auto;
}

kendo-textbox.k-textbox .k-input {
  padding: 0.5rem 0.75rem;
  height: 2.25rem;
}

.k-button,
.k-button:hover {
  background-image: none;
}

a.btn {
  color: #ffffff;
}

.k-radio:checked {
  border-color: #18d6b0;
  color: #ffffff;
  background-color: #18d6b0;
}

.custom-control {
  z-index: 0;
}

.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #22c4c4;
  background-color: #22c4c4;
}

.custom-control-label {
  margin-top: 5px;
  padding-left: 5px;
}

.custom-switch .custom-control-label::after {
  top: calc(.25rem + 2px) !important;
  left: -2.25rem;
  width: calc(1.5rem - 4px);
  height: calc(1.5rem - 4px);
  background-color: #adb5bd;
  border-radius: 1.5rem;
  transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  margin-top: -6px;
}

.custom-control-label::before {
  top: 4px !important;
  height: 1.5rem !important;
}

.custom-switch .custom-control-label::before {
  left: -40px !important;
  width: 2.5rem !important;
  border-radius: 1.5rem;
  margin-top: -6px;
}

.form-control {
  height: calc(1.8rem + 1px);
  font-size: 13px;
}

kendo-textbox.k-textbox .k-input,
.k-textbox.form-control {
  height: calc(1.8rem + 1px);
  padding: 0.5rem 0.75rem;
}

.k-switch-off .k-switch-container {
  color: #656565;
  background-color: #d8d8d8;
}

/*  upload*/
/*switch*/
.k-switch-container {
  width: 4em;
}

.k-switch-handle {
  width: 2em;
  height: 2em;
}

.k-switch {
  border-radius: 4em;
  width: 4em;
  margin-right: 5px;
}

.k-switch-on .k-switch-handle {
  left: calc(100% - 2em);
}

/*switch amaiera*/
kendo-grid-toolbar.k-grid-toolbar.k-toolbar.ng-star-inserted .k-upload {
  max-width: 275px;
}

.k-upload .k-dropzone {
  padding: 3px;
}

.k-upload .k-dropzone {
  background-color: transparent;
}

.k-button.k-upload-button {
  padding: 0;
}

ul.k-upload-files.k-reset.ng-star-inserted {
  background-color: whitesmoke;
}

/*
.k-upload .k-dropzone .k-button.k-upload-button {
  padding: 0px;
}

.k-upload .k-dropzone {
  padding: 0px !important;
  margin: 0 10px 0 5px;
  float: left;
}

.k-upload .k-dropzone .k-button.k-upload-button {
  margin: 3px 13px 0 0 !important;
  background: transparent;
}

li.k-file.ng-star-inserted {
  padding: 0 !important;
  margin: 0 15px !important;
}

span.k-file-name {
  float: left;
}

.k-file-information {
  float: left;
  margin: 7px 0 0 10px;
}

.k-i-file-data::before {
  font-size: 15px;
}

ul.k-upload-files.k-reset.ng-star-inserted {
  float: left;
}
*/
/*  upload amaiera*/
.k-widget.form-control {
  padding: 0;
  border: 0;
}

.k-widget.form-control .k-input {
  height: calc(1.6rem + 1px);
  padding: 0.5rem 0.75rem;
}

label {
  font-size: 13px;
  margin-bottom: 0px;
}

.k-grid-header .k-grid-filter,
.k-grid-header .k-header-column-menu {
  z-index: 0;
}

.k-numerictextbox {
  width: auto !important;
}

.k-upload {
  border: 1px solid #ced4da;
  border-radius: 3px;
}

.requerido {
  border: solid 1px #f16a72;
}

.requerido::after {
  content: "*";
  font-size: 20px;
  margin: 0px -11px 0px 0;
  color: #f16a72;
}

.numerico-soloflechas input {
  pointer-events: none;
}

span.copy-eris {
  display: block;
}

.k-pager-numbers .k-state-selected {
  z-index: 0;
}

.k-multiselect .k-multiselect-wrap:not(:hover):before,
.k-dropdowntree .k-multiselect-wrap:not(:hover):before {
  content: "";
  font-family: "WebComponentsIcons";
  float: right;
  text-align: center;
  margin: 5px -6px -5px 0;
}

.k-multiselect.selec-operario .k-multiselect-wrap:before,
.k-dropdowntree.selec-operario .k-multiselect-wrap:before {
  content: "";
  font-family: "zituikon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  float: right;
  text-align: center;
  margin: 5px -6px -5px 0;
}

.k-multiselect.k-state-focused .k-multiselect-wrap::before,
.k-dropdowntree.k-state-focused .k-multiselect-wrap::before {
  display: none;
}

.k-multiselect .k-multiselect-wrap:not(:hover) span.k-icon.k-clear-value.k-i-close {
  display: none;
}

.k-switch-on:hover .k-switch-container,
.k-switch-on.k-state-hover .k-switch-container,
.k-switch-on .k-switch-container {
  color: #ffffff;
  background-color: #22c4c4;
}

.k-combobox {
  width: 100%;
}

.alert {
  position: fixed;
  z-index: 99999;
  width: 100%;
  max-width: 1078px;
  box-shadow: -8px 12px 18px 0 rgba(25, 42, 70, 0.13);
}

ul.listaerrores li {
  list-style-type: none;
  margin: 0 0 4px 0;
  font-weight: 200;
}

ul.listaerrores {
  font-size: 14px;
}

kendo-numerictextbox {
  max-width: 10em;
}

.card.login kendo-numerictextbox {
  max-width: 100%;
}

.k-tabstrip-items .k-item:hover,
.k-tabstrip-items .k-item.k-state-hover {
  color: #22c4c4;
}

.form-group.img-up .ng-star-inserted {
  float: left;
}

.form-group.img-up kendo-fileselect.form-control.k-widget.k-upload.ng-valid.ng-dirty {
  float: left;
  width: calc(100% - 220px);
}

.k-list .k-item.k-state-selected,
.k-list-optionlabel.k-state-selected {
  background-color: #22c4c4;
}

.k-list .k-item.k-state-selected:hover,
.k-list-optionlabel.k-state-selected:hover {
  background-color: #22c4c4;
  opacity: 0.9;
}

.k-calendar .k-state-selected .k-link {
  background-color: #22c4c4;
}

.k-calendar .k-state-selected:hover .k-link,
.k-calendar .k-state-selected.k-state-hover .k-link {
  background-color: #22c4c4;
  opacity: 0.9;
}

.k-calendar .k-footer .k-nav-today,
.k-calendar .k-calendar-header .k-today {
  color: #22c4c4;
}

.k-calendar .k-calendar-navigation li:hover,
.k-calendar .k-calendar-navigation li.k-state-hover {
  color: #22c4c4;
}

.textorojo {
  color: #f16a72;
}

.columna-justificada:before {
  opacity: 0.99;
  display: block !important;
  font-style: normal;
  font-weight: 900;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: inline-block;
  font-family: "Font Awesome 5 Free";
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  visibility: visible;
  margin-right: 15px;
  transition: all 0.5s;
  content: "";
}

.columna-no-justificada:before {
  opacity: 0.99;
  display: block !important;
  font-style: normal;
  font-weight: 900;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: inline-block;
  font-family: "Font Awesome 5 Free";
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  visibility: visible;
  margin-right: 15px;
  transition: all 0.5s;
  content: "";
  color: #f16a72;
}

span.columna-justificada,
span.columna-no-justificada {
  display: block;
  height: 20px;
  width: 12px;
  overflow: hidden;
  margin: 0 auto;
}

.pe-none {
  pointer-events: none;
}

.paradas-donut-check {
  position: absolute;
  right: 10px;
  bottom: 10px;
}

.paradas-donut-check input {
  margin: 0 5px 0 0;
}

.btn-icon {
  color: #22c4c4;
  border-width: 0;
}

.activo-ico {
  color: #22c4c4;
}

.parte-ico {
  color: #229DC4;
}

.pieza-ico {
  color: #B2F21C;
}

.recambio-ico {
  color: #71C1FF;
}

/* alert {
  position: fixed;
  top: 50px;
  z-index: 10;
  left: 0px;
  right: 0px;
} */
/*tabs*/
.k-tabstrip-items-wrapper {
  flex-direction: column;
}

.k-tabstrip-top .k-tabstrip-items .k-item {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

/*tabs amaiera*/
.scroll-cont {
  width: 100%;
  overflow: hidden;
  overflow-x: scroll !important;
}

td .k-checkbox {
  width: 15px;
  height: 15px;
}

.k-slider .k-draghandle {
  border-color: #22c4c4;
  color: #ffffff;
  background-color: #22c4c4;
  border-radius: 50%;
}

.k-slider .k-slider-selection {
  background-color: #22c4c4;
}

.modal-title {
  width: 100%;
}

div#checkboxGrupos {
  top: unset !important;
  right: 45px;
  display: block;
  bottom: 8px;
  z-index: 1;
}

.btn.focus,
.btn:focus {
  outline: 0;
  box-shadow: 0 0 0 0rem rgba(0, 0, 0, 0) !important;
}

span.grid-circulo {
  background: #22c4c4;
  color: #ffffff;
  display: inline-block;
  height: 25px;
  width: 25px;
  border-radius: 20px;
  text-align: center;
  line-height: 26px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

li span.grid-circulo {
  margin-top: -7px;
  margin-bottom: -7px;
}

.k-item span.grid-circulo {
  margin-right: 5px;
}

input[type=number] {
  text-align: right;
  width: 100%;
}

/*orokorrak amaiera*/
.tabla-frecuencias-calidad p {
  margin: 0;
}

/*tipo grid*/
.tipo-grid-linea:after {
  display: block;
  clear: both;
  content: "";
}

.tipo-grid-col.col-asignatura {
  width: calc(50% - 25px);
  cursor: pointer;
  float: left;
}

.tipo-grid-linea .tipo-grid-col {
  float: left;
}

.tipo-grid-linea .tipo-grid-col.col-eliminar {
  float: right;
}

.tipo-grid-linea:nth-of-type(even) {
  background: #fbfbfb;
}

.tipo-grid-linea {
  border-bottom: solid 1px #E6E9EF;
  background-color: #f5f6f8;
  padding: 5px;
}

.tipo-grid span.grid-circulo:not(:first-child) {
  margin: 0 0 0 -15px;
}

.tipo-grid.grid-lanzadorof-grupotrabajo .col-grupotrabajo {
  width: calc(100% - 25px);
  cursor: pointer;
}

/*tipo grid amaiera*/
/*kendo-switch-tipo2*/
.kendo-switch-tipo2 {
  width: 100%;
}

.kendo-switch-tipo2 span.k-switch-container {
  width: 100%;
  border-radius: 0;
  padding: 1px 3px;
  font-size: 13px;
  color: #ffffff;
}

.kendo-switch-tipo2 span.k-switch-label-off,
.kendo-switch-tipo2 span.k-switch-label-on {
  text-align: left;
  margin: 0 0 0 18px;
  right: auto;
  display: block;
  width: 100%;
  text-transform: capitalize;
  text-wrap: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.kendo-switch-tipo2 span.k-switch-handle:before {
  width: 26px;
  opacity: 0.99;
  font-style: normal;
  font-weight: 900;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: inline-block;
  font-family: "Font Awesome 5 Free";
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  visibility: visible;
  font-size: 12px !important;
  line-height: 25px;
  transition: all 0.5s;
  content: "";
}

.kendo-switch-tipo2.k-switch-on .k-switch-handle {
  left: 0;
}

.kendo-switch-tipo2.k-switch-on .k-switch-handle:before {
  content: "";
}

.kendo-switch-tipo2.k-switch-off .k-switch-handle:before {
  content: "";
}

.kendo-switch-tipo2.k-switch-on .k-switch-handle,
.kendo-switch-tipo2.k-switch-on:hover .k-switch-handle,
.kendo-switch-tipo2.k-switch-on.k-state-hover .k-switch-handle,
.kendo-switch-tipo2.k-switch-off .k-switch-handle,
.kendo-switch-tipo2.k-switch-off:hover .k-switch-handle,
.kendo-switch-tipo2.k-switch-off.k-state-hover .k-switch-handle {
  border-color: rgba(0, 0, 0, 0);
  color: #ffffff;
  background-color: transparent;
}

.k-switch-off:hover .k-switch-container,
.k-switch-off.k-state-hover .k-switch-container {
  color: #ffffff;
  background-color: #d8d8d8;
}

/*kendo-switch-tipo2 amaiera*/
/*scroll bereziak*/
*::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #F5F5F5;
}

*::-webkit-scrollbar {
  height: 8px;
  background-color: #F5F5F5;
}

*::-webkit-scrollbar-thumb {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #cdcdcd;
}

*::-webkit-scrollbar {
  width: 10px;
}

/* Track */
*::-webkit-scrollbar-track {
  background: #253341;
}

/* Handle */
*::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
*::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/*scroll bereziak bukaera*/
.barratop {
  transition: 0.3s ease all;
  background-color: #253341;
  left: 60px;
  border-radius: 0;
  position: fixed;
  top: 0;
  right: 0;
  height: 56px;
  padding: 0;
  z-index: 5;
}

/*
.barratop-danobat {
  background-color: #0000e6 !important;
}
*/
.barratop h2 {
  margin: 0 0 0 20px;
  color: #f7f7f7;
  float: left;
  font-size: 20px;
}

/*MENU LATERAL*/
.barramenu {
  background-color: #2a3f54;
  width: 60px;
  padding: 0 0 40px 0;
  flex-flow: column;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
  overflow-y: auto;
  -webkit-transition: 0.3s ease all;
  transition: 0.3s ease all;
  z-index: 5;
}

/*
.barramenu-danobat {
  background-color: #0000a2 !important;
}
*/
/*
.barramenu-danobat li.dropdown.show>a {
  background: #01005d !important;
}
*/
.barramenu .logotipoa {
  margin: 8px 0 10px 5px;
  height: 40px;
  width: 52px;
  float: none;
  background-image: url(images/logo.png);
  background-position: left;
  background-repeat: no-repeat;
  transition: 0.3s ease all;
  background-size: cover;
}

.barramenu .nav-item .nav-link {
  color: #CAD3DD;
}

.barramenu .nav-item.link-active a.nav-link {
  color: #00c2a1;
}

.barramenu h3 {
  display: none;
  overflow: hidden;
  clear: both;
  background-color: transparent;
  color: #fff;
  margin: 15px 12px 15px 11px;
  text-transform: inherit;
  color: #CAD3DD;
  font-weight: 300;
}

.barramenu a {
  visibility: hidden;
  width: 250px;
  color: #CAD3DD;
  font-size: 13px;
}

.barramenu > .nav > li > a:before,
.barramenu .beheko-minimenu a.conf:before {
  width: 26px;
  opacity: 0.99;
  display: inline-block;
  font-style: normal;
  font-weight: 900;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: inline-block;
  font-family: "Font Awesome 5 Free";
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  visibility: visible;
  /*  width: auto;*/
  font-size: 20px !important;
  margin-right: 15px;
  transition: all 0.5s;
  /* content: "\f542";*/
}

/*
.barramenu-danobat .beheko-minimenu {
  background-color: #01005d !important;
}
*/
/*
img.logodanobat {
  margin: 0 0 0 13px;
  height: 20px;
  visibility: hidden;
}
*/
/*
.barramenu.menu-zabalduta img.logodanobat,
.barramenu:hover img.logodanobat {
  visibility: visible;
}
*/
.nav > li > a:focus,
.nav > li > a:hover {
  background-color: rgba(30, 30, 30, 0.35);
  box-shadow: 0 4px 4px 0px rgba(0, 0, 0, 0.5);
  border-right: solid 4px #22c4c4;
  color: #22c4c4 !important;
}

ul.nav {
  margin: 10px 0 0 0;
  width: 100%;
}

ul.nav li.nav-item {
  /*tabs se ven mal*/
  /*  width: 100%;*/
}

/*toggle despliegue*/
.barramenu .menu-zabalera-blokeatu::before {
  width: 26px;
  opacity: 0.99;
  display: inline-block;
  font-style: normal;
  font-weight: 900;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: inline-block;
  font-family: "Font Awesome 5 Free";
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  visibility: visible;
  width: auto;
  font-size: 15px !important;
  color: #c1cdd9;
  margin: -1px 0 0 -3px !important;
  padding: 0 !important;
}

.barramenu .menu-zabalera-blokeatu {
  display: none;
  width: 25px;
  height: 25px;
  border-radius: 50px;
  right: 0;
  position: absolute;
  margin: 10px 10px 0 0;
  padding: 0px 0 0 5px !important;
  border: solid 3px #c1cdd9;
  top: 0;
  cursor: pointer;
}

.barramenu.menu-zabalduta .menu-zabalera-blokeatu,
.barramenu:hover .menu-zabalera-blokeatu {
  display: block;
  line-height: 19px;
}

.barramenu.menu-zabalduta .menu-zabalera-blokeatu::before {
  content: "";
}

/*toggle despliegue amaiera*/
/*dropdown menu*/
.barramenu a.nav-link.dropdown-toggle::after,
.barramenu.menu-zabalduta a.nav-link.dropdown-toggle::after {
  display: inline-block;
  width: 10px;
  height: 10px;
  content: "";
  color: #fff;
  float: right;
  margin-top: 5px;
  border-bottom: solid 3px #fff;
  border-left: solid 3px #fff;
  transform: rotate(-45deg);
  -webkit-transition: 0.5s ease all;
  transition: 0.5s ease all;
  border-right-width: 0;
  border-top-width: 0;
}

.barramenu:hover .nav-item.show a.nav-link.dropdown-toggle::after,
.barramenu.menu-zabalduta .nav-item.show a.nav-link.dropdown-toggle::after {
  transform: rotate(135deg);
}

.show a.nav-link.dropdown-toggle {
  background-color: rgba(30, 30, 30, 0.35);
}

.barramenu .dropdown-menu {
  position: relative !important;
  background-color: transparent;
  -webkit-transition: 0.3s ease all;
  transition: 0.3s ease all;
  transform: translate3d(0px, 0px, 0px) !important;
  float: none !important;
}

.dropdown-item {
  padding: 5px 15px;
  width: 100%;
  display: block;
}

.barramenu .dropdown-menu.show {
  display: none !important;
}

.barramenu:hover .dropdown-menu.show,
.barramenu.menu-zabalduta .dropdown-menu.show {
  display: block !important;
}

/*dropdown menu*/
/*barramenu zabalduta*/
.barramenu:hover,
.barramenu.menu-zabalduta {
  width: 250px;
}

.barramenu.menu-zabalduta .logotipoa,
.barramenu:hover .logotipoa {
  width: 100px;
  border-radius: 0;
}

.barramenu.menu-zabalduta ul.nav,
.barramenu:hover ul.nav {
  margin: 0;
}

.barramenu:hover h3,
.barramenu.menu-zabalduta h3 {
  display: block;
}

.barramenu:hover a,
.barramenu.menu-zabalduta a {
  visibility: visible;
}

.barratop.menu-zabalduta {
  left: 250px;
}

.contenedor.body-content.menu-zabalduta {
  margin: 65px 20px 0 260px;
}

.contenedor.body-content {
  margin: 65px 20px 0 70px;
  transition: 0.3s ease all;
  min-height: calc(100% - 110px);
}

.barramenu .nav > .show > a,
.barramenu .nav > .show > a:hover,
.barramenu .nav > .show > a:focus {
  border-left-color: transparent;
  background-color: rgba(30, 30, 30, 0.35);
}

.barramenu:hover li.dropdown.show > a::after,
.barramenu.menu-zabalduta li.dropdown.show > a::after {
  transform: rotate(135deg);
}

.barramenu:hover li.dropdown > a::after,
.barramenu.menu-zabalduta li.dropdown > a::after {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  color: #fff;
  float: right;
  border-bottom: solid 3px #fff;
  border-left: solid 3px #fff;
  transform: rotate(-45deg);
  -webkit-transition: 0.5s ease all;
  transition: 0.5s ease all;
}

.barramenu ul.dropdown-menu.show {
  background-color: rgba(30, 30, 30, 0.35);
  box-shadow: 0 4px 4px 0px rgba(0, 0, 0, 0.5);
  border-right: solid 4px #24ab95;
  margin: 0;
}

ul.dropdown-menu a.nav-link {
  padding: 4px 0 0 28px;
}

.barramenu ul.dropdown-menu li::before {
  content: "";
  left: 10px;
  margin-top: 6px;
  position: absolute;
  width: 26px;
  opacity: 0.99;
  font-style: normal;
  font-weight: 900;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Font Awesome 5 Free";
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  visibility: visible;
  width: auto;
  font-size: 10px !important;
  margin-right: 15px;
  transition: all 0.5s;
  color: white;
}

li.dropdown.show > a {
  background: #222a31 !important;
}

.barramenu > .nav > li > a.proyectos-nav:before {
  content: "";
}

.barramenu > .nav > li > a.seguimiento-nav:before {
  content: "";
  /*  margin-right: 10px;*/
}

.barramenu > .nav > li > a.planificador-nav:before {
  content: "";
}

.barramenu > .nav > li > a.planesdeaccion-nav:before {
  content: "";
}

.barramenu > .nav > li > a.mecanizado-nav:before {
  content: "";
}

.barramenu > .nav > li > a.isometrico-nav:before {
  content: "";
}

.barramenu > .nav > li > a.inf-ejecucion:before {
  content: "";
}

.barramenu > .nav > li > a.inf-oee:before {
  content: "";
}

.barramenu > .nav > li > a.almacen-nav:before {
  content: "";
}

.barramenu > .nav > li > a.historico-mant-nav:before {
  content: "";
}

.barramenu > .nav > li > a.incidencia-mant-nav:before {
  content: "";
}

.barramenu > .nav > li > a.repercusiones-mant-nav:before {
  content: "";
}

.barramenu > .nav > li > a.mantenimientos-nav:before {
  content: "";
}

.barramenu > .nav > li > a.repositorioProgramas-nav:before {
  content: "";
}

.barramenu > .nav > li > a.disponibilidad-nav:before {
  content: "";
}

.barramenu > .nav > li > a.consumibles-nav:before {
  content: "";
}

/*barramenu zabalduta amaiera*/
.beheko-minimenu {
  position: fixed;
  bottom: 0px;
  background-color: #253341;
  margin: 0;
  width: 60px;
  cursor: pointer;
  z-index: 9999;
  transition: 0.3s ease all;
  padding: 0;
  overflow: hidden;
}

.tutoriales-nav::before {
  opacity: 0.99;
  font-family: "Font Awesome 5 Free";
  font-style: normal;
  font-weight: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: inline-block;
  text-rendering: auto;
  visibility: visible;
  font-size: 30px !important;
  color: #CAD3DD;
  /*content: "";*/
  /*content: "\f128";*/
  margin: -4px 0 0 0;
}

a.tutoriales-nav {
  height: 34px;
  width: 34px;
  border-radius: 100%;
  border-radius: 50%;
  color: #ffffff;
  display: block;
  margin: 4px 0px 0px 0px;
  text-align: center;
  outline: solid 2px #cdcdcd;
  font-size: 28px;
  line-height: 32px;
  color: #cdcdcd;
  text-decoration: none;
}

ul.beheko-minimenu li {
  width: 250px;
}

.barramenu:hover ul.beheko-minimenu,
.barramenu.menu-zabalduta ul.beheko-minimenu {
  width: 250px;
}

.beheko-minimenu a {
  padding: 10px;
}

.beheko-minimenu a:hover {
  color: #22c4c4;
}

.conf-menu {
  display: none;
}

.conf-menu.agertu {
  display: block;
}

.barramenu:hover h3.titulo-menu-configuracion,
.barramenu.menu-zabalduta h3.titulo-menu-configuracion {
  display: none;
}

.barramenu:hover h3.titulo-menu-configuracion.agertu,
.barramenu.menu-zabalduta h3.titulo-menu-configuracion.agertu {
  display: block;
}

.barramenu .beheko-minimenu a:before {
  color: #73879C;
  font-size: 28px !important;
}

.barramenu .beheko-minimenu a.conf:before {
  content: "";
}

ul.beheko-minimenu li {
  list-style-type: none;
}

/*MENU LATERAL AMAIERA*/
/*USERMENU*/
.usermenu {
  margin-right: 8px;
}

.usermenu > li {
  cursor: pointer;
  margin: 0px 1px;
  padding: 0px 6px;
  transition: background-color 0.5s;
}

.usermenu > li:hover {
  background: #0d2032;
  border-radius: 5px;
}

.usermenu-iso > li {
  cursor: pointer;
}

.usermenu > li .dropdown-menu {
  top: 110%;
}

.usermenu li a.nombre-usuario {
  background: none !important;
  line-height: 41px;
}

.listaLenguajeItem {
  padding-left: 10px !important;
  padding-right: 10px !important;
  transition: background-color 0.5s;
}

.listaLenguajeItem:hover {
  background-color: #d5e3f3;
}

.listaLenguajeItemSeleccionado {
  padding-left: 10px !important;
  padding-right: 10px !important;
  background-color: #acc5df;
}

.circulo-tutorial {
  border-radius: 50%;
  height: 32px;
  width: 32px;
  display: inline-block;
  background-color: #e1e1e1;
  color: #253341;
  text-align: center;
  line-height: 32px;
  font-weight: 900;
}

.circulo-usuario {
  border-radius: 50%;
  height: 32px;
  width: 32px;
  display: inline-block;
  background-color: #22c4c4;
  color: #fff;
  text-align: center;
  line-height: 32px;
}

.dropdown-menu.dropdown-usuario {
  padding: 0;
}

.inner-usuario-nombre-cont {
  background-color: #99bcb1;
  width: 300px;
  padding: 15px;
  color: #ffffff;
}

span.inner-usuario-circulo-usuario {
  border-radius: 50%;
  height: 34px;
  width: 34px;
  display: inline-block;
  background-color: #22c4c4;
  color: #fff;
  text-align: center;
  line-height: 34px;
  margin: 0 10px 0 0;
}

.dropdown-menu.dropdown-usuario.show {
  right: 0;
  left: auto;
}

.inner-usuario-tipo-cont {
  float: left;
  width: 50%;
  text-align: center;
  padding: 10px;
  border-right: solid 1px #99bcb1;
  border-bottom: solid 1px #99bcb1;
}

.inner-usuario-num-cont {
  float: left;
  width: 50%;
  text-align: center;
  padding: 10px;
  border-bottom: solid 1px #99bcb1;
}

.inner-usuario-itxura-cont {
  width: 50%;
  float: left;
  border-bottom: solid 1px #99bcb1;
}

.inner-usuario-titulo {
  display: block;
  color: #9e9e9e;
  font-size: 13px;
  text-align: center;
}

.itxura-aldaketa-argia {
  padding: 5px;
  margin: 10px auto;
  display: block;
  color: #ffffff;
  text-align: center;
  width: 33px;
  height: 33px;
  transition: all 1s;
  border-radius: 100%;
  background-image: linear-gradient(45deg, #ffffff 0%, #e6e6e6 50%, #00c1a0 50%, #00c1a0 100%);
  border: solid 2px #2a3f54;
}

.itxura-aldaketa-iluna {
  padding: 5px;
  margin: 10px auto;
  display: block;
  color: #ffffff;
  text-align: center;
  width: 33px;
  height: 33px;
  transition: all 1s;
  border-radius: 100%;
  background-image: linear-gradient(45deg, #253341 0%, #2a3f54 50%, #00c1a0 50%, #00c1a0 100%);
  border: solid 2px #2a3f54;
}

a#Logout {
  background-color: #2a3f54;
  padding: 5px;
  margin: 10px auto;
  display: block;
  color: #ffffff;
  text-align: center;
  width: 90%;
  transition: all 1s;
}

.header-navbar {
  display: flex !important;
  flex-basis: auto;
  flex-direction: row-reverse !important;
}

label.nombreempresaheader {
  color: #ffffff;
  font-size: 17px;
  text-transform: uppercase;
  padding: 9px 0px 0px 0px;
}

li.selector-idioma > .idioma-seleccionado {
  background-color: #99bcb1;
  padding: 5px 8px;
  border-radius: 50%;
  color: #ffffff;
  display: block;
  margin: 4px 0px 0px 0px;
}

.selector-idioma ul.dropdown-menu {
  min-width: 50px;
}

li.selector-grupo > .grupo-seleccionado {
  background-color: #649dd4;
  padding: 5px 8px;
  border-radius: 5px;
  color: #ffffff;
  display: block;
  margin: 4px 0px 0px 0px;
}

.selector-grupo ul.dropdown-menu {
  min-width: 50px;
}

.listaGrupoItem {
  padding-left: 10px !important;
  padding-right: 10px !important;
  transition: background-color 0.5s;
}

.listaGrupoItem:hover {
  background-color: #d5e3f3;
}

.listaGrupoItemSeleccionado {
  padding-left: 10px !important;
  padding-right: 10px !important;
  background-color: #acc5df;
}

.container-planta {
  margin-left: 0.5rem;
  margin-top: 0.75rem;
}

li.selector-planta > .planta-seleccionado {
  background-color: #22c4c4;
  padding: 5px 8px;
  margin: 0 10px 10px;
  border-radius: 7px;
  color: #ffffff;
  display: block;
}

.planta-seleccionado > i {
  font-size: 17px;
  color: white;
}

.selector-planta ul.dropdown-menu {
  min-width: 200px;
}

.dropdown-planta {
  left: 10px;
  top: 40px;
  background-color: #2a3f54;
}

.listaPlantaItem {
  padding-left: 10px !important;
  transition: background-color 0.5s;
}

.listaPlantaItem:hover {
  background-color: #384d60;
}

.listaPlantaItemSeleccionado {
  background-color: #4c5d6d;
}

/*USERMENU AMAIERA*/
.card {
  box-shadow: -8px 12px 18px 0 rgba(25, 42, 70, 0.13);
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  border-radius: 0;
  border-width: 0;
  margin: 0 0 20px 0;
}

.card-body {
  padding: 1rem;
}

.card-header + .card-body {
  padding: 0rem 1rem 1rem 1rem;
}

.card-header {
  background-color: transparent;
  border-bottom-width: 0;
  padding-bottom: 3px;
}

.plegarpanel {
  position: absolute;
  right: 25px;
  top: 5px;
  display: inline-block;
  width: 10px;
  height: 10px;
  margin-top: 5px;
  vertical-align: 0.255em;
  content: "";
  color: #fff;
  float: right;
  border-bottom: solid 3px #2a3f54;
  border-left: solid 3px #2a3f54;
  transform: rotate(135deg);
  -webkit-transition: 0.5s ease all;
  transition: 0.5s ease all;
  cursor: pointer;
}

.plegarpanel-2 {
  position: absolute;
  right: 25px;
  top: 5px;
  display: inline-block;
  width: 10px;
  height: 10px;
  margin-top: 5px;
  vertical-align: 0.255em;
  content: "";
  color: #fff;
  float: right;
}

.plegarpanel-2 button {
  background-color: transparent;
  border-width: 0;
  font-size: 20px;
}

.card.plegado {
  height: 45px;
  overflow: hidden;
}

.card.plegado .plegarpanel {
  transform: rotate(-45deg);
}

/*Nuevo filtro ancho*/
.tags-filtro button {
  float: right;
  background-color: transparent;
  border-width: 0;
  color: #ffffff;
}

.cont-filtro-desplegable.card.filtro-oculto .card-header {
  display: none;
}

.filtro-desplegado .filtro-desplegable.flt {
  width: 900px;
  opacity: 1;
}

button.flt-cerrar {
  position: absolute;
  right: 25px;
  top: 5px;
  background-color: transparent;
  border-width: 0;
}

.flt-andor {
  width: 90px;
  float: left;
}

.flt-andor kendo-dropdownlist.k-widget.k-dropdown {
  width: calc(100% - 10px);
}

.flt-separador {
  height: 20px;
  float: left;
  border-right: solid 1px #333;
  margin: 0 10px 0px 5px;
  /* border-left: solid 1px #333; */
  /* width: 5px; */
}

.flt-tipo {
  width: 300px;
  float: left;
}

.flt-tipo .k-dropdown,
.flt-tipo .k-dropdowntree {
  width: 100%;
}

.flt-filtro-linea {
  float: left;
  width: auto;
}

.flt-opcion {
  width: 120px;
  float: left;
  margin-left: 10px;
}

.flt-opcion kendo-dropdownlist.k-widget.k-dropdown {
  width: 100%;
}

.flt-datos {
  float: left;
  margin-left: 10px;
}

.flt-datos .calendarFiltro-input {
  width: 220px;
  /*padding-left: 10px;*/
}

.flt-dato1 kendo-datetimepicker.k-widget.k-datetimepicker {
  width: 220px;
}

.flt-dato1 kendo-textbox.k-textbox {
  width: 220px;
}

.flt-datos .flt-dato1 {
  float: left;
  margin-right: 10px;
  margin-left: 0px;
}

.flt-datos .flt-dato2 {
  float: left;
}

.flt-dato1 kendo-switch {
  margin-left: 10px;
}

.flt-datos .flt-dato1 kendo-numerictextbox.k-widget.k-numerictextbox,
.flt-datos .flt-dato2 kendo-numerictextbox.k-widget.k-numerictextbox {
  width: 82px !important;
}

.flt-eliminar {
  float: right;
  background-color: transparent;
  border-width: 0;
  color: #ff6347;
}

.flt-linea {
  margin-bottom: 10px;
}

.flt-btnanadirgrupo {
  border-color: rgba(0, 0, 0, 0.08);
  background-color: transparent;
  border-width: 1px;
  width: 100%;
  color: #808080;
  padding: 3px 0px;
}

/*Nuevo filtro ancho amaiera*/
.turnoOperarios-multiselect {
  width: auto !important;
}

/*Nuevo filtro primero*/
.cont-filtro-desplegable {
  height: calc(100vh - 55px);
  position: absolute;
  right: 0;
  transition: all 0.3s ease-in-out;
  margin-top: -10px !important;
  top: 65px;
  z-index: 3;
}

.filtro-desplegable {
  transition: width 0.3s ease-in-out, padding 0.3s ease-in-out, opacity 1s;
  -webkit-transition: width 0.3s ease-in-out, padding 0.3s ease-in-out, opacity 1s;
  opacity: 0.1;
  height: 100%;
  overflow: hidden;
  overflow-y: auto;
}

.calendarFiltro-calendario.mostrarCalendario {
  display: block;
}

.calendarFiltro-calendario.ocultarCalendario {
  display: none;
}

.filtro-desplegado .filtro-desplegable {
  width: 320px;
  opacity: 1;
}

.filtro-oculto .filtro-desplegable {
  width: 0px;
  padding: 0;
  overflow-x: hidden;
  /*   overflow-y: auto;*/
}

.btn-filtro-desplegable {
  height: 40px;
  width: 40px;
  background-color: #22c4c4;
  margin-left: -40px;
  position: absolute;
  margin-top: 25px;
  border-radius: 10px 0 0 10px;
  text-align: center;
  color: #ffffff;
  font-size: 24px;
  line-height: 42px;
  cursor: pointer;
}

.filtro-seleccionados .clearfix {
  animation: aparecer 1s ease-in-out 0s 1 alternate;
}

.filtro-seleccionados .calendarFiltro-calendario.mostrarCalendario .clearfix {
  animation: none;
}

@keyframes aparecer {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.filtro-desplegable.card-body .filtro-botones {
  margin: 10px 0 10px 0;
  border-bottom: solid 1px #22c4c4;
  padding: 0 0 10px 0;
}

.tags-filtro i {
  vertical-align: middle;
  display: inline;
}

.tags-filtro-label {
  margin-right: 5px;
  vertical-align: middle;
  display: inline;
  float: left;
}

.tags-filtro {
  background-color: #22c4c4;
  border-radius: 5px;
  padding: 0 5px;
  float: left;
  margin-right: 5px;
  color: #ffffff;
}

/*filtro calendario*/
.calendarFiltro-botones {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                              supported by Chrome, Edge, Opera and Firefox */
  border: solid 1px rgba(0, 0, 0, 0.08);
  height: 100%;
}

.calendarFiltro-input {
  border: solid 1px rgba(0, 0, 0, 0.08);
  border-radius: 2px;
  height: 30px;
  width: 100%;
  padding-left: 10px;
}

.calendarFiltro-calendario {
  position: fixed;
  /*top: 30px;*/
  right: 0;
  z-index: 1;
  width: auto;
  background-color: #ffffff;
}

.calendarFiltro-calendario .col-mes {
  width: 257px;
  float: left;
}

.calendarFiltro-calendario .col-mes-botones {
  width: 120px;
}

.calendarFiltro {
  position: relative;
}

.calendarFiltro-calendario .k-calendar .k-calendar-nav.k-hstack {
  /*quitar botón today*/
  visibility: hidden;
}

.calendarFiltro-calendario .k-calendar .k-calendar-view span.k-link {
  padding: 0;
  display: flex;
  align-items: stretch;
}

.calendarFiltro-calendario .k-calendar .k-calendar-view .k-today span.k-link,
.calendarFiltro-calendario .k-calendar .k-calendar-view .k-state-selected span.k-link {
  /*cambiar color fondo días*/
  /*revisar sombra rara que se crear al pasar el ratón*/
  background-color: initial;
  color: black;
  padding: 0;
  display: flex;
  align-items: stretch;
}

.calendarFiltro-calendario .calendarFiltro-calendario-contenido-dia {
  display: block;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.calendarFiltro-calendario .k-link .calendarFiltro-calendarioSeleccionado {
  background-color: #22c4c4 !important;
  color: white;
}

.calendarFiltro-calendario .k-calendar-title {
  /*pointer-events: none;*/
}

.calendarFiltro-input i {
  float: right;
  margin: 5px 5px 0 0;
  color: #424242;
}

.calendarFiltro-input span#calendarioInput {
  font-size: 13px;
  padding: 4px 8px;
}

.calendarFiltro-botones button {
  border-width: 0;
  color: #22c4c4;
  margin: 0 0 5px 0;
  background: transparent;
  font-size: 12px;
}

.calendarFiltro .k-calendar-view {
  min-height: 180px;
}

.calendarFiltro .k-calendar .k-calendar-th,
.calendarFiltro .k-calendar-caption,
.calendarFiltro .k-meta-header,
.calendarFiltro .k-month-header {
  color: #b5adad;
}

.filtro-seleccionados .k-textbox,
.filtro-seleccionados .k-dropdown .k-dropdown-wrap,
.filtro-seleccionados .k-dropdowntree .k-dropdown-wrap,
.filtro-seleccionados .k-numerictextbox .k-numeric-wrap,
.filtro-seleccionados .calendarFiltro-input,
.filtro-seleccionados .k-multiselect .k-multiselect-wrap,
.filtro-seleccionados .k-dropdowntree .k-multiselect-wrap {
  border-color: rgba(34, 196, 196, 0.5);
  background: rgba(34, 196, 196, 0.2);
}

/*filtro calendario amaiera*/
/*Nuevo filtro primero amaiera*/
.fondo-color-ejecucion {
  background-color: #C0EADA;
}

.fondo-color-preparacion {
  background-color: #096844;
}

.fondo-color-alarma {
  background-color: #D33737;
}

.fondo-color-apagada {
  background-color: #424242;
}

.fondo-color-parada {
  background-color: #E7CB68;
}

.fondo-color-mantenimiento {
  background-color: #99AFC6;
}

.fondo-color-microparada {
  background-color: #CF8729;
}

/*HOME BERRI BERRIA*/
.cargado .instalacion-estructura-1,
.cargado .instalacion-estructura-2,
.cargado .hmi-contenedor-maq,
.cargado .home-contenedor-maq {
  animation-name: home-opacidad;
  animation-duration: 1.5s;
}

.cargando .instalacion-estructura-1,
.cargando .instalacion-estructura-2,
.cargando .hmi-contenedor-maq,
.cargando .home-contenedor-maq {
  opacity: 0;
}

.hmi-contenedor-maq {
  height: 100%;
}

@keyframes home-opacidad {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 100;
  }
}
.home-pieza-actual-ritmo-contenedor label {
  line-height: 15px;
}

.hmi-informacion-operacion-offline label,
.home-informacion-operacion label {
  font-size: 12px;
}

.home-contenedor-maquinas {
  color: #7C8593;
  font-size: 12px;
  grid-template-columns: repeat(6, minmax(0, 1fr));
  display: grid;
}

.ejecucion.home-contenedor-maq-inner,
.preparacion.home-contenedor-maq-inner,
.alarma.home-contenedor-maq-inner,
.apagada.home-contenedor-maq-inner,
.parada.home-contenedor-maq-inner,
.mantenimiento.home-contenedor-maq-inner {
  color: #7C8593;
  font-size: 12px;
}

.home-contenedor-maq {
  flex: 33.333% 0 0;
  padding: 0px 8px 0px 8px;
  margin-bottom: 12px;
  grid-column: span 2;
}

.home-contenedor-maq-inner {
  box-shadow: -8px 12px 18px 0 rgba(25, 42, 70, 0.13);
  transition: all 0.3s ease-in-out;
  border-radius: 0;
  border-width: 0;
  /* margin: 0 0 20px 0; */
  position: relative;
  display: flex !important;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  /* overflow: hidden; */
  height: 100%;
  justify-content: space-between;
}

.home-contenedor-maq-inner .estado:after {
  content: "";
  position: absolute;
  height: 0;
  width: 0;
  border: 35px solid transparent;
  border-right: 35px solid #617484;
  right: -35px;
  transform: rotate(135deg);
  top: -35px;
  z-index: 0;
}

.home-contenedor-maq-inner .estado {
  position: absolute;
  height: 50px;
  width: 50px;
  right: 0;
  overflow: hidden;
  pointer-events: none;
}

.ejecucion.home-contenedor-maq-inner .estado:after {
  border-right-color: #C0EADA;
}

.ejecucion .home-estado-maquina label {
  color: #C0EADA;
}

.preparacion.home-contenedor-maq-inner .estado:after {
  border-right-color: #096844;
}

.preparacion .home-estado-maquina label {
  color: #096844;
}

.alarma.home-contenedor-maq-inner .estado:after {
  border-right-color: #D33737;
}

.alarma .home-estado-maquina label {
  color: #D33737;
}

.apagada.home-contenedor-maq-inner .estado:after {
  border-right-color: #424242;
}

.apagada .home-estado-maquina label {
  color: #424242;
}

.parada.home-contenedor-maq-inner .estado:after {
  border-right-color: #E7CB68;
}

.parada .home-estado-maquina label {
  color: #E7CB68;
}

.mantenimiento.home-contenedor-maq-inner .estado:after {
  border-right-color: #99AFC6;
}

.mantenimiento .home-estado-maquina label {
  color: #99AFC6;
}

.home-informacion-maquina {
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: flex-start;
  border-bottom: solid 1px #dce2ea;
  height: 109px;
  justify-content: flex-start;
  padding: 0px 40px 0px 0;
  cursor: pointer;
}
.home-informacion-maquina * {
  cursor: pointer;
}

.home-imagen-maquina {
  width: 30%;
  text-align: center;
}

.home-nombre-operario-maquina {
  width: 50%;
  margin-top: 15px;
}

.home-nombre-maquina {
  font-size: 15px;
  font-weight: 600;
  color: #000000;
  margin: 0 0 5px 0;
}

.home-nombre-maquina label {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
  font-size: 15px;
}

.home-operario-maquina.operario-multiple .un-operario-multiple {
  margin: 0 0 0 -6px;
}

.home-operario-maquina.operario-multiple .un-operario-multiple {
  margin: 0 0 0 -6px;
  background: white;
  border-radius: 100%;
  outline: solid 1px white;
}

.home-operario-maquina {
  white-space: nowrap;
  display: flex;
  align-items: center;
}

.home-operario-maquina label {
  color: #000000;
  margin: 0 0 0 10px;
  font-weight: 300;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: calc(100% - 30px);
}

.home-imagen-maquina img {
  max-width: 90%;
  max-height: 80px;
  width: auto;
  margin-top: 15px;
}

.home-tiempo-maquina-contenedor {
  margin-left: auto;
  text-align: center;
  width: 20%;
  margin-top: 15px;
}

.home-operario-maquina span.grid-circulo {
  background-color: rgba(34, 196, 196, 0.2);
  color: #22c4c4;
  font-size: 9px;
}

.home-tiempo-estado-maquina label {
  font-size: 21px;
  color: #000000;
}

.home-estado-maquina label {
  font-size: 16px;
  text-transform: uppercase;
}

.home-operacion-contenedor {
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: stretch;
  justify-content: space-between;
}

.hmi-informacion-operacion-offline ul,
.home-informacion-operacion ul {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
  align-content: flex-start;
  height: 100%;
}

.home-informacion-operacion.tiene-linea-inferior ul {
  height: calc(100% - 34px);
}

.home-colada-lote-nserie {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-top: 5px;
}

.home-colada-lote-nserie label {
  line-height: 14px;
  font-weight: 300;
}

.home-colada-operacion,
.home-lote-operacion,
.home-nserie-operacion {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.home-informacion-operacion,
.home-informacion-pieza {
  width: 50%;
}

.home-informacion {
  display: flex;
  flex-direction: column;
  align-content: stretch;
  align-items: center;
  justify-content: center;
  width: 50%;
}

.home-informacion label {
  width: 100%;
  max-width: 300px;
  padding: 0 5px;
}

.hmi-informacion-operacion-offline ul li,
.home-informacion-operacion ul li {
  list-style-type: none;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  padding: 0 0 0 10px;
  border-bottom: solid 1px #DCE2EA;
}

.home-informacion-operacion ul li:last-child {
  border-bottom: 0;
}

.home-informacion-operacion.tiene-linea-inferior ul li:last-child {
  border-bottom: solid 1px #DCE2EA;
}

.home-cantidades-operacion {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  border-bottom: solid 1px #dce2ea;
}

.home-piezas-totales-operacion,
.home-contador-operacion,
.home-manual-operacion,
.home-malas-operacion {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  flex: 1 0 0;
  position: relative;
  padding: 4px 0;
}

.home-cantidades-operacion label {
  font-weight: 300;
  line-height: 15px;
  white-space: nowrap;
}

.home-piezas-totales-operacion .valor,
.home-contador-operacion .valor,
.home-manual-operacion .valor,
.home-malas-operacion .valor {
  color: #333333;
}

.home-piezas-totales-operacion:after,
.home-contador-operacion:after,
.home-manual-operacion:after {
  content: "";
  display: block;
  height: 22px;
  width: 1px;
  background: #dce2ea;
  position: absolute;
  right: -1px;
  top: 4px;
}

.home-actual-ritmo-inner {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.home-informacion label:first-child {
  font-size: 22px;
  text-align: center;
}

.home-informacion .home-tiempo-estado-maquina {
  margin-top: 32px;
}

.home-actual-ritmo-inner .cont-titulo-ritmo,
.home-actual-ritmo-inner .cont-titulo-actual {
  flex: 1;
  text-align: center;
  position: relative;
}

.cont-titulo-actual:after {
  content: "";
  display: block;
  height: 12px;
  width: 3px;
  background-color: #dce2ea;
  position: absolute;
  right: -1px;
  top: 4px;
}

.cont-titulo-ritmo:before {
  content: "";
  display: block;
  height: 17px;
  width: 1px;
  background-color: #dce2ea;
  position: absolute;
  left: -1px;
  bottom: -1px;
}

.home-actual-ritmo-inner .hueco {
  background: transparent;
  height: 20px;
  width: 29px;
}

.home-actual-ritmo .borde-fake,
.home-actual .borde-fake {
  height: 1px;
  width: 100%;
  background: linear-gradient(to left, rgba(220, 226, 234, 0) 0%, #dce2ea 50%, rgba(220, 226, 234, 0) 100%);
}

.home-actual-ritmo i {
  font-size: 8px;
  margin-right: 5px;
}

.home-actual-ritmo label {
  font-size: 10px;
}

.home-datos-actual {
  display: flex;
  flex-direction: column;
}

label.home-tiempo {
  font-size: 10px;
  margin-bottom: 5px;
  color: #000000;
  font-weight: 300;
}

.home-valor-grande,
.home-tiempo-grande {
  font-size: 14px;
  font-weight: 500;
  color: #000000;
}

label.home-tiempo-superior {
  color: #ff6347;
}

label.home-tiempo-inferior {
  color: #009680;
}

.home-contenedor-maq-inner .etiqueta {
  background: #dce2ea;
  border-radius: 5px;
  width: auto;
  padding: 1px 5px;
  font-size: 11px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.home-contenedor-maq-inner .etiqueta label {
  font-weight: bold;
}

.home-contenedor-maq-inner .etiqueta i {
  font-size: 9px;
  margin: 0 0 0 5px;
  font-weight: bold;
}

.etiqueta.home-fondo-rojo {
  background: rgba(255, 99, 71, 0.2);
  color: #ff6347;
}

.etiqueta.home-fondo-verde {
  background: rgba(0, 150, 128, 0.2);
  color: #009680;
}

.home-preparacion-pieza-lote {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.home-preparacion-pieza-lote:not(:last-child) {
  border-bottom: solid 1px #dce2ea;
}

.home-euros-hora-cont .home-titulo-vertical,
.home-preparacion-pieza-lote .home-titulo-vertical {
  writing-mode: vertical-rl;
  text-orientation: mixed;
  transform: rotate(180deg);
  box-sizing: border-box;
  width: 28px;
  text-align: center;
}

.home-preparacion-pieza-lote:not(:last-child) .home-titulo-vertical:before {
  content: "";
  display: block;
  height: 3px;
  width: 12px;
  background: #dce2ea;
  position: absolute;
  right: 7px;
  top: -2px;
}

.home-preparacion-pieza-lote .home-titulo-vertical .cont-label-icon {
  margin: 0px 5px 0 0;
}

.home-preparacion-pieza-lote .home-titulo-vertical label {
  font-size: 10px;
}

.home-preparacion-pieza-lote .home-titulo-vertical i {
  font-size: 10px;
  margin-bottom: 5px;
  transform: rotate(90deg);
  writing-mode: horizontal-tb;
  text-orientation: mixed;
}

.home-preparacion-pieza-lote .home-titulo-vertical .borde-fake {
  position: absolute;
  top: 0;
  bottom: 0;
  background: #dce2ea;
  width: 1px;
}

.home-preparacion-pieza-lote .home-titulo-vertical .borde-fake.borde-degradado-top {
  position: absolute;
  top: 0;
  bottom: 0;
  background: linear-gradient(to top, rgba(220, 226, 234, 0) 0%, #dce2ea 98%);
  width: 1px;
}

.home-preparacion-pieza-lote .home-titulo-vertical .borde-fake.borde-degradado-bottom {
  position: absolute;
  top: 0;
  bottom: 0;
  background: linear-gradient(to bottom, rgba(220, 226, 234, 0) 0%, #dce2ea 98%);
  width: 1px;
}

.home-datos-actual, .home-datos-ritmo {
  flex: 1;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  padding: 3px 0px;
}

.home-datos-ritmo label.home-tiempo {
  margin-bottom: 2px;
}

.home-datos-ritmo .etiqueta {
  margin-bottom: 2px;
}

.home-datos-actual {
  border-right: solid 1px #dce2ea;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.home-datos-actual label.home-tiempo {
  margin: 0;
}

.home-informacion-pieza {
  padding: 0px 5px;
}

.home-contenedor-porcentaje {
  background-color: #F8F8F8;
  width: 65%;
  position: relative;
  overflow: hidden;
  max-width: 70px;
  margin: 2px 0 0 0;
}

.home-barra-progreso {
  background-color: #DCE2EA;
  height: 16px;
}

.home-contenedor-porcentaje label.home-label-progreso {
  position: absolute;
  top: 0;
  display: block;
  text-align: center;
  left: 0;
  right: 0;
}

.home-circulo-porcentaje-cont {
  position: relative;
  margin-left: 29px;
}

.home-circulo-porcentaje {
  background-color: #dce2ea;
  position: absolute;
  left: calc(50% - 20px);
  width: 39px;
  height: 39px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  top: -20px;
}

.home-circulo-porcentaje-cont:before {
  background-color: #ffffff;
  position: absolute;
  left: calc(50% - 20px);
  width: 39px;
  height: 39px;
  border-radius: 100%;
  top: -20px;
  content: "";
  display: block;
}

.home-circulo-porcentaje-hora-cont.home-fondo-rojo .home-circulo-porcentaje,
.home-circulo-porcentaje-cont.home-fondo-rojo .home-circulo-porcentaje {
  background: rgba(255, 99, 71, 0.2);
  color: #ff6347;
}

.home-circulo-porcentaje-hora-cont.home-fondo-verde .home-circulo-porcentaje,
.home-circulo-porcentaje-cont.home-fondo-verde .home-circulo-porcentaje {
  background: rgba(0, 150, 128, 0.2);
  color: #009680;
}

.home-circulo-porcentaje label.home-label-porcentaje {
  font-weight: bold;
}

.home-informacion-pieza.home-no-lote {
  min-height: 207px;
}

.home-pieza-actual-ritmo-contenedor {
  position: relative;
}

.home-informacion-pieza.home-no-lote .home-pieza-actual-ritmo-contenedor {
  height: 100%;
}

.home-informacion-pieza.home-no-lote .home-pieza-actual-ritmo-contenedor .home-preparacion-pieza-lote {
  height: calc(33.333% - 7px);
}

.home-informacion-pieza.home-no-lote .home-actual {
  text-align: center;
}

.home-informacion-pieza.home-no-lote .home-actual label {
  font-size: 10px;
}

.home-informacion-pieza.home-no-lote .home-actual i {
  font-size: 8px;
  margin-right: 5px;
}

.home-informacion-pieza.home-no-lote .home-datos-actual {
  border-right-width: 0;
}

.home-informacion-configurable {
  display: flex;
  flex-direction: row;
  border-top: solid 1px #dce2ea;
}

.home-inf-conf-col-izquierda, .home-inf-conf-col-derecha {
  flex: 50% 0 0;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.home-inf-conf-col-izquierda .home-cuatro-graficos {
  padding: 5px;
}

.home-inf-conf-col-derecha:has(.home-resumen-semana),
.home-inf-conf-col-izquierda:has(.home-resumen-semana) {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 8px;
}

.home-informacion-configurable .home-resumen-semana {
  text-align: center;
  cursor: pointer;
}
.home-informacion-configurable .home-resumen-semana * {
  cursor: pointer;
}

.home-informacion-configurable .home-resumen-semana .graf-barr-part-cont {
  opacity: 0.5;
  transition: all 0.5s;
}

.home-informacion-configurable .home-resumen-semana .graf-barr-part-cont:hover {
  opacity: 1;
}

.home-informacion-configurable .home-resumen-semana .graf-barr-part-multi {
  height: 20px !important;
}

hmi-panel.hmi-panel .home-informacion-maquina {
  cursor: default;
}

.home-cuatro-graficos.donuts-ilunak {
  display: none;
}

.home-cuatro-graficos.donuts-argiak {
  display: block;
}

.cont-home-donuts {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
}

.cont-home-donut {
  flex: 20% 0 0;
  position: relative;
}

.home-donut {
  height: 100%;
  aspect-ratio: 1/1;
  margin: 0px;
  border-radius: 50%;
  background: radial-gradient(closest-side, white 79%, transparent 80% 100%), conic-gradient(transparent 95%, white 0), conic-gradient(#7C8593 0%, #DCE2EA 0);
  transform: rotate(189deg);
}

.valores-home-donut {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  justify-content: center;
}

.valores-home-donut label.label-home-donut {
  font-size: 9px;
}

.home-resumen-semana span.tooltiptextHome.text-left {
  padding: 10px;
  box-shadow: -8px 12px 18px 0 rgba(25, 42, 70, 0.13);
  /*transition: all 0.3s ease-in-out;*/
  border-width: 0;
  background-color: #fff;
  background-clip: border-box;
  border-radius: 0.25rem;
}

.tooltipDestacado label.home-tooltip-valor {
  width: 20%;
  text-align: right;
}

.tooltipDestacado label.home-tooltip-titulo {
  width: 50%;
}

.home-resumen-semana span.tooltiptextHome.text-left .tooltipDestacado {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.home-resumen-semana span.tooltiptextHome.text-left .tooltipDestacado.info_preparacion {
  color: #096844;
}

.home-resumen-semana span.tooltiptextHome.text-left .tooltipDestacado.info_ejecucion {
  color: #C0EADA;
}

.home-resumen-semana span.tooltiptextHome.text-left .tooltipDestacado.info_parada {
  color: #E7CB68;
}

.home-resumen-semana span.tooltiptextHome.text-left .tooltipDestacado.info_mantenimiento {
  color: #99AFC6;
}

.home-resumen-semana span.tooltiptextHome.text-left .tooltipDestacado.info_alarma {
  color: #D33737;
}

.home-resumen-semana span.tooltiptextHome.text-left .tooltipDestacado.info_apagada {
  color: #424242;
}

.home-resumen-semana span.tooltiptextHome.text-left .tooltipDestacado.info_microparada {
  color: #CF8729;
}

.home-imagen-sin-datos {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.home-imagen-sin-datos img {
  width: 170px;
}

.carousel-indicators li:after {
  content: "";
  display: block;
  background: #22c4c4;
  height: 8px;
  width: 8px;
  border-radius: 100%;
}

.carousel-indicators {
  top: -17px;
  margin: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  bottom: auto;
  z-index: 0;
}

.carousel-indicators li {
  height: 10px;
  width: 10px;
  border-radius: 100%;
  border: solid 2px #22c4c4;
  background: transparent;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.home-euros-hora-cont {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.home-euros-hora-cont .home-titulo-vertical {
  border-left: solid 1px #dce2ea;
}

.piezas-euros-hora,
.home-euros-hora {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.home-euros-hora-cont label.titulo {
  font-size: 10px;
}

.home-euros-hora-cont label.valor-grande {
  font-size: 14px;
  font-weight: 600;
  color: #000000;
}

.home-euros-hora-cont .home-circulo-porcentaje-hora-cont {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.home-euros-hora-cont .home-circulo-porcentaje-hora-cont .home-circulo-porcentaje {
  position: relative;
  top: auto;
}

label.diferencia.home-label-rojo {
  color: #ff6347;
}

label.diferencia.home-label-verde {
  color: #009680;
}

.home-turno-contenedor .home-circulo-porcentaje,
.home-turno-contenedor .home-circulo-porcentaje-cont:before {
  top: -45px;
}

.home-actual-ritmo .home-preparacion-pieza-lote:not(:last-child) {
  border-width: 0;
}

.home-turno-contenedor .home-actual-ritmo .home-tiempo-grande {
  font-size: 15px;
}

.home-turno-contenedor .home-actual-ritmo i.icon-piezas-totales {
  font-size: 15px;
}

span.home-cantidad-malas {
  color: #D33737;
}

.home-turno-contenedor .home-titulo-vertical {
  border-left: solid 1px #dce2ea;
}

.home-turno-contenedor .home-titulo-vertical:before {
  display: none !important;
}

/*HOME BERRI BERRIA AMAIERA*/
/*  HOME*/
.panelosoa {
  transition: all 1s;
  background-position: center;
  background-repeat: no-repeat;
  padding: 0 8px 0 9px;
  background-size: 100%;
  margin-bottom: 13px;
}

.home-maquinas-cont .panelosoa .inner-panel {
  height: 100%;
}

.panelosoa .inner-panel {
  background-color: #ffffff;
  overflow: hidden;
  position: relative;
  min-height: 392px;
  box-shadow: -8px 12px 18px 0 rgba(25, 42, 70, 0.13);
  -webkit-transition: all 0.3s ease-in-out;
}

.panelosoa .inner-panel-mini {
  background-color: #ffffff;
  overflow: hidden;
  border-radius: 5px 0 0 0;
  position: relative;
  min-height: 196px;
  height: 196px;
  margin: 0 0 25px 0;
  box-shadow: -8px 12px 18px 0 rgba(25, 42, 70, 0.13);
  -webkit-transition: all 0.3s ease-in-out;
}

.panelosoa a {
  display: block;
  cursor: pointer !important;
}

.panelosoa a * {
  cursor: pointer !important;
}

.pestañaprocesos {
  content: "";
  position: absolute;
  height: 0;
  width: 0;
  border: 35px solid transparent;
  border-right: 40px solid #617484;
  right: -40px;
  transform: rotate(135deg);
  top: -40px;
  z-index: 1;
}

.panel-campo {
  background-color: aliceblue;
}

.grafico-home .c3-tooltip-container .c3-tooltip {
  min-width: 80px !important;
}

.colorejecucion {
  border-right-color: #C0EADA;
}

.colorpreparacion {
  border-right-color: #096844;
}

.coloralarma {
  border-right-color: #D33737;
}

.colorapagada {
  border-right-color: #424242;
}

.colorparada {
  border-right-color: #E7CB68;
}

.colormantenimiento {
  border-right-color: #99AFC6;
}

.home-operaciones-contenedor {
  cursor: pointer;
}
.home-operaciones-contenedor * {
  cursor: pointer;
}

.aviso-mant-alarm {
  display: flex;
  line-height: 12px;
  gap: 3px;
  margin-top: 5px;
}

.icono-aviso {
  width: 25px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  float: left;
}

.aviso-mant-alarm label {
  flex: 1;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.aviso-mantenimiento label {
  color: #99AFC6;
}

.aviso-alarma label {
  color: #D33737;
}

.home-oee {
  cursor: pointer;
}
.home-oee * {
  cursor: pointer;
}

/*HMI hornos*/
.ejes-grupo-home.oee-home-hornos .donut-porcent-cont {
  margin: 15px 0 0 0;
}

.donut-resumen-semana-hmi .c3 {
  max-height: 270px !important;
}

.hmi-hornos-cont .panelosoa .inner-panel {
  min-height: 325px;
}

.hmi-panel-procesos-offline {
  height: 100% !important;
}

/*HMI hornos amaiera*/
.hmi-panel .home-contenedor-maq-inner.preparacion {
  transition: none;
}

.hmi-panel.hmi-panel > .ng-star-inserted {
  height: calc(100% - 25px);
}

.hmi-panel.hmi-panel > .ng-star-inserted .home-contenedor-maq-inner {
  height: 100%;
}

/*HMI berri berria*/
.hmi-informacion-operacion-offline {
  height: calc(100% - 19px);
}

.hmi-panel-datos {
  height: 100%;
}

.hmi-panel-procesos-offline label.titulo-seccion-maquina {
  border-bottom: 1px solid #dce2ea;
}

/*HMI berri berria amaiera*/
/* Recetas */
.ciclos-icono {
  padding: 2px !important;
}

.receta-activado {
  background-color: #8AC249;
  color: #ffffff;
}

.receta-desactivado {
  background-color: #7E7E7E;
  color: #ffffff;
}

.receta-desigual {
  color: #ff6347 !important;
}

/* Recetas amaiera*/
/* HMI inyectora*/
.granate .k-checkbox:checked {
  background-color: #8B0707;
  border-color: #8B0707;
}

.azul .k-checkbox:checked {
  background-color: #3366CC;
  border-color: #3366CC;
}

.naranja .k-checkbox:checked {
  background-color: #FF9900;
  border-color: #FF9900;
}

.morado .k-checkbox:checked {
  background-color: #9836CC;
  border-color: #9836CC;
}

.verde .k-checkbox:checked {
  background-color: #B5E50A;
  border-color: #B5E50A;
}

.k-grid tr.granate.k-state-selected td,
.k-grid tr.azul.k-state-selected td,
.k-grid tr.naranja.k-state-selected td,
.k-grid tr.morado.k-state-selected td,
.k-grid tr.verde.k-state-selected td {
  background-color: initial;
}

.pastilla-roja {
  background: rgba(197, 34, 31, 0.3) !important;
  color: #ffffff;
  border-radius: 4px;
  text-align: right;
  color: #C5221F;
  padding-right: 5px;
}

.ocultar-eje-y .c3-axis-y {
  display: none;
}

.contenedor-valores-tecnologicos-inyectora {
  z-index: 2;
  background: #ffffff;
}

.grafico-hmi-inyectora .c3 svg,
.grafico-hmi-inyectora .c3 {
  max-height: 450px !important;
}

.grafico-hmi-inyectora .c3 {
  max-height: 450px !important;
}

.dato-oeee-hmi {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.dato-oeee-hmi .oee-inividual-cont .donut-grupo-cm {
  width: 100%;
  float: none;
  max-width: 88px;
  margin: 6px auto;
  max-width: 80px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.dato-oeee-hmi .oee-inividual-cont:first-child .donut-grupo-cm {
  /*max-width: 98px;
  margin: 0 auto;*/
}

.dato-oeee-hmi .oee-inividual-cont {
  width: 33.333%;
}

.dato-oeee-hmi .oee-inividual-cont:first-child {
  width: 100%;
}

.hmi-inyectora-cont label.label-datos-home {
  width: auto;
}

.inner-panel-izq {
  float: left;
  width: 45%;
}

.inner-panel-izq div#maquina.maquina {
  float: none !important;
}

.inner-panel-der {
  float: left;
  width: 55%;
}

/* HMI inyectora amaiera*/
.modelo-maquina {
  font-weight: 700;
}

.modelo-maquina label {
  font-size: 16px;
  vertical-align: middle;
  padding: 0 5px 0 0;
  height: 62px;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
  line-height: 20px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  white-space: pre-wrap;
}

.fotomaquina {
  height: 153px;
  position: relative;
  width: 190px;
}

.contenedor.body-content.menu-zabalduta .fotomaquina.pqn {
  width: 170px;
}

.fotomaquina #fotomaquina {
  max-height: 100%;
  max-width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

.fotomarca {
  text-align: center;
  margin: 10px;
}

.fotomarca img {
  max-width: 100%;
  max-height: 33px;
}

.ejes-grupo-home {
  overflow: hidden;
  /*padding: 2px 0 0 10px;*/
  color: #22c4c4;
  min-height: 50px;
}

img.donut-img {
  width: 95%;
}

.donut-grupo-cm {
  overflow: hidden;
  width: calc(25% - 5px);
  float: left;
  margin: 0 5px 0 0;
  position: relative;
  text-align: center;
}

.donut-grupo-cm .donut-porcent-cont {
  position: absolute;
  width: 100%;
}

.alerticonos-linea {
  min-height: 17px;
  overflow: hidden;
  width: 100%;
  margin: 5px 0 0 0;
  text-align: center;
}

.donut-grupo-cm .donut-porcent-cont .donut-porcent {
  font-size: 14px !important;
  line-height: 10px;
}

.alerticonos-cont {
  float: left;
  width: 50%;
  height: 17px;
}

.alerticonos-linea .alerticonos-cont:first-child img {
  margin: -6px 0 0 5px;
}

.donut-grupo-cm .donut-porcent-cont label {
  display: block;
  margin: 0 auto;
  text-align: center;
  line-height: 10px;
  color: #22c4c4;
}

.donut-porcent-rpm {
  font-size: 9px;
  margin-top: 2px !important;
}

/*homezaharra
.inner-panel-der {
  float: left;
  width: 55%;
}

.contenedor-eje {
  overflow: hidden;
  margin: 2px;
}

.datos-de-tiempo-inner {
  height: 82px;
}

.estado-parada.contenedor-eje {
  background-color: rgba(231, 203, 104, 0.7);
}

.estado-ejecucion.contenedor-eje {
  background-color: #bbe0d2;
}

.estado-mantenimiento.contenedor-eje {
  background-color: rgba(153, 175, 198, 0.7);
}

.estado-alarma.contenedor-eje {
  background-color: rgba(211, 55, 55, 0.7);
}

.estado-preparacion.contenedor-eje {
  background-color: rgba(9, 104, 68, 0.7);
}

.contenedor-eje.estado-apagada {
  background-color: #cdcdcd;
}

.datos-de-tiempo-inner .dt-izq {
  float: left;
  width: 35%;
}

.estado-titulo {
  color: #ffffff;
  text-align: center;
}
*/
.estado-titulo label {
  margin: 0;
}

.estado-hora {
  color: #ffffff;
  text-align: center;
  padding: 5px;
  display: block;
}

.porcent-cont {
  height: 50px;
  width: 50px;
  position: relative;
  margin: 0 auto;
}

.porcent-cont-outer .donut-porcent {
  position: absolute;
  top: 17px;
  font-size: 12px !important;
  display: block;
  width: 100%;
  text-align: center;
}

.datos-de-tiempo-inner .dt-cent {
  float: left;
  width: 30%;
}

.tiempoestimado-datos {
  color: #ffffff;
}

.estado-tiempo-titulo {
  display: block;
  margin: 0;
  text-align: center;
  color: #ffffff;
}

.estado-tiempo {
  display: block;
  background-color: white;
  border-radius: 3px;
  text-align: center;
  color: #333333;
  margin: 0 5px;
  padding: 5px;
}

.capa-valores {
  margin: 5px 4px 0 0 !important;
  padding: 0 !important;
  letter-spacing: -1px;
  text-align: right;
  float: right;
}

.capa-titulo {
  color: #24ab95;
  margin: 5px 0px 0 0 !important;
}

/* home-zaharra
.panel-of {
  overflow: hidden;
  padding: 4px 4px 4px 10px;
  background-color: #f0fdff;
}

.of-inner {
  position: relative;
}

.of-content {
  margin: 5px 0;
  height: 25px;
}

.of-inner:before {
  content: "\f5fd";
  height: 25px;
  width: 25px;
  background-color: #03A9F4;
  display: block;
  float: left;
  border-radius: 100%;
  opacity: .99;
  font-style: normal;
  font-weight: 900;
  font-family: 'Font Awesome 5 Free';
  text-rendering: auto;
  font-size: 15px !important;
  color: #ffffff;
  text-align: center;
  line-height: 23px;
  margin: 0px 10px 0 0;
}

span.label-proceso {
  margin: 5px 5px 0 5px;
}*/
.maquina-cont .label-azul {
  color: #64a995;
  margin: 0 5px 0 0;
}

label.label-proceso {
  margin: 0;
  max-width: calc(100% - 40px);
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  margin: 3px 0 0 15px;
}

.of-content label.label-azul {
  margin: 0 0 0 5px;
}

.datos-of {
  min-height: 100%;
  border-bottom: 1px solid #dce2ea;
}

.label-datos-home-noidentificado {
  text-align: center;
  padding: 40px 0 0 0;
}

.titulo-seccion-maquina {
  display: block;
  margin: 0px 3px;
  font-weight: bold;
  text-transform: lowercase;
  text-align: left;
  padding: 0 0 0 10px;
}

.titulo-seccion-maquina:first-letter {
  text-transform: uppercase;
}

.inner-panel .titulo-seccion-maquina {
  color: #009680;
  text-align: center;
  font-weight: inherit;
  margin: 0;
  padding: 0;
}

.datos-li-titulo {
  background: #F8F8F8;
  font-weight: bold;
}

.datos-of-content li:nth-of-type(even) {
  background: #F8F8F8;
}

.datos-of-content ul {
  padding: 0;
  margin: 0;
}

.datos-of-content li {
  list-style-type: none;
}

.datos-of-content li {
  padding: 2px 0 2px 10px;
  font-size: 12px;
  height: 23px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 15px;
}

.datos-of-content li label {
  padding: 0px;
  margin: 0px;
}

li.linea-ciclos-inyectora.ng-star-inserted label.label-datos-valor-home:not(:first-child) {
  text-align: right;
}

li.linea-ciclos-inyectora.ng-star-inserted label.label-datos-valor-home:nth-child(2) {
  text-align: center;
}

.datos-of-content label.label-datos-home {
  /*font-weight: 700;*/
  display: inline-block;
  vertical-align: top;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

label.label-datos-valor-home {
  max-width: calc(100% - 83px);
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.bloques-semana-maquina {
  padding: 5px 0 0 0;
}

.bloques-semana-maquina .box-porcent.box-verde {
  border-color: #64A995;
  color: #64A995;
}

.bloques-semana-maquina .box-porcent.box-verdedos {
  border-color: #91C1B2;
  color: #91C1B2;
}

.bloques-semana-maquina .box-porcent.box-azul {
  border-color: #21B0E8;
  color: #21B0E8;
}

.bloques-semana-maquina .box-porcent.box-amarillo {
  border-color: #EACD1F;
  color: #EACD1F;
}

.bloques-semana-maquina .box-porcent.box-naranja {
  border-color: #cf8729;
  color: #cf8729;
}

.bloques-semana-maquina .box-porcent.box-rojo {
  border-color: #ED1C24;
  color: #ED1C24;
}

.bloques-semana-maquina .box-porcent.box-negro {
  border-color: #424242;
  color: #424242;
}

.bloques-semana-maquina .box-porcent {
  opacity: 0.8;
}

/*HOME BERRIA*/
.tiempo-ejecucion-maquina label {
  font-size: 21px;
  display: block;
  line-height: 20px;
}

.estado-maquina label {
  display: block;
  font-size: 16px;
}

.lote-actual-label,
.lote-actual-tiempo,
.total-time-label,
.total-time-desviacion {
  text-align: center;
}

.piezas-hora-cantidad label,
.euros-hora-cantidad label {
  text-align: center;
  display: block;
  font-size: 20px;
}

.pieza-actual-tiempo label,
.lote-actual-tiempo label {
  font-size: 20px;
}

.lote-actual-label label,
.pieza-actual-label label {
  text-align: center;
  display: block;
  line-height: 10px;
}

.piezas-hora-label label,
.piezas-hora-estimado label,
.euros-hora-label label,
.euros-hora-estimado label,
.cycle-time-label label,
.estimacion-label label,
.total-time-label label,
.prediccion-label label {
  color: #009680;
  display: block;
  text-align: center;
  line-height: 8px;
  font-size: 12px;
}

.cycle-time-tiempo label {
  display: block;
  text-align: center;
}

.cycle-time-desviacion label {
  text-align: center;
  display: block;
}

.separador-home {
  height: 50px;
  width: 1px;
  background: #dce2ea;
  float: left;
}

.datos-home-pieza-cont,
.datos-home-lote-cont {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #DCE2EA;
  margin: 3px 0;
  height: calc(50% - 3px);
  text-align: center;
}

.con-datosdestacados .datos-home-pieza-cont,
.con-datosdestacados .datos-home-lote-cont {
  height: calc(50% - 18px);
}

.lote-actual-tiempo,
.pieza-actual-tiempo {
  width: 80px;
}

.con-datosdestacados .datos-pieza-unica {
  height: calc(50% - 19px);
}

.datos-pieza-unica {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
  border: 10px solid transparent;
  margin: 1px 0;
  height: calc(50% - 3px);
}

.dato-pieza-unica {
  text-align: center;
  flex-basis: 0;
  flex-grow: 1;
}

.datos-pieza-unica .lote-actual-tiempo label.k-label {
  white-space: nowrap;
}

.margen-borde-inferior {
  border-bottom: 1px solid #DCE2EA;
}

.estimacion-tiempo {
  margin: 0 0 2px 0;
}

.clearfix.datos-cols-cont {
  border-top: 1px solid #dce2ea;
  display: flex;
}

.instalacion-estructura-1 .clearfix.datos-cols-cont {
  padding: 10px;
  display: block;
  height: calc(100% - 150px);
}

.datos-of-col,
.datos-pieza-lote-col {
  /* float: left; */
  width: 50%;
}

.cont-resumen-semana-home,
.cont-ejes-home {
  width: calc(50% - 20px);
  margin: 5px 10px 0px 10px;
  text-align: center;
}

.datos-pieza-lote-col {
  padding: 0 5px 0 5px;
  position: relative;
}

.datos-pieza-lote-col .k-i-loading {
  background-color: rgba(255, 255, 255, 0.87);
}

div#maquina.maquina {
  float: left;
}

.modelo-operario-cont {
  float: left;
  width: 65%;
}

.maquina-tiempo-estado-cont {
  float: left;
  text-align: center;
  width: 35%;
}

.header-home-maquina {
  width: calc(100% - 200px);
  float: left;
  margin: 20px 0 0 10px;
}

.programa-ejecucion-maquina {
  width: 100%;
  float: right;
  margin: 0px 5px 0px 0px;
}

.programa-ejecucion-maquina .k-label {
  line-height: 13px;
  height: 26px;
  overflow: hidden;
  width: 100%;
  display: flex;
  flex-direction: column-reverse;
  text-align: right;
  font-weight: bold;
}

.ejecucion {
  color: #C0EADA;
  display: block;
  font-size: 16px;
}

.parada {
  color: #E7CB68;
  display: block;
  font-size: 16px;
}

.microparada {
  color: #CF8729;
  display: block;
  font-size: 16px;
}

.preparacion {
  color: #096844;
  display: block;
  font-size: 16px;
}

.mantenimiento {
  color: #99AFC6;
  display: block;
  font-size: 16px;
}

.alarma {
  color: #D33737;
  display: block;
  font-size: 16px;
}

.alarma2 {
  color: #424242;
  display: block;
  font-size: 16px;
}

.apagada {
  color: #424242;
  display: block;
  font-size: 16px;
}

.rojoPlusDesviacion {
  color: #ff0000;
  display: block;
  font-size: 16px;
}

.datos-destacados-cont-home {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 3px 0 2px 0;
  border-bottom: solid 1px #dce2ea;
}

.dato-destacado-home {
  text-align: center;
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
  padding: 1px 5px;
  border-right: solid 1px #dce2ea;
}

.dato-destacado-home:last-child {
  border-width: 0;
}

.dato-destacado-home .label label {
  color: #009680;
  display: block;
  text-align: center;
  line-height: 8px;
  font-size: 12px;
}

.dato-destacado-home .dato label {
  font-size: 10px;
  display: block;
}

.menu-zabalduta .dato-destacado-home .dato label {
  width: 70px;
}

.dato-destacado-home .dato label {
  font-size: 9px;
  display: block;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 87px;
  margin: 2px Auto 0 auto;
}

/*home hornos*/
.datos-home-pieza-cont i {
  font-size: 32px;
  margin: 0;
  box-sizing: border-box;
  display: block;
}

.pieza-hora-porcentaje,
.euros-hora-porcentaje {
  text-align: center;
}

.datos-home-pieza-cont-col {
  width: 50%;
}

.datos-home-pieza-cont-separador {
  border-right: solid 1px #dce2ea;
  height: 35px;
  margin: 0 10px;
}

.datos-home-pieza-cont-col {
  width: calc(50% - 10px);
}

.datos-home-pieza-cont-col .pieza-dato-hora {
  width: calc(100% - 40px);
  float: left;
}

.datos-home-pieza-cont-col .pieza-icono-porcentaje {
  width: 40px;
  text-align: center;
  float: right;
}

.datos-home-pieza-cont-col .pieza-icono-porcentaje .icon-flechaabajo-alt-2 {
  color: #D33737;
}

.datos-home-pieza-cont-col .pieza-icono-porcentaje .icon-flechaarriba-alt-2 {
  color: #4287cc;
}

/*home hornos amaiera*/
/*home instalaciones*/
.tooltip-graf-home {
  background-color: rgba(255, 255, 255, 0.8);
  font-size: 13px;
  padding: 5px;
}

.instalacion-estructura-1 .fotomaquina {
  text-align: center;
  height: 117px;
  margin: 8px auto 0 auto;
}

.instalacion-estructura-1 .nombre-instalacion {
  text-align: center;
  width: 100%;
  font-weight: 700;
  font-size: 16px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
  margin: 2px 0 0 0;
}

.instalacion-estructura-2 {
  grid-column: span 2;
}

.instalacion-estructura-2 .clearfix.datos-cols-cont {
  height: calc(100% - 153px);
}

.instalacion-estructura-2 .datos-of-col:nth-child(2) {
  border-left: solid 1px #dce2ea;
}

.instalacion-estructura-2 .datos-of-col {
  padding: 10px 10px;
}

.instalacion-nombre-cont {
  text-align: center;
  color: #009680;
  display: block;
  width: 100%;
}

/*.instalacion-estructura-1 .c3,
.instalacion-estructura-2 .c3,
.instalacion-estructura-1 .grafico-presion,
.instalacion-estructura-2 .grafico-presion {
  height: 170px !important
}
*/
.instalacion-estructura-1 .grafico-forma-cont,
.instalacion-estructura-2 .grafico-forma-cont,
.instalacion-estructura-1 .c3,
.instalacion-estructura-2 .c3,
.instalacion-estructura-1 .grafico-presion,
.instalacion-estructura-2 .grafico-presion,
.instalacion-estructura-1 .cont-grafico-temperatura-label,
.instalacion-estructura-2 .cont-grafico-temperatura-label,
.instalacion-estructura-1 .cont-grafico-dato-label,
.instalacion-estructura-2 .cont-grafico-dato-label {
  height: calc(100% - 40px) !important;
  margin: 10px 0 0 0;
  max-height: 180px !important;
}

.c3-ygrid-line.graficoLineas-media line {
  stroke: #03a9f4 !important;
}

/*home instalaciones amaiera*/
/*HOME BERRIA AMAIERA*/
/* HOME TV HASIERA */
@keyframes agertu-anim {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.agertu-makina {
  animation: agertu-anim 2s;
}

.agertu-OEE {
  animation: agertu-anim 3s;
}

/* HOME TV AMAIERA */
.box-porcent {
  display: inline-block;
  border: solid 1px #333333;
  width: calc(14.28% - 6px);
  text-align: center;
  padding: 3px 0;
  margin: 0 3px 0 3px;
  font-size: 13px;
}

.datos-de-tiempo-inner .dt-der {
  float: left;
  width: 35%;
  background-color: rgba(255, 255, 255, 0.5);
  height: 100%;
}

.desvalter .estado-tiempo {
  background-color: transparent;
  padding: 0;
  text-align: center;
}

.desvalter .estado-tiempo-titulo {
  color: #24ab95;
}

.panelosoa .usuario {
  overflow: hidden;
  margin: 5px 0 0 0;
}

/*.panelosoa .usuario img {
  width: 18px;
  margin: 0 3px 0 0;
  float: left;
}*/
.panelosoa .usuario i {
  color: #22c4c4;
  font-size: 18px;
  vertical-align: middle;
  margin: 0 5px 0 0;
}

.inner-panel .contbarra {
  width: 100%;
  height: 5px;
  overflow: hidden;
  position: absolute;
  bottom: 0;
}

.inner-panel .contbarra .barrainner {
  height: 5px;
  background-color: #99c6b9;
  animation: zabalera 2s ease-in-out 0s infinite;
  /*zabalera 2s ease-in-out 0s infinite alternate*/
  margin: 0 auto;
}

.panel-alarma {
  height: 30px;
  padding: 0 0 0 8px;
}

.panel-alarma label:before {
  content: "";
  height: 25px;
  width: 25px;
  display: block;
  float: left;
  border-radius: 100%;
  opacity: 0.99;
  font-style: normal;
  font-weight: 900;
  font-family: "Font Awesome 5 Free";
  text-rendering: auto;
  font-size: 15px !important;
  color: #545559;
  text-align: center;
  line-height: 23px;
  margin: 0px 6px 0 0;
}

@keyframes zabalera {
  0% {
    width: 0;
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  100% {
    width: 100%;
    opacity: 0;
  }
}
.alerta-temperatura {
  height: 25px;
  width: 25px;
  background-color: #F9545B;
  border-radius: 100%;
  text-align: center;
  color: #ffffff;
  position: absolute;
  right: 2px;
  top: -8px;
}

.alerta-kw {
  height: 25px;
  background-color: #F9545B;
  border-radius: 100%;
  text-align: center;
  color: #ffffff;
  position: absolute;
  right: -2px;
  top: -5px;
  font-size: 16px;
  font-weight: 900;
  border-radius: 5px;
  padding: 2px 10px;
}

/*  HOME*/
/* HMI*/
div#panelosoaHmi.panelosoa .inner-panel {
  height: auto !important;
}

.cont-procesos .inner-panel {
  margin: 0 0 0px 0;
  min-height: 347px !important;
  overflow: hidden;
  border-radius: 5px 0 0 0;
  position: relative;
  min-height: 392px;
  margin: 0 0 25px 0;
  -webkit-transition: all 0.3s ease-in-out;
}

.panel-procesos {
  background-color: #ffffff;
  box-shadow: -8px 12px 18px 0 rgba(25, 42, 70, 0.13);
  position: relative;
  overflow: hidden;
  height: calc(100% - 25px);
}

.procesos-maquina-of-cont.panel-procesos {
  height: 330px;
  box-shadow: -8px 12px 18px 0 rgba(25, 42, 70, 0.13);
}

.panel-con-resumen-semana {
  overflow: visible !important;
}

.procesos-header .procesos-maquina-cont {
  width: 50%;
  float: left;
}

.maquina-cont .usuario {
  overflow: hidden;
  margin: 20px 0 6px 8px;
}

.maquina-cont .usuario img {
  width: 18px;
  margin: 0 0 0 0;
  float: left;
}

.procesos-header .proces-of-cont {
  width: 50%;
  float: left;
}

.panel-herramientas {
  height: 90px;
  margin: 0 5px;
  border-bottom: solid 1px #DCE2EA;
}

.panel-herramientas ul {
  padding: 0;
}

.panel-herramientas ul li {
  list-style-type: none;
  line-height: 15px;
}

.titulo-herramienta-inner {
  font-weight: bold;
}

.panel-herramientas ul li label {
  margin: 0 5px 0 0;
}

span.label-num-num {
  font-size: 13px;
}

.comandoprogramas {
  padding: 15px;
}

.lineas-programa {
  height: 170px;
  overflow-y: auto;
  font-size: 13px;
}

.lineas-programa label {
  display: block;
}

.grafico-horizontal {
  width: calc(100% - 6px);
  overflow: hidden;
  margin: 10px 3px 10px 3px;
}

.grafico-horizontal .graf-hor-part {
  float: left;
  height: 20px;
  background-color: #cdcdcd;
}

.resumensemana-panel-content {
  /*padding: 0 0 19px 0;*/
}

.name-container-offline {
  position: relative;
}

.name-value-offline {
  position: absolute;
  top: 38%;
  width: 100%;
  text-align: center;
  font-size: 22px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.bloques-info-offline {
  padding: 4px 10px;
}

label.bloques-info-label-offline {
  display: inline-block;
  margin-right: 10px;
  font-weight: bold;
  color: #22c4c4;
}

label.bloques-info-valor-offline,
label.bloques-info-label-offline {
  display: inline-block;
}

.doslineas label.bloques-info-valor-offline,
.doslineas label.bloques-info-label-offline {
  display: block;
  margin: 0;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.css_linea_lateral_1 {
  /*Ejecucion*/
  border-left-style: solid;
  border-color: #c0eae4;
}

.css_linea_lateral_2 {
  /*Parada*/
  border-left-style: solid;
  border-color: #EBE078;
}

.css_linea_lateral_3 {
  /*Preparacion*/
  border-left-style: solid;
  border-color: #0d6a30;
}

.css_linea_lateral_4 {
  /*Mantenimiento*/
  border-left-style: solid;
  border-color: #99afc6;
}

.css_linea_lateral_5 {
  /*Fin*/
  border-left-style: solid;
  border-color: deepskyblue;
}

.css_linea_lateral_6 {
  /*Alarma*/
  border-left-style: solid;
  border-color: #D33737;
}

.css_linea_lateral_7 {
  /*Pasiva*/
  border-left-style: solid;
  border-color: #878787;
}

.css_linea_lateral_8 {
  /*Apagado*/
  border-left-style: solid;
  border-color: #333333;
}

/*  HMI AMAIERA*/
.cont-repercutea {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

/*MAQUINAS CONF*/
.imagen-maquina kendo-fileselect.k-widget.k-upload.ng-untouched.ng-pristine.ng-valid {
  max-width: 310px;
  margin: 0 auto;
}

.imagen-maquina kendo-fileselect.k-widget.k-upload.ng-untouched.ng-pristine.ng-valid .k-dropzone {
  display: flex;
  flex-direction: column;
}

.imagen-maquina {
  margin: 20px 0 20px 0;
}

.imagen-maquina img {
  margin: 0 auto 20px auto;
}

.imagen-maquina kendo-fileselect.k-widget.k-upload.ng-untouched.ng-pristine.ng-valid .k-dropzone .k-button.k-upload-button {
  display: block;
  background: transparent;
  width: 100%;
  border-color: #22c4c4;
  color: #22c4c4;
  margin: 0 0 10px 0;
}

.configuracion-hmi-mecanizado {
  margin: 20px 0 32px 0px;
}

.configuracion-hmi-mecanizado kendo-switch.kendo-switch-tipo2 {
  width: 205px;
  float: left;
  margin: 0 10px 7px 0;
}

.switch-offline {
  margin: 16px 0px;
}

.imagen-isometrico-maquina {
  margin: 33px 0;
}

.imagen-isometrico-maquina img {
  margin: 0 0px 20px 0px;
}

.maquina-conf-potenciometros-cont .float-left {
  margin: 0 10px 0 0;
}

.card.maquinas-conf-caracteristicas-conf kendo-multiselecttree.k-widget.k-dropdowntree {
  width: 100%;
}

.isometricos-popup .clickable.ng-star-inserted {
  float: left;
  cursor: pointer;
}

.isometricos-popup .clickable label {
  display: block;
}

.isometricos-popup .clickable.ng-star-inserted {
  float: left;
  margin: 10px 10px 0 10px;
}

/*MAQUINAS CONF AMAIERA*/
/*INYECTORA CONF*/
/*INYECTORA CONF AMAIERA*/
.card-header + .card-body.inyectora-conexion-cont {
  padding-top: 38px;
  padding-bottom: 40px;
}

/*HMI HORNOS TEMPLE*/
.datosconicono-cont {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  width: 100%;
  justify-content: space-around;
  margin: 35px 0;
}

.dato-icono-col .icono-cont {
  float: left;
}

.dato-icono-col .dato-cont {
  float: left;
}

.dato-icono-col .dato-cont label {
  display: block;
  text-align: center;
}

.dato-icono-col .dato-cont label.datosconicono-titulo {
  color: #22c4c4;
}

.dato-icono-col .icono-cont i {
  font-size: 45px;
  margin: 0 15px 0 0;
}

.temp-programada.dato-icono-col .icono-cont i {
  color: #22c4c4;
}

.temp-actual.dato-icono-col .icono-cont i {
  color: #64a996;
}

.consumo.dato-icono-col .icono-cont i {
  color: #03a9f4;
}

.dato-icono-col .dato-cont label.datosconicono-dato {
  font-size: 23px;
  font-weight: bold;
}

/*HMI HORNOS TEMPLE AMAIERA*/
/* HMI EXTRUSORA E IMPRESORAS*/
.titulo-panel-procesos {
  padding: 10px 0 0 18px;
}

.titulo-panel-procesos-texto {
  font-size: 19px;
  display: block;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.panel-mmin {
  background-color: #12192C;
  height: 50px;
  margin-bottom: 10px;
  margin-left: 5px;
  margin-right: 15px;
  text-overflow: ellipsis;
  overflow: hidden;
}

.label-mmin {
  font-size: 35px;
  color: white;
  margin-left: 10px;
}

.label-mmin-unidad {
  font-size: 20px;
  margin-top: 15px;
  color: #00b8c0;
}

.contenido-panel-procesos {
  padding: 10px 0 0 18px;
  height: 300px;
  overflow-x: hidden;
  overflow-y: auto;
}

.fila-panel-ext {
  margin-left: -8px;
  margin-top: 5px;
}

.panel-ext-label-title {
  font-size: 14px;
  font-weight: 400;
}

.panel-ext-label-title-home {
  font-size: 12px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: block;
}

.panel-ext-label-text {
  font-size: 16px;
  font-weight: 400;
  color: #878f9c;
}

.nombre-grafico-temperaturas {
  background-color: #e9f0f6;
  display: block;
  text-align: center;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 15px;
}

.nombre-grafico-temperaturas-barras {
  display: block;
  text-align: center;
  font-size: 15px;
  margin: -15px 12px 0 0;
}

.grafico-temperaturas {
  height: 110px;
  background-color: #e9f0f6;
}

.valor-grafico-temperaturas {
  display: block;
  text-align: center;
  color: #777777;
  font-size: 14px;
  height: 0px;
  overflow: visible;
  transform: translate(1px, -30px);
}

.of-label-linea {
  margin-bottom: 5px;
}

.of-label-titulo {
  font-size: 14px;
  font-weight: 400;
}

.of-label-text {
  margin-left: 6px;
  font-size: 14px;
  font-weight: 400;
  color: #878f9c;
}

.capa-container {
  height: 200px;
}

.title-capa {
  font-size: 18px;
  font-weight: 400;
  display: block;
  text-align: center;
  background-color: #F5F5F5;
  margin: 0 -7px 0 -7px;
  padding: 7px 0 7px 0px;
}

.capa-graficos-container {
  height: 160px;
  background-color: #F5F5F5;
  margin: 0 -7px 0 -7px;
}

.title-grafico-capas {
  display: block;
  text-align: center;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 14px;
  height: 45px;
}

.grafico-capas {
  height: 110px;
}

.valor-grafico-capas {
  display: block;
  text-align: center;
  color: #02babd;
  font-size: 16px;
  font-weight: 400;
  height: 0px;
  overflow: visible;
  transform: translate(1px, -35px);
}

.label-capa-valor {
  display: block;
  text-align: center;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 36px;
  color: #02BABD;
  margin-top: 20px;
}

.label-capa-valor-unidad {
  display: block;
  text-align: center;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 18px;
  color: #02BABD;
  margin-top: -5px;
}

/* HMI EXTRUSORA E IMPRESORAS AMAIERA */
/* HMI INYECTORAS */
.container-grafico-ejec {
  height: 170px;
  text-align: center;
}

.label-grafico-ejec {
  height: 25px;
  font-size: 16px;
  font-weight: 500;
  color: #18d6b0;
}

.grafico-ejec {
  height: 135px;
}

.container-tiempos {
  height: 60px;
  margin: 0;
}

.tiempos-inyectoras-tit {
  color: #009680;
  display: block;
  text-align: center;
  line-height: 8px;
  font-size: 12px;
}

.celda-tiempos {
  margin-bottom: 10px;
  text-align: center;
  font-size: 0.9rem;
  padding: 0;
}

.celda-tiempos-medio {
  border-left: 1px solid #b6e9df !important;
  border-right: 1px solid #b6e9df !important;
  margin-bottom: 10px;
  text-align: center;
  font-size: 0.9rem;
  padding: 0;
}

.celda-tiempos-labelmini {
  font-size: 0.7rem;
}

.linea-ciclos-inyectora {
  cursor: pointer;
  transition: background-color 0.5s;
}

.linea-ciclos-inyectora > label {
  cursor: pointer;
}

.linea-ciclos-inyectora:hover {
  background: #7bd9bba1 !important;
}

.ciclo-seleccionado {
  background: #7bd9bba1 !important;
}

.ciclo-rojo {
  background: #df1f1fa1 !important;
  color: #ffffff;
}

.titulos-datos-cont {
  color: #000000;
  background-color: #f8f8f8;
  font-weight: 700;
}

/* HMI INYECTORAS AMAIERA */
/*GRID*/
.k-grid {
  /*  font-size: 12px;*/
  font-size: 14px;
}

.k-grid.grid-font-10 td,
.k-grid.grid-font-10 th {
  font-size: 12px !important;
  line-height: 20px !important;
}

.k-grid.grid-font-10 td.k-hierarchy-cell.ng-star-inserted {
  font-size: 12px !important;
  line-height: 12px !important;
}

.modal-body .k-grid .k-group-col,
.modal-body .k-grid .k-hierarchy-col {
  width: 3px !important;
}

kendo-grid.k-widget.k-grid {
  border-width: 0;
  padding-bottom: 5px;
}

.k-grid-header,
kendo-grid-toolbar.k-grid-toolbar.k-header {
  background-color: transparent;
}

.k-grid th,
.k-grid td,
.k-grid th.k-header.k-filterable .k-grid-header-wrap th,
.k-grid-header-wrap td,
.k-grid-header-wrap th.k-header.k-filterable {
  border-width: 0;
  cursor: pointer;
}

.k-grid th {
  font-size: 12px;
  font-weight: bold;
}

.k-grid th.k-header.k-filterable span.k-link {
  font-weight: bold;
}

/*
.k-grid-table-wrap tr {
  background-color: rgba(0, 0, 0, 0.04);
}

.k-grid-table-wrap tr.k-alt {
  background-color: initial;
}*/
/*grid berria*/
.k-grid-table-wrap tr {
  background-color: #F5F6F8;
}

.k-grid-table-wrap tr.k-alt {
  background-color: #FBFBFB;
}

.k-grid tbody td {
  padding: 8px 12px;
  border-bottom: solid 1px #E6E9EF;
  white-space: nowrap;
}

/*.k-grid .k-grid-content td {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}*/
span.k-icon.k-i-filter {
  width: 26px;
  opacity: 0.99;
  display: inline-block;
  font-style: normal;
  font-weight: 900;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: inline-block;
  font-family: "Font Awesome 5 Free";
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  visibility: visible;
  font-size: 14px !important;
  margin-right: 15px;
  transition: all 0.5s;
}

.k-i-filter::before {
  content: "";
}

kendo-grid table {
  border-spacing: 1px;
}

td.p-0 button {
  width: 100%;
  padding: 7px 12px;
}

.celda-completa {
  display: block;
  padding: 7px 12px;
}

.k-grid tbody td.k-hierarchy-cell {
  padding: 0 !important;
}

.k-grid .k-hierarchy-cell > .k-icon,
.k-grid .k-drag-cell > .k-icon {
  padding: 7px 0px;
  color: #22c4c4;
}

.k-grid span.grid-circulo {
  background: #22c4c4;
  color: #ffffff;
  display: inline-block;
  height: 25px;
  width: 25px;
  border-radius: 20px;
  text-align: center;
  line-height: 26px;
  margin-left: -10px;
  margin-top: -7px;
  margin-bottom: -7px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  overflow: hidden;
}

.k-grid span.grid-circulo.grid-circulo-mas {
  font-size: 9px !important;
}

.k-grid span.grid-circulo img.operarios {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.k-grid span.grid-circulo img.maquinas {
  max-width: 100%;
  max-height: 100%;
}

.cont-barraprogreso-grid {
  background-color: #C2C2C2;
  height: 20px;
  pointer-events: none;
}

.barraprogreso-grid {
  height: 100%;
  max-width: 100%;
}

.barraprogreso-enProceso {
  background-color: #22c4c4;
}

.barraprogreso-acabada {
  background-color: #03a9f4;
}

.barraprogreso-validada {
  background-color: #009680;
}

.barraprogreso-apartada {
  background-color: #ee8625;
}

.barraprogreso-achatarrada {
  background-color: #ff6347;
}

label.label-barraprogreso-grid {
  margin: -18px auto 0px auto;
  display: block;
  text-align: center;
  color: #ffffff;
}

/*grid hierárquico*/
.k-grid tbody td.k-detail-cell {
  padding: 0;
}

td span p {
  margin: 0;
}

/*grid berria amaiera*/
.k-grid-aria-root .k-grid-aria-root tr th {
  /* background: #8ad2d2; */
  background: #ffffff;
}

.k-grid-aria-root tr:hover td.k-hierarchy-cell .k-grid-aria-root a,
td.k-hierarchy-cell a {
  color: #8ad2d2;
}

table.k-grid-table:has(td.k-hierarchy-cell) colgroup col.ng-star-inserted:first-child {
  width: 15px !important;
}

.k-pager-numbers .k-link:hover,
.k-pager-numbers .k-link.k-state-hover,
.k-pager-numbers .k-link.k-state-selected {
  color: #18d6b0;
  background-color: rgba(24, 214, 176, 0.13);
}

.k-pager-numbers .k-link {
  color: #18d6b0;
}

.virtualizado.k-grid tbody td {
  white-space: nowrap;
  line-height: 20px;
  padding: 8px 12px;
}

.virtualizado.k-grid .k-grid-content td {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

kendo-treelist-list.k-grid-container .k-icon {
  margin: 0 10px 0 0;
}

/*grid filtroa*/
.k-filter-menu-container .k-radio-list.k-reset li {
  margin: 0 5px 0 0;
}

/*grid filtroa amaiera*/
/*scroll bereziak grid*/
.k-grid-content::-webkit-scrollbar-track {
  background: #ffffff;
}

/*scroll bereziak grid amaiera*/
/*GRID amaiera*/
/*PLAN DE ACCIÓN*/
label.perdida-bloque-titulo {
  display: block;
  text-align: center;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

label.perdida-bloque-horas {
  display: block;
  text-align: center;
  font-size: 2em;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

label.perdida-bloque-porcentaje {
  display: block;
  text-align: center;
  font-size: 1em;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.perdidas-bloque .card {
  color: #f5f5f5;
  background-color: #CAD3DD;
}

.perdidas-bloque .card .card-body {
  padding: 0.5rem;
}

.perdidas-bloque i {
  position: absolute;
  right: 35px;
  bottom: 45px;
  font-size: 4em;
}

.perdidas-periodo .card {
  background-color: #54bfd3 !important;
}

.variacion-periodo .card {
  background-color: #f2a225 !important;
}

.perdidas-global .card {
  background-color: #64a996 !important;
}

.variacion-global .card {
  background-color: #ee8625 !important;
}

.card.minigrid .card-body {
  padding: 0;
}

.card.minigrid .k-grid-header {
  display: none !important;
}

.label-fecha-pa {
  font-size: 18px;
  margin: 2px 0px 0px 2px;
  font-weight: 400;
  color: #22c4c4;
}

.icono-med-pa {
  font-size: 20px;
  margin-bottom: 4px;
}

.icono-peq-pa {
  font-size: 14px;
  margin-bottom: 4px;
  margin-left: 5px;
}

/*PLAN DE ACCIÓN amaiera*/
/*INFORME INCIDENCIAS*/
.perdida-bloque-horas-sin-incidencias,
.perdida-bloque-horas-sin-horas {
  margin: 0 auto;
  display: block;
  text-align: center;
  margin: 16px 0px;
  font-size: 20px;
}

/*INFORME INCIDENCIAS amaiera*/
/*seguimientos proyectos*/
label.valor-label {
  margin: 0;
  font-size: 17px;
  color: #22c4c4;
}

label.valor-label-porcenseguimiento {
  font-size: 34px;
  color: #22c4c4;
  margin: 0;
}

/*seguimientos proyectos amaiera*/
/*CONTROL DE ASIGNACIONES*/
.ca-izq {
  float: left;
  /*width: 276px;*/
  width: 738px;
  margin-right: 10px;
}

.ca-der {
  float: left;
  width: calc(100% - 748px);
}

.card.fechas-control-de-asignaciones .card-body {
  padding: 5px;
}

.cont-graf-ca {
  text-align: center;
}

.card.cont-graf-ca .card-body {
  padding: 0;
}

.cont-graf-ca .c3-gauge-value,
.cont-graf-ca .c3-legend-item {
  display: none;
}

label.label-grafico-asignaciones {
  display: block;
  font-size: 18px;
  color: #18d6b0;
  /*margin: 0 0 30px 0;*/
}

label.label-valor-total-asignaciones {
  font-size: 21px;
  margin: 10px 0 0 0;
}

label.label-grafico-asignaciones.ejecucion {
  color: #22c4c4;
}

label.label-grafico-asignaciones.parada {
  color: #E7CB68;
}

.valor-total-asignaciones {
  border-right: solid 1px #dee2e6;
  padding: 0 15px;
  width: 50%;
}

.valores-totales-asignaciones {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-around;
}

.valor-total-asignaciones:last-child {
  border-width: 0;
}

label.label-grafico-asignaciones.alarmas {
  color: #D33737;
}

label.label-grafico-asignaciones.mantenimientos {
  color: #99AFC6;
}

label.label-grafico-asignaciones.apagadas {
  color: #424242;
}

.label-izq {
  margin-bottom: 0;
  font-size: 15px;
  color: #525252;
  white-space: nowrap;
  text-overflow: ellipsis;
  /*overflow: hidden;*/
}

.label-der {
  margin-bottom: 0;
  font-size: 15px;
  color: #525252;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.gridcontroldeasignaciones {
  font-size: 12px;
}

.ca-infocol {
  float: left;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 33.333%;
  flex: 0 0 33.333%;
}

.ca-infocol:after {
  border-right: solid 1px #ececec;
  content: "";
  display: block;
  height: 75px;
}

.ca-condonut {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.ca-condonut-izq {
  width: 130px;
}

.ca-condonut-der {
  width: calc(100% - 150px);
  text-align: left;
}

.ca-condonut {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

label.ca-label-titulo {
  color: #009680;
}

label.ca-label-valor {
  font-size: 15px;
}

.btn-cont-pordia {
  position: absolute;
  right: 0;
  top: 0;
  width: 100px;
}

.grafico-OperariosMaquinas-controlAsignaciones .c3 {
  max-height: 170px !important;
}

/*CONTROL DE ASIGNACIONES AMAIERA*/
/*  TOOLTIP GRAF*/
.tooltip-alarma {
  color: #D33737;
}

.c3-tooltip-container {
  color: #333333;
  /*border: solid 1px $color-principal;*/
}

.c3-tooltip {
  border-collapse: collapse;
  border-spacing: 25px !important;
  background-color: rgba(255, 255, 255, 0) !important;
  empty-cells: show;
  box-shadow: 7px 7px 12px -9px #777;
  min-width: 250px;
  backdrop-filter: blur(4px);
}

.c3-tooltip-container tbody tr:first-child th {
  color: #22c4c4;
  padding: 5px 15px;
  border-bottom: solid 1px #22c4c4;
  background-color: #ffffff;
}

/*.c3-tooltip-container tbody tr:first-child th {
  background-color: $color-principal;
  padding: 15px;
}
*/
.c3-tooltip tr {
  border: 0px solid #d8d8d8 !important;
  padding: 15px;
}

.c3-tooltip tr td {
  padding: 5px;
  background-color: rgba(255, 255, 255, 0.8);
  border-top: solid 1px #22c4c4;
}

/*
.c3-tooltip-container .c3-tooltip td {
  border-width: 0px !important;
  padding: 5px;
}
*/
/*  TOOLTIP GRAF*/
/*SELECTOR DE MÁQUINAS*/
/*berria*/
.cont-select-maquinas-out {
  background-color: #ffffff;
  height: 90px;
  margin-bottom: 20px;
  /*width: calc(100% - 260px);*/
  width: 130px;
  float: left;
  margin-right: 10px;
  box-shadow: -8px 12px 18px 0 rgba(25, 42, 70, 0.13);
  position: relative;
  transition: 0.3s ease all;
}

.cont-filtro-maquinas-areas .cont-select-maquinas-out {
  background-color: #ffffff;
  height: 90px;
  margin-bottom: 20px;
  /*width: calc(100% - 260px);*/
  width: 130px;
  float: left;
  box-shadow: -8px 12px 18px 0 rgba(25, 42, 70, 0.13);
  position: relative;
  margin-right: 0;
  transition: 0.3s ease all;
}

.cont-filtro-maquinas-areas .cont-select-maquinas-out.no-contraer-click-maquina {
  width: auto !important;
}

.cont-filtro-maquinas-areas .cont-select-maquinas-out.desplegar {
  width: calc(100% - 603px) !important;
}

.cont-select-maquinas-out.desplegar {
  width: calc(100% - 610px) !important;
}

.filtro-areaproductiva-cont {
  width: 250px;
  float: left;
  margin-right: 10px;
}

.cont-select-maquinas-out .desplegar-selector-maquina {
  position: absolute;
  right: -20px;
  top: 20px;
  width: 40px;
  height: 40px;
  cursor: pointer;
  border: solid 2px #d1ede9;
  border-radius: 100px;
  text-align: center;
  background-color: white;
}

.desplegar-selector-maquina i {
  margin: 10px 0 0 0;
  color: #22cfdf;
}

.cont-select-maquinas-out .desplegar-selector-maquina {
  z-index: 4;
}

.cont-select-maquinas-out .cont-select-maquinas {
  background-color: transparent;
  overflow: hidden;
  overflow-x: visible;
  white-space: normal;
  height: 90px;
  margin-bottom: 0;
  width: 100%;
  float: none;
  margin-right: 0;
  box-shadow: none;
}

.cont-select-maquinas-out.desplegar .cont-select-maquinas {
  height: auto;
  position: absolute;
  top: 0;
  background: white;
  z-index: 3;
  box-shadow: -8px 12px 18px 0 rgba(25, 42, 70, 0.13);
}

/*berria amaiera*/
/*editor informes personalizados*/
.cont-opciones-editor {
  float: right;
}

.contenedor-editor {
  /*margin: 130px 0 0 0;*/
  padding: 60px 0 0 0;
}

.opcion-draganddrop {
  float: left;
}

.cabecera-editor.card {
  position: absolute;
  top: 56px;
  right: 0;
  left: 60px;
  z-index: 1;
  padding: 10px;
}

.clearfix.contenedor-editor .card .card-header {
  padding: 2px;
}

.contenedor.body-content.menu-zabalduta .cabecera-editor.card {
  left: 251px;
}

.cont-opciones-editor button.btn.dropdown-toggle::after {
  display: none;
}

.cont-opciones-editor button.btn.dropdown-toggle i {
  pointer-events: none;
  font-size: 16px;
}

span.col-icono-cont,
span.uncol-icono,
span.doscol-icono,
span.trescol-icono {
  height: 30px;
  display: block;
  width: 30px;
  margin: 0 auto;
  padding: 0;
}

span.col-icono {
  background: #ebebeb;
  height: 30px;
  display: inline-block;
}

span.uncol-icono span.col-icono {
  width: 28px;
  margin: 0 1px 0px 1px;
}

span.doscol-icono span.col-icono {
  width: 13px;
  margin: 0 1px 0px 1px;
}

span.doslin-icono span.col-icono {
  width: 30px;
  height: 50%;
  margin: 2px 0px 0px 0px;
  display: block;
}

span.doslinder-icono.col-icono-cont span.col-icono:first-child {
  height: calc(100% - 2px);
  margin: 1px 1px;
}

span.doslinder-icono.col-icono-cont span.col-icono {
  background: #ebebeb;
  height: 13px;
  display: block;
  width: 13px;
  float: left;
  margin: 1px;
}

span.doslinizq-icono.col-icono-cont span.col-icono:nth-child(3) {
  margin: -14px 0 0 1px;
}

span.doslinizq-icono.col-icono-cont span.col-icono:nth-child(2) {
  height: calc(100% - 2px);
  margin: 1px 1px;
}

span.doslinizq-icono.col-icono-cont span.col-icono {
  background: #ebebeb;
  height: 13px;
  display: block;
  width: 13px;
  float: left;
  margin: 1px;
}

.doscolabajo-icono span.col-icono:first-child {
  width: calc(100% - 2px);
  margin: 0 1px;
}

.doscolabajo-icono span.col-icono {
  width: 13px;
  height: 13px;
  margin: 1px 1px 1px 1px;
  display: block;
  float: left;
}

.doscolarriba-icono span.col-icono:last-child {
  width: calc(100% - 2px);
  margin: 0 1px;
}

.doscolarriba-icono span.col-icono {
  width: 13px;
  height: 13px;
  margin: 1px 1px 1px 1px;
  display: block;
  float: left;
}

span.doslincol-icono span.col-icono {
  width: 13px;
  height: 13px;
  margin: 1px 1px 1px 1px;
  display: block;
  float: left;
}

span.trescol-icono span.col-icono {
  width: 8px;
  margin: 0 1px 0px 1px;
}

button.dropdown-item i {
  font-size: 10px;
  margin-right: 10px;
}

.cabecera-editor-titulo {
  float: left;
  width: calc(100% - 300px);
}

.agregar-elemento {
  border: solid 1px #dfdfdf;
  border-radius: 3px;
  margin: 10px 0 0 0;
  color: #dfdfdf;
  text-align: center;
  padding: 4px 0px 0px 0px;
  cursor: pointer;
  height: calc(100% - 10px);
}

.oculto-overlay {
  display: none;
}

.oculto .oculto-overlay {
  background: rgba(207, 207, 207, 0.66);
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: repeating-linear-gradient(-45deg, #a8a8a8, #a8a8a8 2px, #c9c9c9 2px, #c9c9c9 10px);
}

.oculto-overlay > i {
  text-align: center;
  width: 100%;
  display: block;
  font-size: 40px;
  top: calc(50% - 20px);
  position: absolute;
}

.out-selec-tipo-grafico {
  width: 25%;
  padding: 5px;
  float: left;
}

.selec-tipo-grafico {
  height: 130px;
  border: solid 1px #cdcdcd;
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  flex-direction: column;
  cursor: pointer;
}

.selec-tipo-grafico p {
  margin: 0 auto 5px auto;
  text-align: center;
}

.selec-tipo-grafico i {
  font-size: 55px;
  text-align: center;
  margin: 30px auto 0 auto;
}

.opcion-habilitar {
  float: right;
  cursor: pointer;
  margin: 2px;
  background: #a8a8a8;
  padding: 2px 7px;
  font-size: 12px;
  color: #ffffff;
}

.tipo-grafico-encolumna {
  border: solid 1px #dfdfdf;
  border-radius: 3px;
  color: #dfdfdf;
  text-align: center;
  padding: 4px 0px 0px 0px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  flex-direction: column;
  cursor: pointer;
  position: relative;
  height: 100px;
}

.tipo-grafico-encolumna .cont-opciones-editor {
  position: absolute;
  right: 0;
}

.tipo-grafico-encolumna > i {
  font-size: 35px;
  margin: 10px 0 0 0;
}

.tipo-grafico-encolumna p {
  margin: 0 auto 5px auto;
  text-align: center;
}

.tipo-grafico-encolumna .opcion-draganddrop {
  position: absolute;
  left: 7px;
  color: #000000;
}

/*editor informes personalizados amaiera*/
.cont-select-maquinas {
  background-color: #ffffff;
  overflow: hidden;
  overflow-x: auto;
  white-space: nowrap;
  height: 90px;
  margin-bottom: 20px;
  width: calc(100% - 370px);
  float: left;
  margin-right: 10px;
  box-shadow: -8px 12px 18px 0 rgba(25, 42, 70, 0.13);
}

.lista-planificados .cont-filtro-maquinas-areas {
  float: left;
  width: calc(100% - 220px);
  margin-right: 10px;
}

.lista-planificados .cont-filtro-informes {
  width: 210px;
  float: left;
}

.cont-select-maquinas-out.noturnos {
  width: 100%;
  float: none;
}

.cont-select-maquinas.noturnos {
  width: 100%;
  float: none;
}

.cont-select-maquinas .clickable,
.cont-select-maquinas .clickable1,
.cont-select-maquinas .clickable2,
.cont-select-maquinas .clickable3 {
  width: 130px;
  padding: 10px;
  text-align: center;
  cursor: pointer;
  height: 90px;
  position: relative;
  display: inline-block;
}

.cont-select-maquinas .clickable img,
.cont-select-maquinas .clickable1 img,
.cont-select-maquinas .clickable2 img,
.cont-select-maquinas .clickable3 img {
  max-width: 150px;
  max-height: 50px;
}

.cont-select-maquinas .clickable label,
.cont-select-maquinas .clickable1 label,
.cont-select-maquinas .clickable2 label,
.cont-select-maquinas .clickable3 label {
  cursor: pointer;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  font-size: 10px;
  font-weight: normal;
  white-space: normal;
  margin: 0;
  line-height: 1;
  height: 21px;
}

.cont-select-maquinas .clickable.actual,
.cont-select-maquinas .clickable1.actual1,
.cont-select-maquinas .clickable2.actual2,
.cont-select-maquinas .clickable3.actual3 {
  background: rgba(36, 171, 149, 0.21);
  /*float: left;*/
}

/*ARATZ START*/
.cont-select-maquinas-out .clickable {
  display: none;
}

.cont-select-maquinas-out .actual {
  display: inline-block !important;
  opacity: 1 !important;
}

.desplegar .clickable {
  display: inline-block !important;
  /*  animation-name: maquinaappear;
  animation-duration: 0.3s;*/
}

.desplegar .text-select-maquinas {
  display: none !important;
}

.cont-select-maquinas-out .clickable {
  opacity: 0;
  transition: all 0.3s;
}

.cont-select-maquinas-out.aparecer .clickable {
  opacity: 1;
  display: inline-block;
}

.h-90 {
  height: 90px !important;
}

.cont-select-maquinas.h-90 {
  pointer-events: none;
}

.cont-select-maquinas-conf.cont-select-maquinas {
  width: 100%;
  white-space: nowrap;
}

.scroll-Nserie {
  height: 300px;
  overflow-y: auto;
}

.k-grid-virtual .k-grid-content .k-grid-table {
  z-index: 0 !important;
}

.text-select-maquinas {
  text-align: center;
  margin: 20px auto 0 auto;
  max-width: 100px;
  font-size: 13px;
  color: #666666;
}

.text-nota {
  text-align: left;
  margin: 20px auto 0 auto;
  font-size: 11px;
  color: #a1a1a1;
  margin-bottom: 10px;
}

/*ARATZ END*/
/*SELECTOR DE MÁQUINAS AMAIERA*/
/*TOOLTIP GRID NUEVO*/
.tooltip-consumibles-contenido {
  position: relative;
}

.tooltipTextHeader:after {
  content: attr(contenido);
}

.tooltip-consumibles-contenido .tooltiptext,
.tooltip-consumibles-contenido .tooltiptextHome,
.tooltip-consumibles-contenido .tooltipTextHeader {
  display: none;
  min-width: 185px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 5px;
  position: fixed;
  z-index: 1;
  white-space: break-spaces;
  box-shadow: -8px 12px 18px 0 rgba(25, 42, 70, 0.13);
}

.home-resumen-semana:hover .tooltip-consumibles-contenido .tooltiptextHome,
.cont-tooltip-grafico:hover .tooltip-consumibles-contenido .tooltiptext,
.cont-tooltip-grafico:hover .tooltip-consumibles-contenido .tooltipTextHeader,
.celda-tooltip-externo:hover .tooltip-consumibles-contenido .tooltiptext,
th .tooltip-consumibles-contenido:hover .tooltipTextHeader,
.bloques-info-label .tooltip-consumibles-contenido:hover .tooltipTextHeader {
  display: block;
}

.tooltip-consumibles-contenido .tooltiptext label,
.tooltip-consumibles-contenido .tooltiptextHome label {
  display: block;
}

.k-grid td.celda-tooltip-externo {
  overflow: visible !important;
}

/*TOOLTIP GRID NUEVO AMAIERA*/
/*FILTRO TURNOS*/
.cont-filtro-informes label.control-label {
  float: left;
  width: 50px;
}

.cont-filtro-informes .caja {
  float: left;
  width: calc(100% - 50px);
}

.card.cont-filtro-informes .card-body .form-group {
  margin: 0 0 10px 0;
  overflow: hidden;
}

.cont-filtro-informes {
  width: 360px;
  float: right;
}

.filtro-informes-data-1 {
  width: calc(50% - 10px);
  float: left;
  margin-right: 10px;
}

.filtro-informes-data-2 {
  width: 50%;
  float: left;
}

.card.cont-filtro-informes .card-body {
  padding: 5px;
}

.card.cont-filtro-informes label {
  margin: 5px 0 0 0px;
  /*float: left;*/
}

.cont-filtro-informes .btn input {
  display: none;
}

.cont-filtro-informes .btn {
  width: 90px;
  text-align: center;
}

/*FILTRO TURNOS AMAIERA*/
/*INFORME RENDIMIENTO*/
p.oee-donut-label {
  text-align: center;
  font-size: 13px;
  margin: 0;
}

/*INFORME RENDIMIENTO*/
.barra-izq-oee .calendarFiltro-calendario.mostrarCalendario {
  background: transparent;
}

.barra-izq-oee .calendarFiltro-calendario.mostrarCalendario .row {
  justify-content: flex-end;
}

/*VALIDADOR*/
.card-body.botones-vistalargo {
  width: 220px;
}

.bloques-datos {
  background-color: rgba(34, 196, 196, 0.1);
  padding: 5px 10px;
  margin: 5px !important;
}

.bloques-datos label {
  display: block;
}

.bloques-datos label.valor {
  font-size: 18px;
  margin: 2px 0px 0px 2px;
  font-weight: 400;
  color: #22c4c4;
}

.bloques-datos .form-group {
  margin: 0;
}

.cuadrado-color-grid {
  width: 20px;
  height: 20px;
  display: block;
  text-indent: -99999px;
}

.cuadrado-color-grid.ejecucion {
  background-color: #C0EADA;
}

.cuadrado-color-grid.parada {
  background-color: #E7CB68;
}

.cuadrado-color-grid.microparada {
  background-color: #CF8729;
}

.cuadrado-color-grid.preparacion {
  background-color: #096844;
}

.cuadrado-color-grid.mantenimiento {
  background-color: #99AFC6;
}

.cuadrado-color-grid.alarma {
  background-color: #D33737;
}

.cuadrado-color-grid.alarma2 {
  background-color: #424242;
}

.cuadrado-color-grid.apagada {
  background-color: #424242;
}

.cuadrado-color-grid-inner {
  width: 0;
  height: 0;
  border-top: 20px solid;
  border-right: 20px solid transparent !important;
  margin: -20px 0 0 0;
}

/*VALIDADOR AMAIERA*/
.grupo-graficos-informetecnicoejecucion .c3 {
  max-height: 550px !important;
}

/*INFORME TECNICO DE EJECUCIONES*/
.lineas-ejes-infteceje .col .bloques-info {
  margin: 0 1px 8px 1px !important;
}

.lineas-ejes-infteceje .col {
  padding: 0;
  margin: 0;
}

.lineas-poten-infteceje .col {
  margin: 0;
  padding: 0;
}

.lineas-poten-infteceje .col label.titulo-eje {
  margin: 0 0;
  min-height: 38px;
}

.panel-menu {
  position: fixed;
  bottom: 0;
  top: 56px;
  right: 0;
  width: 0px;
  background-color: white;
  transition: all 500ms;
  z-index: 4;
}

.panel-menu.desplegado {
  width: 350px;
  box-shadow: -16px 12px 18px 0 rgba(25, 42, 70, 0.3);
}

.panel-menu.desplegado .contenedor-eje {
  margin: 0 !important;
}

.panel-menu.desplegado .contenedor-eje::after {
  display: block;
  clear: both;
  content: "";
}

.panel-menu-inner {
  display: none;
}

.panel-menu.desplegado .panel-menu-inner {
  display: block;
}

.panel-menu.desplegado .titulo-maquina {
  margin: 0;
  font-weight: 400;
  color: white;
  font-size: 20px;
  border-bottom: solid 0px white;
  padding: 0 0 0 0;
}

.bloque-innermenu-white {
  background-color: white;
}

.panel-menu.desplegado ul li {
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
}

.panel-menu.desplegado .dt-izq {
  margin: 0 0 0 10px;
}

.panel-menu.desplegado ul li label {
  width: auto;
}

.panel-menu.desplegado .datos-de-tiempo-inner {
  display: flex;
  justify-content: space-between;
}

.bloque-innermenu-white {
  background-color: white;
}

.card-menu-desplegado {
  border-width: 0;
  margin: 0 0 0 0;
}

.switch-row {
  margin-left: 25px;
}

.desplegar-menu-lateral {
  position: absolute;
  right: 25px;
  top: 4px;
  width: 40px;
  height: 40px;
  cursor: pointer;
  border: solid 2px #d1ede9;
  border-radius: 100px;
  text-align: center;
}

.desplegar-menu-lateral i {
  margin: 10px 0 0 0;
  color: #22cfdf;
}

.granate .k-switch-container {
  background-color: #8B0707;
}

.azul .k-switch-container {
  background-color: #3366CC;
}

.naranja .k-switch-container {
  background-color: #FF9900;
}

.morado .k-switch-container {
  background-color: #9836CC;
}

.verde .k-switch-container {
  background-color: #B5E50A;
}

/*INFORME TECNICO DE EJECUCIONES AMAIERA*/
/*INFORME TECNICO DE EJECUCIÓN*/
.tooltip-gantt.hover-line {
  height: 0;
  border: 0;
  background: transparent;
}

.tooltipa p span {
  white-space: nowrap;
}

.tooltip-gantt.hover-line .arrow {
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid #646464;
  left: calc(50% - 10px);
  animation: myAnim 1.5s ease-in-out infinite;
  border-width: 0;
}

.tooltip-gantt.hover-line .arrow:after {
  content: "";
  opacity: 0.99;
  font-style: normal;
  font-weight: 900;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: inline-block;
  font-family: "Font Awesome 5 Free";
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  visibility: visible;
  font-size: 20px !important;
  margin-right: 15px;
  transition: all 0.5s;
}

@keyframes myAnim {
  0%, 100% {
    bottom: 16px;
  }
  50% {
    bottom: 8px;
  }
}
.editar-leyenda-cont .card .form-group.switch-row {
  width: 100%;
  margin: 2px;
}

.editar-leyenda-cont .row {
  padding: 0;
  margin: 0;
}

.editar-leyenda-cont .col {
  margin: 0;
  padding: 0px 2px;
}

.editar-leyenda-cont .card {
  background-color: #F5F6F8;
}

.editar-leyenda-cont .card h3 {
  border-width: 0;
  text-align: center;
  margin: 0;
}

.editar-leyenda-cont-izq {
  float: left;
  width: calc(100% - 50px);
}

.editar-leyenda-cont-der {
  float: left;
  width: 50px;
  position: relative;
}

.datos-of-content.informe-tec-de-eje-datos li label:first-child {
  text-overflow: ellipsis;
  white-space: nowrap;
}

.datos-of-content.informe-tec-de-eje-datos li label.bloques-info-valor {
  text-align: right;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-weight: 600;
}

/*INFORME TECNICO DE EJECUCIÓN AMAIERA*/
/*  PLANIFICADOR LARGO*/
.bloques-info {
  /* background-color: #f0fdff;*/
  padding: 5px 10px;
  margin: 5px !important;
  border-bottom: solid 1px #e5e5e5;
}

.botones-vistalargo .btn {
  margin: 2px;
  width: calc(50% - 4px);
}

label.bloques-info-label {
  display: inline-block;
  margin-right: 10px;
  font-weight: bold;
  color: #22c4c4;
}

label.bloques-info-valor,
label.bloques-info-label {
  display: inline-block;
}

.doslineas label.bloques-info-valor,
.doslineas label.bloques-info-label {
  display: block;
  margin: 0;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.bloques-info label.titulo-eje {
  text-align: center;
  min-height: 38px;
  display: block;
}

.bloques-info.form-group {
  margin: 0;
}

label.nombregrupo {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 5px;
  left: 15px;
  z-index: 0;
  pointer-events: auto;
  background-color: #eeeeee;
  /* padding: 45px; */
  font-size: 29px;
  writing-mode: vertical-lr;
  text-align: center;
  transform: rotate(180deg);
  line-height: 2;
}

.cont-nombre-maquina {
  padding-right: 0;
}

.cont-inner-nombre-maquina {
  /*background-color: #ececec;*/
  /*height: calc(100% - 7px);*/
  height: 100%;
}

label.nombremaquina {
  margin: 15px 0 5px 15px;
}

.cont-inner-nombre-maquina img {
  width: 100%;
  margin: 0 auto;
  padding: 15px;
}

.col-graficos-largo {
  padding-right: 15px;
}

/*
.col-graficos-largo:before {
  content: "";
  background: #ececec;
  display: block;
  position: absolute;
  bottom: 0px;
  top: 0;
  right: 15px;
  left: 0;
}*/
.col-graficos-largo:before {
  content: "";
  display: block;
  position: absolute;
  bottom: -4px;
  top: 0;
  right: 15px;
  left: 0;
  border-bottom: solid 1px #eeeeee;
}

.cont-nombre-maquina:before {
  content: "";
  display: block;
  position: absolute;
  bottom: -4px;
  top: 0;
  right: 0;
  left: 0;
  border-bottom: solid 1px #eeeeee;
}

.plan-largo-colgraf > .row {
  margin-bottom: 5px;
}

.btn-pasarsemana {
  background-color: white;
  border: solid 1px #22c4c4;
  color: #22c4c4;
  font-size: 12px;
  padding: 5px 15px;
  margin-bottom: 5px;
}

.cont-pasarsemana {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 6px;
}

.clearfix.filtro-planificador-largo-cont {
  display: flex;
  gap: 4px;
}

.filtro-planificador-largo-cont-inner {
  display: flex;
  gap: 4px;
}

.filtro-planificador-largo-col:has(.filtro-planificador-largo-cont-inner) {
  margin-left: auto;
}

.menu-zabalduta .filtro-planificador-largo-col kendo-multiselect.k-widget.k-multiselect.ng-untouched.ng-pristine.ng-valid {
  width: 190px !important;
}

.menu-zabalduta .filtro-planificador-largo-version:has(.btn-danger) label {
  display: none;
}

/*  PLANIFICADOR LARGO AMAIERA*/
/*ANALISIS RENDIMIENTO*/
.bloques-turnos-graf {
  display: flex;
  justify-content: space-between;
  margin: 5px 25px 19px 25px;
  gap: 15px;
}

.con-selector-horaspiezas {
  display: flex;
  flex-direction: column;
  flex: 1;
}
.con-selector-horaspiezas input {
  display: none;
}
.con-selector-horaspiezas .btn {
  padding: 0;
}
.con-selector-horaspiezas .btn:first-child {
  border-radius: 5px 5px 0 0;
  border: solid 1px #D0D4E4;
  border-bottom: 0;
}
.con-selector-horaspiezas .btn:last-child {
  border-radius: 0 0 5px 5px;
  border: solid 1px #D0D4E4;
  border-top: 0;
}
.con-selector-horaspiezas .btn label {
  padding: 3px 8px;
  margin: 0;
  display: block;
}
.con-selector-horaspiezas .btn.btn-outline-success label {
  color: #D0D4E4;
}

.bloques-turnos {
  display: flex;
  flex-direction: row;
  gap: 15px;
  flex: 10;
}

.separador {
  background: #565656;
  width: 1px;
}

.inner-bloques-turnos {
  display: flex;
  align-items: center;
  padding: 3px;
  line-height: 21px;
}

.bloques-turnos-graf .bloque-turno {
  border: solid 1px #333333;
  border-radius: 5px;
  flex: 1;
}
.bloques-turnos-graf .cont-icono-circular {
  border-radius: 100%;
  padding: 5px;
}
.bloques-turnos-graf .cont-icono {
  border-right: solid 1px #333333;
  padding: 0 10px;
}
.bloques-turnos-graf .datosybarra {
  padding: 0 10px;
  flex: 1;
  text-align: center;
}
.bloques-turnos-graf .piezas-buenasmalas-cont {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.bloques-turnos-graf .piezas-buenas label {
  font-size: 20px;
}
.bloques-turnos-graf .piezas-malas {
  color: #D33737;
  font-size: 13px;
  display: flex;
  gap: 2px;
  flex-direction: row;
  align-items: center;
}
.bloques-turnos-graf i.icon-check {
  color: #22c4c4;
}
.bloques-turnos-graf .tooltip-consumibles-contenido .tooltiptext {
  background-color: #ffffff;
}
.bloques-turnos-graf .tooltip-consumibles-contenido .tooltiptext label {
  color: #333333;
}
.bloques-turnos-graf .tooltip-consumibles-contenido .tooltiptext label.piezasmalas {
  color: #D33737;
}
.bloques-turnos-graf .tooltip-consumibles-contenido .tooltiptext .dia {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.bloques-turnos-graf .tooltip-consumibles-contenido .tooltiptext .dia.tooltipdiaheader {
  border-bottom: solid 1px #D8D8D8;
}
.bloques-turnos-graf .tooltip-consumibles-contenido .tooltiptext .dia > label {
  flex: 4;
  text-align: left;
}
.bloques-turnos-graf .tooltip-consumibles-contenido .tooltiptext .total.dia {
  font-weight: 600;
}
.bloques-turnos-graf .tooltip-consumibles-contenido .tooltiptext .piezas-turnos-cantidades {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex: 1;
  gap: 15px;
}
.bloques-turnos-graf .tooltip-consumibles-contenido .tooltiptext .linea-tooltip.linea-tooltipheader {
  border-bottom: solid 1px #D8D8D8;
  height: 23px;
  margin-bottom: 5px;
}

.barra-simple {
  height: 4px;
  width: 100%;
  background-color: #F8F8F8;
}
.barra-simple .barra-simple-progreso {
  height: 4px;
}

.mannana.bloque-turno.celda-tooltip-externo {
  border-color: #3BF0FD;
}
.mannana.bloque-turno.celda-tooltip-externo .piezas-buenas label {
  color: #3BF0FD;
}
.mannana.bloque-turno.celda-tooltip-externo .cont-icono {
  border-color: #3BF0FD;
}
.mannana.bloque-turno.celda-tooltip-externo .cont-icono-circular {
  background: rgba(59, 240, 253, 0.1);
}
.mannana.bloque-turno.celda-tooltip-externo .barra-simple-progreso {
  background: #3BF0FD;
}

.tarde.bloque-turno.celda-tooltip-externo {
  border-color: #D9E497;
}
.tarde.bloque-turno.celda-tooltip-externo .piezas-buenas label {
  color: #D9E497;
}
.tarde.bloque-turno.celda-tooltip-externo .cont-icono {
  border-color: #D9E497;
}
.tarde.bloque-turno.celda-tooltip-externo .cont-icono-circular {
  background: rgba(217, 228, 151, 0.25);
}
.tarde.bloque-turno.celda-tooltip-externo .barra-simple-progreso {
  background: #D9E497;
}

.noche.bloque-turno.celda-tooltip-externo {
  border-color: #1D7076;
}
.noche.bloque-turno.celda-tooltip-externo .piezas-buenas label {
  color: #1D7076;
}
.noche.bloque-turno.celda-tooltip-externo .cont-icono {
  border-color: #1D7076;
}
.noche.bloque-turno.celda-tooltip-externo .cont-icono-circular {
  background: rgba(29, 112, 118, 0.1);
}
.noche.bloque-turno.celda-tooltip-externo .barra-simple-progreso {
  background: #1D7076;
}

.total.bloque-turno.celda-tooltip-externo {
  border-color: #565656;
}
.total.bloque-turno.celda-tooltip-externo .piezas-buenas label {
  color: #565656;
}
.total.bloque-turno.celda-tooltip-externo .cont-icono {
  border-color: #565656;
}
.total.bloque-turno.celda-tooltip-externo .cont-icono-circular {
  background: rgba(86, 86, 86, 0.1);
}
.total.bloque-turno.celda-tooltip-externo .barra-simple-progreso {
  background: #565656;
}

.chart-tooltip {
  background-color: #ffffff;
  padding: 10px;
  box-shadow: -8px 12px 18px 0 rgba(25, 42, 70, 0.13);
}
.chart-tooltip .linea-tooltip {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 25px;
}
.chart-tooltip label.piezas-malas {
  color: #D33737;
}
.chart-tooltip i.icon-check {
  color: #22c4c4;
}
.chart-tooltip .mannana {
  color: #3BF0FD;
}
.chart-tooltip .tarde {
  color: #D9E497;
}
.chart-tooltip .noche {
  color: #1D7076;
}
.chart-tooltip .total {
  color: #565656;
  font-weight: 600;
}
.chart-tooltip label.piezacero {
  color: #000000;
}
.chart-tooltip label.piezasmalas {
  color: #D33737;
}
.chart-tooltip .linea-tooltip {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.chart-tooltip .linea-tooltip.tooltipdiaheader {
  border-bottom: solid 1px #D8D8D8;
}
.chart-tooltip .linea-tooltip > label {
  flex: 4;
  text-align: left;
}
.chart-tooltip .total.linea-tooltip {
  font-weight: 600;
}
.chart-tooltip .piezas-turnos-cantidades {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex: 1;
  gap: 15px;
}
.chart-tooltip .linea-tooltip.linea-tooltipheader {
  border-bottom: solid 1px #D8D8D8;
  height: 23px;
  margin-bottom: 5px;
}

.cont-equis-vacio {
  position: absolute;
  left: calc(50% - 61px);
}
.cont-equis-vacio.equispiezasturnos {
  left: calc(50% - 61px);
}

.cont-equis-vacio p {
  text-align: center;
  background: #ffffff;
  margin-top: -25px;
  position: relative;
  border-radius: 25px;
}

.cont-piezasturno-vacio > img {
  width: 100%;
  max-height: 100%;
  margin: 0 10px;
}

/*ANALISIS RENDIMIENTO AMAIERA*/
/*  ANALITICA AVANZADA DE EJECUCIONES*/
.bloques-container-aae {
  margin: 0px 15px 0px 15px;
}

.bloques-info-aae {
  background-color: #f5f5f5;
  padding: 8px 13px;
  margin: 5px !important;
  overflow: hidden;
}

label.bloques-info-label-aae {
  display: inline-block;
  margin-right: 10px;
}

label.bloques-info-valor-aae,
label.bloques-info-label-aae {
  display: inline-block;
}

.doslineas label.bloques-info-valor-izquierda-aae,
.doslineas label.bloques-info-label-izquierda-aae {
  display: block;
  margin: 0;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 12px;
  float: left;
}

.doslineas label.bloques-info-valor-derecha-aae,
.doslineas label.bloques-info-label-derecha-aae {
  display: block;
  margin: 0;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 12px;
  float: right;
}

.bloques-info-aae.form-group {
  margin: 0;
}

/*ANALITICA AVANZADA DE EJECUCIONES AMAIERA*/
/*  COMPARATIVA DE PROYECTOS*/
.bloques-container-cp {
  margin: 0px 15px 0px 15px;
}

.bloques-info-cp {
  background-color: #f5f5f5;
  padding: 8px 13px;
  margin: 5px !important;
  overflow: hidden;
}

label.bloques-info-label-cp {
  display: inline-block;
  margin-right: 10px;
}

label.bloques-info-valor-cp,
label.bloques-info-label-cp {
  display: inline-block;
}

.doslineas label.bloques-info-valor-izquierda-cp,
.doslineas label.bloques-info-label-izquierda-cp {
  display: block;
  margin: 0;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 14px;
  float: left;
}

.doslineas label.bloques-info-valor-derecha-cp,
.doslineas label.bloques-info-label-derecha-cp {
  display: block;
  margin: 0;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 14px;
  float: right;
}

.bloques-info-cp.form-group {
  margin: 0;
}

/*COMPARATIVA DE PROYECTOS AMAIERA*/
/* HISTÓRICO */
.botones-pareja-historico .btn {
  float: left;
  width: calc(50% - 8px);
  margin: 2px;
}

.historico .bloques-info {
  background-color: transparent;
  padding: 5px 10px;
  margin: 5px !important;
  /* width: 30%; */
  float: left;
}

.historico label.bloques-info-valor,
.historico label.bloques-info-label {
  display: block;
}

.historico label.bloques-info-label {
  color: #22c4c4;
}

.tEstimado-historicoOperaciones {
  outline: solid 1px black;
  border-radius: 3px;
  padding: 0 5px;
  font-weight: 700;
}

.tEjecucion-historicoOperaciones {
  outline: solid 1px #C0EADA;
  border-radius: 3px;
  padding: 0 5px;
  font-weight: 700;
  color: #C0EADA;
}

.tPreparacion-historicoOperaciones {
  outline: solid 1px #096844;
  border-radius: 3px;
  padding: 0 5px;
  font-weight: 700;
  color: #096844;
}

.desvioTiempo-historicoOperaciones {
  outline: solid 1px #ff6347;
  border-radius: 3px;
  padding: 0 5px;
  font-weight: 700;
  color: #ff6347;
}

.desvioPorcen-historicoOperaciones {
  outline: solid 1px black;
  border-radius: 3px;
  padding: 0 5px;
  font-weight: 700;
}

.refPieza-historicoOperaciones,
refPieza-historicoOperaciones-label,
.cliente-historicoOperaciones,
.cantidad-historicoOperaciones,
.plano-historicoOperaciones,
.estado-historicoOperaciones {
  font-weight: 800;
}

/*HISTÓRICO AMAIERA*/
/*HISSTORICO PROCESOS*/
.img-operacion,
.img-operario {
  width: 130px;
  padding: 2px;
  text-align: center;
  cursor: pointer;
  position: relative;
  display: inline-block;
}

.imagenes-histoperacion {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(395px, 1fr));
  gap: 10px;
  align-items: start;
}

.imagenes-histoperacion img {
  width: 100%;
  height: auto;
}

/*HISSTORICO PROCESOS AMAIERA*/
/* GRAFIKOAK */
.card .c3 line,
.card .c3 path,
.card-menu-desplegado .c3 line,
.card-menu-desplegado .c3 path,
.panel-campo .c3 line,
.panel-campo .c3 path,
.panel-procesos .c3 line,
.panel-procesos .c3 path {
  stroke: #cdcdcd;
}

.c3 path.c3-shape.c3-shape.c3-arc {
  stroke: #ffffff !important;
}

tspan {
  fill: #848282;
}

.c3-shapes.c3-bars > path {
  stroke: white !important;
  stroke-width: 1px !important;
  stroke-opacity: 1 !important;
}

.c3-shapes.c3-lines > path {
  /* stroke: #424242 !important;*/
  /*oeekoak bakoitza bere kolorea du*/
}

.leyenda-chart .leyenda-chart-color {
  height: 7px;
  width: 25px;
  background: #d4d4d4;
  display: inline-block;
  margin: 0 10px 10px 0;
  vertical-align: bottom;
}

.leyenda-chart .leyenda-chart-label {
  margin: 0;
  vertical-align: bottom;
  border: none;
  background: none;
  outline: none;
}

.leyenda-chart .leyenda-chart-label-deseleccionado {
  margin: 0;
  vertical-align: bottom;
  border: none;
  background: none;
  outline: none;
  color: #eaeaea;
}

.k-i-loading::before,
.k-i-loading::after,
.k-loading-image::before,
.k-loading-image::after {
  border-color: #22c4c4;
  border-top-color: transparent;
  border-bottom-color: transparent;
  background-color: transparent;
}

.k-i-loading {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  font-size: 64px;
  background-color: rgba(255, 255, 255, 0.3);
  color: #22c4c4;
  z-index: 4;
}

/* GRAFIKOAK AMAIERA */
/*  HMI PROCESOS*/
.proces-titulo {
  text-align: center;
  margin: 0 0 15px 0;
}

.processcroll {
  height: 413px;
  overflow: auto;
  background: rgba(232, 232, 232, 0.29);
  padding: 4px 5px 0 5px;
  width: 100%;
}

.historial {
  font-size: 11px;
  /*  border-left: solid 3px;*/
  float: left;
  position: relative;
  background-color: #ffffff;
  margin: 0 0 4px 0;
  padding: 5px 4px;
  overflow: hidden;
  width: 100%;
}

.proces-fecha-cont {
  float: left;
  text-align: center;
  font-weight: bold;
  margin: 0 10px 0 5px;
}

.proces-fecha-cont label {
  margin: 0;
}

.proces-info-cont {
  float: left;
}

.proces-info-cont label {
  margin: 0;
}

.informacionOF {
  margin: 5px 5px;
}

.informacionOF label {
  margin: 0;
}

span.proces-titulo-uno {
  color: #22c4c4;
  font-weight: bold;
}

span.proces-titulo-dos {
  color: #22c4c4;
  margin: 0 2px 0 2px;
}

/*  HMI PROCESOS AMAIERA*/
/*  HMI*/
.donut-grupo-cm-cont {
  overflow: hidden;
  padding: 0 0 0 10px;
  /*    background-color: #ffffff;*/
  overflow-x: auto;
  height: 385px;
  width: 100%;
  white-space: nowrap;
  position: relative;
}

.cont-multiples img {
  position: absolute;
  width: 91%;
  z-index: 1;
}

.cont-graf.main {
  width: 455px;
  margin: 0;
}

.cont-graf {
  overflow: hidden;
  width: 17%;
  color: #333333;
  margin: 1.1% 0.1%;
  padding: 5px;
  position: relative;
  display: inline-block;
}

.cont-graf .cont-superior {
  overflow: hidden;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.barra-izq {
  width: 20%;
  position: absolute;
  left: 5%;
  top: 20px;
}

.cont-multiples {
  position: relative;
}

.barra-izq .toplabel {
  position: absolute;
  right: 0;
  font-size: 10px;
}

.barra-izq .bottom-label {
  position: absolute;
  bottom: 0px;
  font-size: 14px;
  margin: 0 0 0 -10px;
}

.barra-der {
  width: 20%;
  position: absolute;
  right: 5%;
  top: 20px;
}

.barra-der .toplabel {
  position: absolute;
  left: -12px;
  font-size: 10px;
}

.cont-multiples {
  position: relative;
}

.barra-der .toplabel {
  position: absolute;
  left: -12px;
  font-size: 10px;
}

.barra-der .bottom-label {
  position: absolute;
  bottom: 0px;
  right: 0;
  font-size: 14px;
  margin: 0;
}

.cont-donut {
  margin: 0 auto;
  width: 65%;
}

.donut {
  position: relative;
}

.donut img {
  width: 100%;
}

.donut-valores {
  position: absolute;
  top: 39%;
  left: 0;
  right: 0;
}

.consum-fondo {
  position: inherit !important;
  z-index: 0 !important;
  display: block;
}

.donut-valores label {
  display: block;
  width: 100%;
  text-align: center;
  margin: 0;
}

.cont-donut > label {
  font-size: 15px;
  text-align: center;
  display: block;
  margin: 0;
}

.valores-abajo {
  overflow: hidden;
  margin: 12px 0 0 0;
  border-top: solid 1px #649dd4;
  padding: 10px 0 0 0;
  background: #f0f5ff;
}

.valores-abajo .eje {
  float: left;
  width: 33%;
  overflow: hidden;
  text-align: center;
}

.valores-abajo > label {
  display: block;
  width: 33.3%;
  float: left;
  text-align: center;
  font-size: 20px;
}

.valores-abajo .eje label {
  float: none;
  text-align: center;
  display: block;
  width: 100%;
  color: #02bcd4;
  font-size: 15px;
  margin: 0;
  font-weight: 600;
}

.valores-abajo .eje p {
  margin: 0;
}

/*  HMI AMAIERA */
/*COMPARATIVA*/
.col-comparativa {
  float: left;
}

.donut-comparativa {
  width: 240px;
}

.oee-comparativa {
  width: 145px;
}

.gantt-comparativa.col-comparativa {
  width: calc(100% - 385px);
}

.card.informe-comparativa h3 {
  margin: 5px 0 0 10px;
  border: 0;
}

.card.informe-comparativa h3.titulo-oee-comparativa {
  text-align: center;
  margin-left: 0;
}

.card.informe-comparativa .card-header {
  padding: 7px 0 0 10px;
}

.card.informe-comparativa .card-header h3 {
  margin: 0;
  padding: 0;
  border-width: 0;
}

.card.informe-comparativa h3 label {
  margin: 0;
}

.oee-labels label {
  background-color: #f7f7f7;
  padding: 3px;
  margin-bottom: 2px;
  font-size: 10px;
  float: left;
}

.oee-labels label.oee-label-titulo {
  margin-left: 2px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: calc(65% - 2px);
}

.oee-labels label.oee-label-valor {
  text-align: right;
  margin-right: 2px;
  color: #22c4c4;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: calc(35% - 2px);
}

.informe-comparativa .machine {
  margin: 0;
  padding: 0 !important;
  line-height: 1;
}

.card.informe-comparativa .card-body {
  padding: 0 6px 0 0 !important;
}

.card.informe-comparativa .card-body .row {
  margin-right: -9px;
}

/*COMPARATIVA AMAIERA*/
.contenedor-eje {
  background-color: #cdcdcd;
}

/*MULTICANAL*/
.doble-tiempo-home {
  overflow: hidden;
  width: calc(50% - 4px);
  text-align: center;
  float: left;
  padding: 0 5px;
  font-size: 10px;
  background-color: rgba(255, 255, 255, 0.5);
  margin: 2px;
}

.doble-bloque-sup {
  /*    color: #ffffff;*/
}

.doble-bloque-cent {
  position: relative;
}

.doble-bloque-cent .porcent-cont {
  height: 35px;
  width: 35px;
  position: relative;
  margin: 0 auto;
}

.doble-bloque-cent .donut-porcent {
  position: absolute;
  top: 10px;
  font-size: 12px !important;
  display: block;
  width: 100%;
  text-align: center;
  white-space: nowrap;
}

.doble-bloque-inf .m-cabecera,
.doble-bloque-inf label {
  display: inline-block;
}

.doble-bloque-inf {
  /*    color: #ffffff;*/
}

.cuatro-tiempo-home {
  overflow: hidden;
  width: calc(50% - 4px);
  text-align: center;
  float: left;
  padding: 0 5px;
  font-size: 10px;
  background-color: rgba(255, 255, 255, 0.5);
  margin: 2px;
}

.cuatro-bloque-sup .m-cabecera {
  float: left;
  color: #64a995;
  font-size: 10px;
}

.cuatro-bloque-inf .m-cabecera {
  float: left;
  color: #64a995;
  font-size: 10px;
}

.cuatro-bloque-cent .textobarra-home {
  /* position: absolute;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  text-align: center;*/
}

cuatro-bloque-cent .cont-barra-porcentaje-home {
  height: 11px;
}

.barra-porcentaje-home {
  margin: -15px 0 0 0;
  height: 11px !important;
}

.cuatro-tiempo-home label,
.doble-tiempo-home label {
  margin: 0;
  font-size: 10px;
}

.cuatro-bloque-cent .textobarra-home label {
  color: #ffffff;
}

.porcent-cont img {
  width: 100%;
}

.titulo-canal label {
  font-weight: bold;
  color: #253341;
}

/*MULTICANAL AMAIERA*/
/*TURNOS*/
.turnos-grid.k-grid td {
  padding: 0 !important;
  white-space: break-spaces;
}

.turnos-grid .k-dateinput,
.turnos-grid .k-datepicker,
.turnos-grid .k-datetimepicker,
.turnos-grid .k-timepicker {
  width: 100% !important;
  display: block;
}

.turnos-grid .k-input {
  padding: 0 3px 0 0 !important;
  text-align: right;
}

/*TURNOS AMAIERA*/
icono-estado-enProceso {
  color: #99AFC6;
}

.icono-estado-acabada {
  color: #99AFC6;
}

.icono-estado-validada {
  color: #22c4c4;
}

.icono-estado-valorFueraLimites {
  color: #D33737;
}

/*  LIMITES*/
.planificador-limite-proyecto-maquinas .clickable img {
  max-width: 150px;
  max-height: 60px;
}

.planificador-limite-proyecto-maquinas .maquinaLimites {
  display: block;
  float: left;
  background-color: #f7f7f7;
  margin: 5px;
  height: 135px;
  width: 11.5%;
  text-align: center;
}

.planificador-limite-proyecto-maquinas .maquinaLimites label {
  position: relative;
  text-align: center !important;
  width: 100%;
  display: block;
  font-size: 12px;
  margin: 10px 0;
  width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  padding: 0 20px;
}

/*.k-grid tbody td {
  white-space: nowrap !important;
  line-height: 20px !important;
  padding: 8px 12px !important;
}

.k-grid .k-grid-content td {
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}*/
.clickable.maquinaLimites ul {
  padding: 0;
}

li.prioridadMaquina {
  display: inline-block;
  vertical-align: top;
  text-align: left;
  list-style: none;
  margin: 0;
}

li.prioridadMaquina a {
  display: block;
}

.maquinaLimites ul,
.maquinaLimites ul * {
  box-sizing: content-box;
  outline: 0;
  user-select: none;
}

.RadRating a {
  color: inherit;
  position: relative;
  width: 1.5em;
  height: 1.5em;
  cursor: default;
  text-decoration: none;
  display: block;
}

/*  LIMITES AMAIERA*/
/*  POTENCIOMETRO*/
label.titulopoten {
  display: block;
  border-bottom: solid 1px #DCE2EA;
  margin: 0px 3px;
  font-weight: bold;
  text-transform: lowercase;
  text-align: left;
  padding: 0 0 0 10px;
}

label.titulopoten:first-letter {
  text-transform: uppercase;
}

label.valor-poten {
  position: absolute;
  bottom: 0px;
  font-size: 35px;
  left: 0;
  right: 0;
  display: block;
  text-align: center;
  color: #5b5d5f;
}

.outer-cont-donut {
  position: relative;
  width: 100%;
  float: left;
  background-color: #F8F8F8;
  margin: 2px;
  height: 228px;
}

.poti-graf .dospoti .outer-cont-donut {
  width: calc(50% - 4px);
  float: left;
  text-align: center;
}

.poti-graf .dospoti .cont-donut {
  width: 100%;
  height: 200px;
}

.poti-graf .cont-donut {
  position: relative;
  width: 65%;
  position: relative;
  max-width: 235px;
}

.poti-labels {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.poti-labels span:before {
  display: block;
  content: "";
  width: 10px;
  height: 10px;
  background-color: #333333;
  border-radius: 100px;
  float: right;
  margin: 0 0 0 10px;
}

.dospoti .poti-label-uno {
  bottom: 0px;
  left: 30%;
}

.dospoti .poti-label-tres {
  top: 22%;
  right: 47%;
}

.poti-label-cuatro:before,
.poti-label-cinco:before {
  float: left !important;
  margin: 0 5px 0 0 !important;
}

.dospoti .poti-label-cinco {
  bottom: 0px;
  left: 57%;
}

.dospoti .potenciomtero-valor {
  top: 55%;
  left: 0;
  right: 0;
  z-index: 999;
  font-size: 17px;
  display: block;
  text-align: center;
}

.poti-labels span {
  position: absolute;
}

.poti-graf .par-icono-cm {
  margin: 0px 0 15px 0;
  overflow: hidden;
}

span.titulo-poten {
  text-align: center;
  display: block;
  margin: 10px 0 15px 0;
  font-size: 15px;
}

.poti-graf .donut {
  width: 80%;
  margin: 34px auto 1px auto;
  max-width: 125px;
  position: relative;
}

.donut img {
  width: 100%;
}

.rueda-poti-cont {
  background-color: #292a2e;
  position: absolute;
  bottom: 16px;
  top: 16px;
  left: 16px;
  right: 16px;
  border-radius: 100%;
}

.gridFilaRoja {
  color: #d33737 !important;
}

.cont-mantenimientos button.k-button.ng-star-inserted {
  border-width: 0;
  background-color: transparent;
}

.cont-mantenimientos button.k-button.ng-star-inserted span.k-icon.k-i-file-pdf {
  color: #d33737;
  font-size: 26px;
}

.pico {
  background: #292a2e;
  width: 20px;
  height: 20px;
  margin: -8px auto 0 auto;
  transform: rotate(45deg);
}

.rueda-poti-inner {
  background-color: #f2f2f2;
  position: absolute;
  bottom: 10px;
  top: 10px;
  left: 10px;
  right: 10px;
  border-radius: 100%;
}

.ruedin-poti {
  background-color: #292a2e;
  width: 10px;
  height: 10px;
  border-radius: 100%;
  margin: -15px auto 0 auto;
}

/*  POTENCIOMETRO AMAIERA*/
/*POTENCIA CONSUMIDA*/
.graf-barr-part-cont .graf-barr-part {
  float: left;
  height: 32px !important;
  background: #22c4c4;
}

.graf-barr-part-cont .graf-barr-part-multi {
  float: left;
  height: 32px !important;
}

.graf-barr {
  overflow: hidden;
}

.graf-barr-part-cont {
  overflow: hidden;
  margin: 10px 0 0 0;
}

.diez-porciento {
  width: 10%;
  float: left;
  height: 10px;
}

.graf-bar-centro {
  width: 80%;
  float: left;
}

.cinco-porciento {
  width: 5%;
  float: left;
  height: 10px;
}

.graf-barr-labels label {
  display: block;
  float: left;
  width: 10%;
  text-align: center;
  margin: 0;
}

.graf-barr-labels label:after {
  content: "";
  display: block;
  height: 5px;
  border-left: solid 1px #333333;
  margin: 0 50%;
  text-align: center;
}

.graf-barr-labels {
  overflow: hidden;
}

.graf-barr-horas-title {
  float: left;
  width: 5%;
}

.graf-barr-cont {
  float: left;
  width: 95%;
}

.graf-barr-labels-linea {
  border-top: solid 1px #333333;
  width: 80%;
  margin: -1px auto 0 auto;
}

.graf-barr-part-cont .textobarra {
  position: absolute;
  margin: 5px 0 0 10px;
  width: 250px;
  pointer-events: none;
}

.graf-barr-part-cont .textobarra label {
  color: #ffffff;
}

.graf-barr-part-cont .textobarra label:nth-child(2) {
  float: right;
}

/*POTENCIA CONSUMIDA AMAIERA*/
/* HISTORICO PRODUCCION HASIERA */
#lightbox {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  z-index: 99999;
  background-color: rgba(29, 29, 29, 0.7);
}

#lightbox p {
  text-align: right;
  color: #fff;
  margin-right: 20px;
  font-size: 12px;
}

#lightbox img {
  box-shadow: 0 0 25px #111;
  -webkit-box-shadow: 0 0 25px #111;
  -moz-box-shadow: 0 0 25px #111;
  max-width: 940px;
  margin-top: 5%;
  background-color: #ffffff;
}

/* HISTORICO PRODUCCION AMAIERA */
/*  INFORME defs CONSUMO*/
/*INFORME EJECUCIONES*/
.diasemana-turno {
  width: calc(100% - 191px);
  float: left;
  margin-right: 2px;
}

/*INFORME EJECUCIONES AMAIERA*/
/*  INFORME de CONSUMO*/
.selector-ejes button.k-button.k-state-active {
  background-color: #22c4c4;
  border-color: #22c4c4;
}

.selector-ejes button.k-button {
  background-color: #FFFFFF;
  border-color: #22c4c4;
  margin-right: 2px;
}

.leyendas.leyendas-informe-potencia {
  width: 90%;
  overflow: hidden;
  margin: 0 auto;
}

.leyendas {
  margin: 10px 0 0 0;
  padding: 15px;
}

.leyendas.leyendas-informe-potencia .graf-barr-leyenda {
  margin: 0 0.5%;
  overflow: hidden;
  float: left;
  width: 24%;
}

/*.leyendas-top .graf-barr-leyenda {
  margin: 0 0.5%;
  overflow: hidden;
  float: left;
  width: 24%;
}*/
.leyendas-top .graf-barr-leyenda {
  margin: 0 10px 0 0;
  display: inline;
}

.leyendas.leyendas-gantt {
  text-align: center;
}

.leyendas-gantt .graf-barr-leyenda {
  margin: 0 10px 0 0;
  display: inline;
}

.leyendas-gantt .graf-barr-leyenda span.colorleyenda {
  margin: 0px 5px -4px 0;
}

/*.leyendas-top {
  margin: 0;
  padding: 5px 0 0 0;
  background: #ffffff;
  overflow: hidden;
  min-width: 410px;
}*/
.leyendas-top {
  margin: 0;
  padding: 5px 0 0 0;
  background: #ffffffa8;
  overflow: hidden;
  position: absolute;
  right: 0;
  z-index: 1;
}

/*.leyendas-top .graf-barr-leyenda label {
  width: 73px;
  overflow: hidden;
  height: 20px;
}
*/
.leyendas-top .graf-barr-leyenda label {
  overflow: hidden;
  height: 20px;
  display: inline-block;
}

.colorleyenda {
  width: 18px;
  height: 18px;
  margin: 0 5px 0 0;
  display: inline-block;
}

leyendas-top .graf-barr-leyenda .colorleyenda {
  margin: 0 5px 0 0;
}

.leyendas.leyendas-top.leyendas-herramientas {
  position: relative;
  padding: 0 10px;
}

.leyendas.leyendas-top.leyendas-herramientas .graf-barr-leyenda {
  display: block;
}

/*  INFORME defs CONSUMO AMAIERA */
/* PLANIFICADOR LARGO COMPARATIVA */
.triangulo-titulo {
  width: 0px;
  height: 0px;
  border-left: 60px solid transparent;
  border-right: 60px solid transparent;
  border-top: 20px solid #98efde;
  display: block;
  content: "";
  margin: 0px auto 0px auto;
}

table.tablas-comparativas > tr:first-child td {
  padding-top: 10px;
}

table.tablas-comparativas > tr:last-child td {
  padding-bottom: 10px;
}

span.titulillo {
  display: block;
  background: #98efde;
  width: 114px;
  margin: -15px auto 0px auto;
}

.filtro-planificador-comparativa {
  position: absolute;
  right: 0;
}

.tablas-comparativas {
  /* box-shadow: -8px 12px 18px 0 rgb(25 42 70 / 13%); */
  transition: all 0.3s ease-in-out;
  border-radius: 0;
  border-width: 0;
  margin: 0 0 10px;
}

.tablas-comparativas td {
  padding-top: 1px;
  padding-bottom: 1px;
}

.tablas-comparativas td.principal {
  /*background-color: #ffcb7f;*/
  background-color: #22c4c4;
  text-align: left;
  font-weight: bold;
  width: 300px;
  padding-left: 10px;
}

.tablas-comparativas.titulo-tablas-comparativas {
  background-color: transparent;
  box-shadow: none;
  margin: 77px 0 0 0;
}

.tablas-comparativas.titulo-tablas-comparativas td.principal {
  /*background-color: #ffcb7f;*/
  background-color: transparent;
  text-align: center;
  font-weight: bold;
  width: 530px;
  text-align: center;
}

.tablas-comparativas.titulo-tablas-comparativas .datos {
  text-align: center;
  /*background-color: #98efde;*/
  /*padding: 10px;*/
}

.tablas-comparativas.titulo-tablas-comparativas .datos.colalt {
  background-color: #f1efef;
  /*  background-color: #77ead4;*/
}

.tablas-comparativas .linea {
  /*background-color: #ffc269;*/
  background-color: #77ead4;
  padding-left: 15px;
  width: 150px;
}

.tablas-comparativas th.titulo {
  width: 80px;
}

.tablas-comparativas .linealt {
  /* background-color: #ffd392;*/
  background-color: #98efde;
  width: 150px;
  padding-left: 15px;
}

.tablas-comparativas .datos {
  text-align: right;
  background-color: #ffffff;
  width: 215px;
  padding-right: 10px;
  /* border-right: solid 20px #f7f7f7; */
  border-right: solid 5px #f7f7f7;
}

.tablas-comparativas .verde {
  color: #44e3c4;
  font-weight: bold;
}

.TasaServicioTiempo td.principal,
.retrasoMedio td.principal,
.valoracionMedia td.principal {
  width: 530px;
}

.TasaServicioFacturacionClientes td.principal,
.disminucionParadas td.principal,
.horasExtra td.principal,
.tasadeservicio td.principal,
.margenEntrega td.principal,
.TasaServicioClientes td.principal {
  width: 380px;
}

.disminucionParadas td.principal,
.horasExtra td.principal {
  background-color: #228bc4;
}

.disminucionParadas td.linea,
.horasExtra td.linea {
  background-color: #559abf;
}

.disminucionParadas td.linealt,
.horasExtra td.linealt {
  background-color: #74aece;
}

.valoracionMedia td.principal,
.retrasoMedio td.principal,
.margenEntrega td.principal {
  background-color: #33d486;
}

.valoracionMedia td.linealt,
.mretrasoMedio td.linealt,
.margenEntrega td.linealt {
  background-color: #76e8b1;
}

.valoracionMedia td.linea,
.retrasoMedio td.linea,
.margenEntrega td.linea {
  background-color: #50dc98;
}

.fpc-2 .btn.btn-success {
  background-color: #228bc4;
  border-color: #228bc4;
}

.fpc-2 .btn.btn-outline-success {
  border-color: #228bc4;
}

.fpc-3 .btn.btn-success {
  background-color: #33d486;
  border-color: #33d486;
}

.fpc-3 .btn.btn-outline-success {
  border-color: #33d486;
}

td.datos.colalt {
  background-color: #f1efef;
}

/* POPUP */
.popup-cont {
  background-color: #252525a3;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.popup {
  /* SE HACE CON w-25/50/75 */
  margin: 190px auto 0 auto;
  background-color: #ffffff;
  padding: 15px;
}

ngb-modal-backdrop.modal-backdrop.fade.show {
  z-index: 1050 !important;
}

/* POPUP amaiera */
/*MODAL*/
.modal-content {
  border-radius: 0;
}

.modal-header h3 {
  width: 100%;
}

.modal-header {
  padding: 15px 15px 0 15px;
  border-width: 0;
}

.modal-footer .modal-botoiak-erdian {
  display: flex;
  justify-content: space-between;
}

.modal-footer.modal-botoiak-erdian > * {
  flex-grow: 1;
  flex-basis: 0;
}

.modal-footer.modal-botoiak-erdian .form-group {
  margin: 0;
}

/*MODAL AMAIERA*/
/* PLANIFICADOR LARGO COMPARATIVA AMAIERA */
/*Activos maestros*/
.activos-detalle-isometrico img {
  cursor: pointer;
}

.activos-detalle-isometrico img.actual {
  background: rgba(36, 171, 149, 0.21);
}

.tabstrip-activos-isometricos > .ng-star-inserted {
  overflow-x: hidden;
}

.arbol-activo {
  color: #D33737;
}

.upload-sinBotones .k-actions {
  display: none;
}

/*Activos maestros amaiera*/
/* ARATZ START */
.float-right {
  margin-left: auto;
}

.modal-xxl {
  width: 1500px;
  max-width: 1500px;
}

.texto-loading-panel {
  position: absolute;
  left: 0;
  right: 0;
  text-align: center;
  top: 50%;
}

/* ARATZ END */
.clearfix.oee-botones-periodo {
  margin: 12px 0;
}

.oee-botones-periodo .btn {
  float: left;
  width: calc(25% - 10px);
  margin-left: 10px;
}

.alerticonos-cont img {
  margin-bottom: 7px;
  margin-right: 3px;
}

.oee-botones-periodo .btn.oee-boton-periodo-1 {
  margin: 0;
}

/* EXTRUSORAS START */
.extrusoras-panel-rangos {
  margin: 5px 5px 5px 5px;
}

.extrusoras-panel-rangos-header {
  background-color: #91E1E1;
  min-height: 45px;
  padding-top: 11px;
}

.extrusoras-panel-rangos-header-titulo {
  font-size: 14px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: calc(100% - 50px);
}

.extrusoras-panel-rangos-body {
  background-color: white;
  min-height: 80px;
}

/* EXTRUSORAS END */
/* Fracciones */
.frac {
  display: inline-block;
  position: relative;
  vertical-align: middle;
  letter-spacing: 0.001em;
  text-align: center;
}

.frac > span {
  display: block;
  padding: 0.1em;
}

.frac span.bottom {
  border-top: thin solid black;
}

.frac span.symbol {
  display: none;
}

/* Fracciones */
.frac {
  display: inline-block;
  position: relative;
  vertical-align: middle;
  letter-spacing: 0.001em;
  text-align: center;
}

.frac > span {
  display: block;
  padding: 0.1em;
}

.frac span.bottom {
  border-top: thin solid black;
}

.frac span.symbol {
  display: none;
}

/* Fracciones amaiera*/
/*bonos*/
.cont-filtro-maquinas-areas.bonos .cont-select-maquinas-out.desplegar {
  width: calc(100% - 425px) !important;
}

td .graf-barr-part-cont {
  margin: 0px 0 0 0;
}

td .graf-barr-part-cont .graf-barr-part-multi {
  height: 25px !important;
}

.filtro-solofecha {
  float: right;
  width: 180px;
}

/*bonos amaiera*/
.horizontal-scroll {
  overflow: auto;
  white-space: nowrap;
}

/* ARATZ START */
.filtro-desplegable .k-clear-value {
  display: none;
}

.k-datepicker .k-picker-wrap,
.k-timepicker .k-picker-wrap {
  border-width: 1px;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.08);
}

.box {
  background: red;
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  z-index: 4;
}

.calendarFiltro-calendario.mostrarCalendario {
  z-index: 4;
}

.fondo-rojo {
  background: red;
}

.linea-draging {
  background-color: #22c4c4 !important;
}

/* ARATZ END */
/* aditivo */
.aditivoImg img {
  height: auto;
  width: 100%;
  max-height: 300px;
  object-fit: contain;
}

.aditivoImg {
  height: 300px;
}

/* aditivo amaiera */
/*GRID DIV*/
.linea-griddiv {
  border-bottom: solid 1px #E6E9EF;
}

.linea-griddiv-inner {
  display: flex;
  flex-wrap: wrap;
}

.linea-griddiv::after {
  display: block;
  clear: both;
  content: "";
}

.col-griddiv {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
  border-right: solid 1px #E6E9EF;
}

.col-griddiv .k-multiselect.selec-operario .k-multiselect-wrap,
.col-griddiv .k-dropdowntree.selec-operario .k-multiselect-wrap {
  border-width: 0;
}

.col-griddiv span.grid-circulo + span.grid-circulo {
  margin: 0 0 0 -10px;
}

/*GRID DIV*/
/*TURNOS OPERARIOS alex*/
.header-eguna-turnoOperario {
  text-align: center;
}

.zutabe-eguna-turnosOperarios {
  width: calc(100% - 230px);
  float: left;
}

.zutabe-makina-turnosOperarios {
  width: 230px;
  float: left;
}

kendo-multiselect.selec-operario .k-multiselect .k-multiselect-wrap:not(:hover):before,
kendo-multiselect.selec-operario .k-dropdowntree .k-multiselect-wrap:not(:hover):before {
  content: "";
  font-family: "zituikon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

kendo-multiselect.selec-operario .k-reset {
  display: none !important;
}

/*TURNOS OPERARIOS amaiera alex*/
/* DEPENDENCIAS PARTES */
.dependencias-padre,
.dependencias-hijo,
.dependencias-cantidad,
.dependencias-eliminar,
.dependencias-orden,
.dependencias-titulo-orden,
.dependencias-titulo-padre,
.dependencias-titulo-hijo,
.dependencias-titulo-cantidad {
  float: left;
  margin-right: 10px;
  margin-bottom: 10px;
}

.dependencias-padre kendo-dropdownlist.k-widget.k-dropdown,
.dependencias-hijo kendo-dropdownlist.k-widget.k-dropdown,
.dependencias-titulo-padre,
.dependencias-titulo-hijo {
  width: 300px;
}

.dependencias-cantidad kendo-numerictextbox.k-widget.k-numerictextbox,
.dependencias-titulo-cantidad {
  width: 100px !important;
}

/* DEPENDENCIAS PARTES END */
/*ESKOLA*/
kendo-list.dropdown-colores li {
  padding: 2px !important;
  width: 50%;
}

kendo-list.dropdown-colores li .dropdownItem {
  padding: 4px !important;
  width: 100%;
  text-align: center;
  color: #ffffff;
}

kendo-list.dropdown-colores ul {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

/*ESKOLA AMAIERA*/
.col-calendario {
  max-width: 820px;
  width: 100%;
}

.col-datos-calendario {
  width: 280px;
  margin-left: 15px;
}

.calendar .calendar-header {
  text-align: center;
}

.calendar .calendar-header span {
  font-size: 20px;
  color: #22c4c4;
  margin: 0 15px;
}

.calendar .calendar-header span.calendar-flecha {
  color: #fff !important;
  background-color: #00b6fe;
  border-radius: 15px;
  cursor: pointer;
  font-size: 12px;
  padding: 3px 0;
  margin: 0;
}

.calendar .calendar-flecha .fas.fa-angle-left {
  padding: 0 8px 0 6px;
}

.calendar .calendar-flecha .fas.fa-angle-right {
  padding: 0 6px 0 8px;
}

.calendar .calendar-body kendo-calendar {
  border: none;
}

.calendar .calendar-body span.k-calendar-nav.k-hstack {
  display: none;
}

.calendar .k-calendar .k-calendar-td.k-state-selected .k-link {
  color: black;
  background-color: transparent;
}

.calendar .k-calendar .k-calendar-td.k-state-selected:hover .k-link,
.calendar .k-calendar .k-calendar-td.k-state-selected.k-state-hover .k-link {
  color: black;
  background-color: #f0f0f0;
}

.calendar .k-other-month > span {
  display: none;
}

.calendar .k-other-month {
  pointer-events: none;
}

.calendar .k-calendar .k-calendar-td.k-state-selected:hover .k-link,
.calendar .k-calendar .k-calendar-td.k-state-selected.k-state-hover .k-link {
  color: black;
  background-color: #f0f0f0;
}

.calendar .k-calendar .k-calendar-td.k-other-month:hover .k-link {
  background-color: transparent;
}

.calendar .k-calendar .k-calendar-td.k-state-selected.k-state-focused .k-link,
.k-calendar .k-calendar-td.k-state-selected.k-state-focus .k-link {
  box-shadow: inset 0 0 0 2px rgba(236, 0, 0, 0);
}

.calendar .k-calendar-header.k-hstack {
  background-color: #22c4c4;
}

.calendar .k-calendar-header.k-hstack .k-button.k-flat.k-title.k-calendar-title {
  color: #fff;
  width: 100%;
  text-transform: capitalize;
}

.calendar .k-calendar .k-calendar-view .k-today {
  color: inherit;
}

.calendar .col-mes {
  width: 260px;
  float: left;
}

.calendar .k-calendar-header.k-hstack {
  background-color: #00b6fe;
  padding: 0;
  pointer-events: none;
}

.calendar .k-calendar .k-calendar-th,
.k-calendar .k-calendar-caption,
.k-calendar .k-meta-header,
.k-calendar .k-month-header {
  color: #b5adad;
}

.calendar .k-calendar-th {
  width: 31px;
  height: 18px;
  font-size: 10px;
  line-height: 0;
}

.calendar .k-calendar-monthview .k-calendar-td {
  width: 32px;
  height: 21px;
}

.calendar .k-calendar-monthview .k-link {
  width: 32px;
  height: 21px;
}

.calendar .k-calendar-view .k-link {
  padding: 0;
  border-radius: 0;
}

.calendar .k-calendar-view {
  min-height: 180px;
}

.calendar-leyenda .colorleyenda {
  display: inline-block;
  width: 21px;
  text-align: center;
  height: 21px;
  margin: 0 5px 0px 0;
  outline: solid 1px #828282;
}

.calendar-leyenda .conreserva {
  display: inline-block;
  width: 21px;
  text-align: center;
  height: 21px;
  margin: 0 5px 0px 0;
  outline: solid 1px #828282;
  color: red;
}

.valores-recetas-switch .ng-star-inserted {
  width: 280px;
  float: left;
  margin: 0 10px 10px 0px;
}

.calendar-leyenda .calendario-periodoSeleccionado,
.calendar .k-link .calendario-periodoSeleccionado {
  background-color: #22c4c4 !important;
  color: white !important;
}

.calendar-leyenda .calendario-periodoInactivo,
.calendar .k-link .calendario-periodoInactivo {
  background-color: #ababab;
  color: #000000;
}

.calendar-leyenda .calendario-reserva,
.calendar .k-link .calendario-reserva {
  /*background-color: white;*/
  color: tomato !important;
}

.calendar-leyenda .contenido-dia,
.calendar .contenido-dia {
  display: block;
  width: 100%;
}

.calendar-leyenda .calendario-deshabilitado,
.calendar .k-link .calendario-deshabilitado {
  color: #222222;
  background-color: #ababab;
}

.calendar-leyenda .calendario-periodoActivo,
.calendar .k-link .calendario-periodoActivo {
  background-color: #FFFFFF;
  color: #000000;
}

.calendar-leyenda {
  margin: 0 0 0 16px;
}

.calendar-leyenda .row {
  margin-bottom: 10px;
}

.calendar-switches .form-group {
  display: inline-block;
}

/* Calendar amaiera */
/*HMI personalizado*/
.cont-editor-hmi .card-header {
  margin: 10px 0 0 0;
  padding: 0;
  position: relative;
}

.cont-editor-hmi .card-header .btn-columnas-hmi {
  position: absolute;
  right: 0px;
  top: 0px;
  display: inline-block;
  vertical-align: 0.255em;
  content: "";
  color: #fff;
  float: right;
  cursor: pointer;
}

.btn-group.btn-columnas-hmi .dropdown-toggle::after {
  display: none;
}

.btn-group.btn-columnas-hmi .dropdown-toggle i {
  pointer-events: none;
  font-size: 16px;
}

.cont-editor-hmi .agregar-elemento i {
  font-size: 55px;
}

.cont-editor-hmi .agregar-elemento i.icon-mas-alt {
  font-size: 25px;
}

.cont-editor-hmi .agregar-elemento {
  padding: 10px;
}

.hmi-personalizado-cont:after {
  display: block;
  clear: both;
  content: "";
}

.hmi-personalizado-cont.tiene-procesos {
  width: 66%;
  height: 391px;
}

.hmi-personalizado-cont .card {
  height: 100%;
  margin: 0;
}

.hmi-personalizado-cont {
  display: flex;
  justify-content: flex-start;
  height: 325px;
  width: 74%;
}

.hmi-personalizado-col {
  width: calc(33.333% - 10px);
  margin: 0 10px;
}

.hmi-personalizado-int {
  height: 100%;
}

.hmi-personalizado-int-top {
  height: calc(50% - 5px);
  margin-bottom: 10px;
}

.hmi-personalizado-int-bottom {
  height: calc(50% - 5px);
  margin-top: 10px;
}

.hmi-personalizado-int-1 {
  height: calc(50% - 5px);
  width: calc(50% - 5px);
  margin: 0 5px 10px 0;
  float: left;
}

.hmi-personalizado-int-2 {
  height: calc(50% - 5px);
  width: calc(50% - 5px);
  margin: 0 0px 10px 5px;
  float: left;
}

.hmi-personalizado-int-3 {
  height: calc(50% - 5px);
  width: calc(50% - 5px);
  margin: 0 5px 0px 0;
  float: left;
}

.hmi-personalizado-int-4 {
  height: calc(50% - 5px);
  width: calc(50% - 5px);
  margin: 0 0px 0px 5px;
  float: left;
}

.hmi-personalizado-int-vert-der {
  height: 100%;
  width: calc(50% - 5px);
  margin: 0 0px 0px 5px;
  float: right;
}

.hmi-personalizado-int-vert-izq {
  height: 100%;
  width: calc(50% - 5px);
  margin: 0 5px 0px 0px;
  float: left;
}

.hmi-personalizado-int-vert-izq .card-body {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.hmi-personalizado-cont .card-header h3 {
  display: block;
  border-bottom: solid 1px #DCE2EA;
  font-weight: bold;
  text-transform: none;
  text-align: left;
  font-size: 13px;
  color: initial;
  margin: 0px 3px;
  padding: 0 0 0 10px;
}

.hmi-personalizado-cont .card-header {
  margin: 0;
  padding: 0;
}

.hmi-personalizado-cont .ng-star-inserted.c3 {
  height: 100%;
}

.hmi-personalizado-cont .card-body {
  padding: 5px;
}

/* Temperatura */
.cont-grafico-temperatura-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.cont-grafico-temperatura-label .grafico-temperatura-icono i {
  font-size: 35px;
  color: #22c4c4;
}

.grafico-temperatura-labels label {
  font-size: 20px;
}

.grafico-temperatura-labels .grafico-dato-unidad {
  font-size: 11px;
  color: #22c4c4;
}

.grafico-temperatura {
  height: 90%;
  display: flex;
  flex-direction: row;
  margin-top: 10px;
}

.grafico-temperatura .barra-cont {
  border: solid 1px #cdcdcd;
  height: 100%;
  border-radius: 50px;
  position: relative;
  display: flex;
  flex-direction: column-reverse;
  overflow: hidden;
  padding: 5px;
  width: 50px;
}

.grafico-temperatura .barra-cont .barra-inner {
  border-radius: 50px;
  background-color: #22c4c4;
}

.grafico-temperatura .numeros-graf-cont {
  /* margin-left: 10px; */
  /* display: flex; */
  /* flex-direction: column-reverse; */
  /* justify-content: space-between; */
  transform: translate(10px, 10px);
}

.grafico-temperatura label.numero-graf {
  font-size: 10px;
  position: absolute;
  display: block;
}

grafico-temperatura {
  height: 100%;
  width: 100px;
  display: block;
  float: left;
}

.cont-grafico-temperatura-label {
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.grafico-temperatura-labels {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.grafico-temperatura-labels i.icon-temperatura {
  color: #22c4c4;
  font-size: 45px;
  margin: 0 0 5px 0;
}

/* End temperatura */
/*Dato*/
/*
.grafico-dato-icono-valor {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}*/
.grafico-dato-icono-label {
  text-align: center;
}

.grafico-dato-icono i {
  font-size: 50px;
  margin: 0 5px 0 0;
  color: #22c4c4;
}

.cont-grafico-dato-label {
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  height: 100%;
}

.grafico-dato-labels label {
  font-size: 30px;
}

.grafico-dato-labels {
  display: flex;
  flex-direction: column;
  margin: 0 0 0 10px;
  align-items: center;
}

.grafico-dato-labels .grafico-dato-unidad {
  font-size: 18px;
  color: #22c4c4;
}

/*Dato amaiera*/
/*instalaciones home*/
.inner-panel .grafico-dato-labels .grafico-dato-unidad {
  font-size: 18px;
}

.inner-panel .grafico-dato-labels label {
  font-size: 30px;
}

.inner-panel .grafico-dato-icono i {
  font-size: 50px;
}

/*instalaciones home amaiera*/
/* Posicion */
.cont-grafico-posicion-info {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 0 5px 0;
}

.grafico-posicion-labels label {
  font-size: 20px;
  margin: 0 0 0 5px;
}

.grafico-posicion-labels .grafico-dato-unidad {
  font-size: 11px;
  color: #22c4c4;
}

.cont-grafico-posicion-label .grafico-posicion-icono i {
  font-size: 35px;
  color: #22c4c4;
}

.grafico-posicion-labels {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
}

.hmi-personalizado-int-vert-izq .card-body .grafico-posicion-labels {
  margin: 0;
}

.grafico-posicion {
  height: 40px;
  position: relative;
  padding: 20px 0 0 0;
  width: calc(100% - 14px);
  margin: 0 auto;
}

.grafico-posicion .barra-cont {
  width: 100%;
  height: 20px;
  padding: 0 25px;
  background-color: #F1F1F1;
  border: solid 1px #DDDDDD;
  border-radius: 50px;
  overflow: hidden;
  position: relative;
}

.grafico-posicion .barra-inner {
  background-color: #22c4c4;
  height: 100%;
  position: absolute;
}

.grafico-posicion .marcador-triangulo {
  height: 20px;
  width: 20px;
  position: absolute;
  top: 0;
  margin-left: -10px;
}

.grafico-posicion .numeros-graf-cont {
  display: flex;
  justify-content: space-between;
}

.grafico-posicion label.numero-graf {
  display: block;
  text-align: center;
  font-size: 10px;
  transform: translateX(50%);
}

.grafico-posicion .barra-inicio,
.grafico-posicion .barra-fin {
  height: 25px;
  width: 1px;
  top: 17px;
  background: #22c4c4;
  position: absolute;
}

.grafico-posicion .barra-fin {
  transform: translateX(-1px);
}

/* End Posicion */
/*selector icono*/
kendo-list.cont-selector-icono ul {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: flex-start;
  justify-content: center;
  align-items: center;
  /*height: 350px;*/
  overflow-y: auto;
}

kendo-list.cont-selector-icono ul li.k-item {
  width: 11%;
  min-width: 75px;
  display: block;
  text-align: center;
  font-size: 25px;
  color: #ababab;
  outline: 1px solid rgba(0, 0, 0, 0.08);
  margin: 4px;
  cursor: pointer;
  border-radius: 4px;
}

kendo-list.cont-selector-icono ul li.k-item span:nth-child(2) {
  font-size: 9px;
  display: block;
  width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

/*selector icono amaiera*/
/*Etiquetas*/
.etiquetas-cont kendo-dropdownlist.form-control {
  width: 128px;
}

.etiquetas-cont .cont-eliminar {
  width: 30px;
}

/*Etiquetas amaiera*/
/* Nivel */
.cont-grafico-nivel-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.grafico-nivel-labels label {
  font-size: 20px;
}

.grafico-nivel-labels .grafico-dato-unidad {
  font-size: 11px;
  color: #22c4c4;
}

label.grafico-nivel-label {
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 40px;
}

.cont-grafico-nivel-label {
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.hmi-personalizado-int-vert-der .cont-grafico-nivel-label,
.hmi-personalizado-int-vert-izq .cont-grafico-nivel-label {
  max-height: 70%;
}

.grafico-forma-cont {
  aspect-ratio: 393/653;
  position: relative;
  width: auto;
  height: 100%;
}

.forma {
  -webkit-clip-path: url(#svgPath);
  clip-path: url(#svgPath);
  padding: 0;
  background-color: #e1e1e1;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}

.barra-interior-forma {
  width: 100%;
  background-color: #22c4c4;
  bottom: 0;
  position: absolute;
}

.grafico-forma-cont svg {
  height: 100%;
}

/* End nivel */
.grafico-presion svg {
  transform: rotate(145deg);
}

/*HMI personalizado amaiera*/
/*Informe personalizado disennador */
.informePersonalizadoModuloAltura {
  height: calc(100% - 30px);
}

/*End informe personalizado disennador*/
.reldiv1 svg {
  overflow: visible !important;
}

.grafico-multiardatza {
  padding-right: 128px;
}

/*MULTIROW GANTT*/
.gantt-horariosSecciones-dias svg.gantt-chart > g.task-container > g > text {
  text-anchor: middle;
  font-size: 11px;
}

.gantt-horariosSecciones-dias svg.gantt-chart > g.overlappingLines-container > g > text {
  text-anchor: middle;
  font-size: 11px;
}

.gantt-horariosSecciones-dias svg.gantt-chart > g.fecha_dia > text.data {
  text-anchor: middle;
  font-size: small;
}

.gantt-horariosSecciones-dias svg.gantt-chart > g.fecha_dia > text.orduak {
  text-anchor: middle;
  font-size: x-small;
}

.gantt-horariosSecciones-maquinas {
  float: left;
  max-width: 150px;
}

.grid-hornos-temple td {
  font-size: 13px;
  padding-bottom: 2px !important;
  padding-top: 2px !important;
}

inye .hornos-temple-hmi label.estado-tiempo-titulo {
  color: #333333;
}

.hornos-temple-hmi .datos-de-tiempo {
  padding: 0 20px;
}

/*HOME HANDIAK*/
.panelosoa.tv-grande-uno .porcent-cont {
  width: 200px;
  height: 200px;
}

.panelosoa.tv-grande-uno .porcent-cont-outer .donut-porcent {
  position: absolute;
  top: 69px;
  font-size: 46px !important;
  display: block;
  width: 100%;
  text-align: center;
}

.panelosoa.tv-grande-uno .pestañaprocesos {
  border-width: 120px;
  right: -120px;
  top: -120px;
}

.panelosoa.tv-grande-uno,
.panelosoa.tv-grande-uno .inner-panel {
  height: calc(100vh - 125px);
}

.panelosoa.tv-grande-uno .fotomaquina {
  height: 250px;
  width: 400px;
}

.panelosoa.tv-grande-uno .nombre-maquina-tv label {
  font-size: 55px;
  font-weight: 600;
  white-space: nowrap;
  width: 1340px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.panelosoa.tv-grande-uno .tiempo-maquina-tv label {
  font-size: 55px;
}

.tv-grande-uno .pieza-desviacion-valor label {
  font-size: 30px;
}

.tv-grande-cuatro .pieza-desviacion-valor label {
  font-size: 20px;
}

.panelosoa.tv-grande-uno .estado-maquina-tv label {
  font-size: 55px;
}

.datos-of-tv ul {
  padding: 0;
}

.datos-of-tv ul li {
  list-style-type: none;
  padding: 0 0 0 20px;
}

.datos-of-tv ul li:nth-of-type(odd) {
  background: #F8F8F8;
}

.panelosoa.tv-grande-uno p.oee-donut-label {
  font-size: 30px;
}

.panelosoa.tv-grande-uno .circulo-oee-home-tv {
  height: 250px !important;
}

.panelosoa.tv-grande-uno .datos-of-tv ul li label {
  font-size: 50px !important;
}

.panelosoa.tv-grande-uno .oee-cont-tv {
  margin: 70px 0 0 0;
}

.panelosoa.tv-grande-uno text.c3-chart-arcs-title {
  font-size: 50px !important;
}

.panelosoa.tv-grande-cuatro .fotomaquina {
  height: 163px;
  width: 210px;
}

.panelosoa.tv-grande-cuatro .nombre-maquina-tv label {
  font-size: 25px;
  font-weight: 500;
  white-space: nowrap;
  width: 650px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.panelosoa.tv-grande-cuatro .tiempo-maquina-tv label {
  font-size: 25px;
}

.panelosoa.tv-grande-cuatro .estado-maquina-tv label {
  font-size: 25px;
}

.panelosoa.tv-grande-cuatro .oee-cont-tv {
  margin: 40px 0 0 0;
}

.panelosoa.tv-grande-cuatro .fotomaquina {
  margin-right: 15px;
}

.panelosoa.tv-grande-cuatro p.oee-donut-label {
  font-size: 18px;
}

.panelosoa.tv-grande-cuatro .pestañaprocesos {
  border-width: 80px;
  right: -80px;
  top: -80px;
}

/*disenoTipo2*/
.panelosoa.tv-grande-uno .inner-panel.disenoTipo2 .tiemposhorno label.tiemposhorno-titulo {
  font-size: 55px;
}

.panelosoa.tv-grande-uno .inner-panel.disenoTipo2 .tiemposhorno label.tiemposhorno-tiempo {
  font-size: 80px;
  font-weight: 600;
}

.panelosoa.tv-grande-uno .inner-panel.disenoTipo2 .tiemposhorno label.tiemposhorno-sin-mant {
  font-size: 55px;
}

.panelosoa.tv-grande-uno .inner-panel.disenoTipo2 .euroshorahorno label.euroshorahorno-titulo {
  color: #009680;
  font-size: 55px;
}

.panelosoa.tv-grande-uno .inner-panel.disenoTipo2 .euroshorahorno label.euroshorahorno-valor {
  font-size: 80px;
}

.panelosoa.tv-grande-uno .inner-panel.disenoTipo2 .cont-porcentaje-flecha-horno i {
  font-size: 110px;
}

.panelosoa.tv-grande-uno .inner-panel.disenoTipo2 .cont-porcentaje-flecha-horno .porcentaje-horno label {
  margin-top: 15px;
  font-size: 50px;
}

/****/
.panelosoa.tv-grande-cuatro .inner-panel.disenoTipo2 .tiemposhorno label.tiemposhorno-titulo {
  font-size: 20px;
}

.panelosoa.tv-grande-cuatro .inner-panel.disenoTipo2 .tiemposhorno label.tiemposhorno-tiempo {
  font-size: 40px;
  font-weight: 600;
}

.panelosoa.tv-grande-cuatro .inner-panel.disenoTipo2 .tiemposhorno label.tiemposhorno-sin-mant {
  font-size: 18px;
}

.panelosoa.tv-grande-cuatro .inner-panel.disenoTipo2 .euroshorahorno label.euroshorahorno-titulo {
  color: #009680;
  font-size: 25px;
}

.panelosoa.tv-grande-cuatro .inner-panel.disenoTipo2 .euroshorahorno label.euroshorahorno-valor {
  font-size: 30px;
}

.panelosoa.tv-grande-cuatro .inner-panel.disenoTipo2 .cont-porcentaje-flecha-horno i {
  font-size: 45px;
}

.panelosoa.tv-grande-uno .cont-porcentaje-flecha-horno i.icon-flechaabajo-alt-2,
.panelosoa.tv-grande-cuatro .cont-porcentaje-flecha-horno i.icon-flechaabajo-alt-2 {
  color: #d33737;
}

.panelosoa.tv-grande-uno .cont-porcentaje-flecha-horno i.icon-flechaarriba-alt-2,
.panelosoa.tv-grande-cuatro .cont-porcentaje-flecha-horno i.icon-flechaarriba-alt-2 {
  color: #4287cc;
}

.panelosoa.tv-grande-cuatro .inner-panel.disenoTipo2 .cont-porcentaje-flecha-horno .porcentaje-horno label {
  font-size: 25px;
}

.inner-panel.disenoTipo2 .row.cont-datos-alineados {
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
  margin-top: 10px;
}

/*disenoTipo3*/
.cabecera-maquina-tv {
  margin-top: 15px;
}

.cabecera-maquina-tv .modelo-operario-cont {
  float: left;
  width: 53%;
}

.cabecera-maquina-tv .maquina-tiempo-estado-cont {
  float: left;
  text-align: center;
  width: 47%;
}

.panelosoa.tv-grande-uno .cabecera-maquina-tv .modelo-maquina label {
  font-size: 55px;
  line-height: 61px;
  height: 125px;
}

.panelosoa.tv-grande-uno .cabecera-maquina-tv .panelosoa .usuario {
  display: flex;
  flex-direction: row;
  align-content: space-around;
  align-items: center;
}

.panelosoa.tv-grande-uno .cabecera-maquina-tv .nombre-operario-maquina label {
  font-size: 40px;
}

.panelosoa.tv-grande-uno .cabecera-maquina-tv .nombre-operario-maquina i {
  font-size: 40px;
  margin: 0 20px 0 0;
}

.panelosoa.tv-grande-uno .cabecera-maquina-tv .maquina-tiempo-estado-cont label {
  font-size: 60px;
  margin-top: 40px;
}

/**/
.panelosoa.tv-grande-uno .cabecera-maquina-tv .maquina-tiempo-estado-cont label {
  font-size: 30px;
  margin-top: 10px;
}

.panelosoa.tv-grande-uno .inner-panel.disenoTipo3 .euroshorahorno label.euroshorahorno-titulo {
  color: #009680;
  font-size: 25px;
}

.panelosoa.tv-grande-uno .inner-panel.disenoTipo3 .euroshorahorno label.euroshorahorno-valor {
  font-size: 50px;
}

.panelosoa.tv-grande-uno .inner-panel.disenoTipo3 .cont-porcentaje-flecha-horno {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.panelosoa.tv-grande-uno .inner-panel.disenoTipo3 .cont-porcentaje-flecha-horno i {
  font-size: 45px;
}

/*----*/
.panelosoa.tv-grande-uno .inner-panel.disenoTipo3 .cont-porcentaje-flecha-horno {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.panelosoa.tv-grande-uno .inner-panel.disenoTipo3 .lote-actual-cont label.lote-actual-titulo {
  font-size: 25px;
}

.panelosoa.tv-grande-uno .inner-panel.disenoTipo3 .lote-actual-cont kendo-label.lote-actual-valor label,
.panelosoa.tv-grande-uno .inner-panel.disenoTipo3 .lote-actual-cont label.lote-actual-valor {
  font-size: 35px;
}

.panelosoa.tv-grande-uno .inner-panel.disenoTipo3 .lote-actual-cont label.lote-actual-totaltime {
  color: #009680;
  font-size: 25px;
}

/**/
.panelosoa.tv-grande-cuatro .cabecera-maquina-tv .maquina-tiempo-estado-cont label {
  font-size: 30px;
  margin-top: 10px;
}

.panelosoa.tv-grande-cuatro .inner-panel.disenoTipo3 .euroshorahorno label.euroshorahorno-titulo {
  color: #009680;
  font-size: 20px;
}

.panelosoa.tv-grande-cuatro .inner-panel.disenoTipo3 .euroshorahorno label.euroshorahorno-valor {
  font-size: 30px;
}

.panelosoa.tv-grande-cuatro .inner-panel.disenoTipo3 .cont-porcentaje-flecha-horno {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.panelosoa.tv-grande-cuatro .inner-panel.disenoTipo3 .cont-porcentaje-flecha-horno i {
  font-size: 45px;
}

.panelosoa.tv-grande-cuatro .inner-panel.disenoTipo3 .lote-actual-cont label.lote-actual-titulo {
  font-size: 16px;
}

.panelosoa.tv-grande-cuatro .inner-panel.disenoTipo3 .lote-actual-cont label.lote-actual-valor {
  font-size: 25px;
}

.panelosoa.tv-grande-cuatro .inner-panel.disenoTipo3 .lote-actual-cont label.lote-actual-totaltime {
  color: #009680;
  font-size: 20px;
}

/*disenoTipo4*/
.panelosoa.tv-grande-uno .inner-panel.disenoTipo4 .cont-porcentaje-flecha-horno {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.panelosoa.tv-grande-uno .inner-panel.disenoTipo4 .lote-actual-cont label.lote-actual-titulo {
  font-size: 25px;
}

.panelosoa.tv-grande-uno .inner-panel.disenoTipo4 .lote-actual-cont kendo-label.lote-actual-valor label,
.panelosoa.tv-grande-uno .inner-panel.disenoTipo4 .lote-actual-cont label.lote-actual-valor {
  font-size: 35px;
}

.panelosoa.tv-grande-uno .inner-panel.disenoTipo4 .lote-actual-cont label.lote-actual-totaltime {
  color: #009680;
  font-size: 25px;
}

.panelosoa.tv-grande-cuatro .inner-panel.disenoTipo4 .lote-actual-cont label.lote-actual-titulo {
  font-size: 16px;
}

.panelosoa.tv-grande-cuatro .inner-panel.disenoTipo4 .lote-actual-cont kendo-label.lote-actual-valor label,
.panelosoa.tv-grande-cuatro .inner-panel.disenoTipo4 .lote-actual-cont label.lote-actual-valor {
  font-size: 25px;
}

.panelosoa.tv-grande-cuatro .inner-panel.disenoTipo4 .lote-actual-cont label.lote-actual-totaltime {
  color: #009680;
  font-size: 20px;
}

/*HOME HANDIAK AMAIERA*/
/*CONTENEDORES*/
.button-contenedores-filtro span.icon-check {
  background-color: #d8d8d8;
  border-radius: 100%;
  font-size: 21px;
  color: #A5A5A5;
  padding: 5px;
  margin: 0 10px 0 0;
  cursor: pointer;
}

.button-contenedores-filtro {
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: stretch;
  margin: 5px 0 0px 0;
}

.button-contenedores-filtro .btn {
  border: solid 1px #D9D9D9;
  background-color: transparent;
  margin: 0px 3px 0 0;
  color: #D9D9D9;
  font-size: 13px;
  padding: 0px 6px;
}

.button-contenedores-filtro .btn-success,
.button-contenedores-filtro .btn-success:focus {
  border: solid 0px #D9D9D9;
  font-size: 13px;
  margin: 0px 3px 0 0;
  outline: 0;
}

.grid-agrupado kendo-grid-group-panel.k-grouping-header.k-grouping-header-flex {
  display: none;
}

.card.agregar-maquinas-cont td.k-touch-action-auto.k-state-focused {
  box-shadow: none;
}

.agregar-maquinas-cont tr.k-grouping-row p.k-reset {
  float: right;
  font-weight: 400;
  color: #D9D9D9;
  font-size: 10px;
  margin-right: 10px;
}

.agregar-maquinas-cont tr.k-grouping-row p.k-reset a.k-icon.k-i-collapse {
  display: none;
}

.button-contenedores-filtro button {
  height: 20px;
}

.card.agregar-maquinas-cont td {
  background: #ffffff;
}

.card.agregar-maquinas-cont tr.k-grouping-row td {
  padding: 5px 0;
}

.card.agregar-maquinas-cont .k-grid tbody tr td.k-touch-action-auto,
.card.agregar-maquinas-cont .k-grid tbody tr td.k-group-cell {
  border-bottom: solid 0px #ffffff;
}

.agregar-maquinas-buscadorfiltro {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.agregar-maquinas-buscadorfiltro .buscador-cont {
  flex: 1 0 0;
  margin-right: 10px;
}

.cont-grid-agrupado {
  margin: 5px 10px 10px 10px;
}

.agregar-maquinas-buscadorfiltro .btn-filtro-cont {
  cursor: pointer;
  color: #d1d1d1;
}

.agregar-elemento-maquina {
  border: solid 1px #dfdfdf;
  border-radius: 3px;
  margin: 10px 0 0 0;
  color: #dfdfdf;
  text-align: center;
  padding: 4px 15px 0px 3px;
  cursor: pointer;
  height: calc(100% - 10px);
  position: relative;
}

.agregar-elemento-maquina:after {
  right: 5px;
  top: 4px;
  position: absolute;
  display: inline-block;
  width: 10px;
  height: 10px;
  margin-top: 5px;
  vertical-align: 0.255em;
  content: "";
  color: #fff;
  float: right;
  border-bottom: solid 3px #2a3f54;
  border-left: solid 3px #2a3f54;
  transform: rotate(-45deg);
  transition: 0.5s ease all;
  cursor: pointer;
}

.agregar-elemento-maquina .tags-filtro {
  margin-bottom: 5px;
}

.agregar-maquinas-cont .clearfix.cont-tags-filtro {
  margin: 10px 0;
}

.card.agregar-maquinas-cont thead {
  display: none;
}

.grid-row-seleccionado {
  background: #22c4c4;
  height: 33px;
  width: 4px;
}

/*CONTENEDORES AMAIERA*/
/* GRUPOS PLANIFICADOR DRAG AND DROP HASIERA */
/*.example-container {
  width: 400px;
  max-width: 100%;
  margin: 0 25px 25px 0;
  display: inline-block;
  vertical-align: top;
}*/
/*.example-list {
  border: solid 1px #ccc;
  min-height: 60px;
  background: white;
  border-radius: 4px;
  overflow: hidden;
  display: block;
}*/
/*
.example-box {
  padding: 20px 10px;
  border-bottom: solid 1px #ccc;
  color: rgba(0, 0, 0, 0.87);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  cursor: move;
  background: white;
  font-size: 14px;
}*/
/*.example-box:last-child {
  border: none;
}
*/
/*.example-list.cdk-drop-list-dragging .example-box:not(.cdk-drag-placeholder) {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}
*/
/* .item {
  cursor: move;
 border: 2px solid lightgrey;
  background-color: whitesmoke;
  border-radius: 10px;
  padding: 1rem;
  margin: 1rem;
}*/
.grupos-col {
  width: 430px;
  /*  float: left;*/
}

.cdk-drag-preview {
  box-sizing: border-box;
  border-radius: 4px;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.cdk-drag-placeholder {
  opacity: 0;
}

.cdk-drag-animating {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.grupos-scroll {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  flex-wrap: nowrap;
  align-content: flex-start;
  justify-content: flex-start;
  overflow: hidden;
  overflow-x: auto;
  padding-bottom: 35px;
}

.grupo-maquina {
  cursor: move;
  padding: 5px 5px 5px 25px;
  box-shadow: -8px 12px 18px 0 rgba(25, 42, 70, 0.13);
  border-width: 0;
  margin: 5px;
  background-color: #ffffff;
  height: 100%;
  position: relative;
}

.item.grupo-maquina:after {
  display: block;
  content: "";
  width: 3px;
  background: #D2F0E9;
  position: absolute;
  top: 31px;
  bottom: 0;
  left: 11px;
}

.cabecera-grupos-maquina {
  position: relative;
  margin-bottom: 10px;
}

.cabecera-grupos-maquina .nombre-maquina {
  text-align: center;
}

.cabecera-grupos-maquina .cont-btn-sticky {
  position: absolute;
  top: 0;
  right: 0;
}

/*.item > .sub-item {
  margin: 1rem;
  border: 2px solid lightgrey;
  background-color: whitesmoke;
  border-radius: 10px;
  padding: 1rem;
  margin: 1rem;
}*/
.cont-grupo {
  background-color: #F7F7F5;
  margin-bottom: 15px;
  border-radius: 5px;
  position: relative;
  width: 390px;
}

.cont-grupo.corto {
  background-color: #C3E8EE;
}

.cont-grupo.largo {
  background-color: #3289A8;
}

.cont-grupo:before {
  content: "";
  display: block;
  width: 13px;
  height: 13px;
  position: absolute;
  left: -19px;
  border-radius: 15px;
  outline: solid 3px #D2F0E9;
  background: white;
  z-index: 1;
  top: 65px;
}

.bloke-anadir {
  padding: 0 0 5px 0;
}

.cont-grupo-header {
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
  justify-content: space-evenly;
  padding: 3px 0 0px 0;
}

.bloke-anadir .cont-grupo-header {
  justify-content: space-between;
  margin-left: 7px;
}

label.col-nombregrupo {
  text-transform: uppercase;
}

.col-cant-grupo {
  margin: 0 auto;
}

button.btn.btn-desplegar {
  margin-left: auto;
}

.col-cant-grupo {
  margin: 0 auto;
  gap: 10px;
  display: flex;
}

button.btn.btn-desplegar {
  background: #ffffffb5;
  border-radius: 5px;
  margin-right: 5px;
}

.col-cant-grupo i {
  margin-right: 10px;
}

.tarea-cont {
  background-color: #ffffff;
  border-radius: 5px;
  margin: 5px;
  position: relative;
}

.bloke-anadir .tarea-cont {
  padding: 15px;
  text-align: center;
  font-size: 25px;
}

.tarea-cont .cont-inner {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: nowrap;
  gap: 5px;
  padding: 5px;
}

.col-indicador {
  width: 8px;
  height: 76px;
  background-color: gray;
  border-radius: 5px;
}

.col-indicador.margen {
  background-color: #4dc4a8;
}

.col-indicador.enplazo {
  background-color: #ffd05f;
}

.col-indicador.fueradeplazo {
  background-color: #fe6b74;
}

.col-info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  align-content: flex-start;
}

.col-cantidad {
  border: solid 1px #4DC4A8;
  min-width: 30px;
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: center;
  align-items: center;
  height: 75px;
  border-radius: 5px;
  font-weight: 600;
}

.plegado .col-indicador {
  height: 33px;
}

.plegado .col-cantidad {
  height: 33px;
}

label.info-of {
  font-weight: 600;
}

label.info-operacion {
  color: #999999;
}

.col-info label {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  max-width: 275px;
}

.col-info2 {
  margin-left: auto;
  display: flex;
  flex-direction: column;
  align-content: flex-end;
  align-items: flex-end;
  justify-content: space-between;
}

.col-info2 label {
  color: #999999;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.col-info2 i {
  margin: 0 0 0 7px;
  text-align: center;
  color: #22c4c4;
  width: 13px;
}

/*** angular cdk-drop  ***/
.cdk-drop-list-dragging {
  border-bottom: 5px solid lightslategray;
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.cdk-drag-preview {
  box-sizing: border-box;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
  border-radius: 10px;
}
.cdk-drag-preview .item {
  margin: 0;
}

.cdk-drag-placeholder {
  opacity: 0;
}

.cdk-drag-animating {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.sub-item:last-child {
  border: none;
}

.sub-item.cdk-drop-list-dragging .item:not(.cdk-drag-placeholder),
.item.cdk-drop-list-dragging .sub-item:not(.cdk-drag-placeholder) {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.datos-grupo {
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: space-between;
  align-items: center;
  padding: 0 7px;
  color: #999999;
  font-size: 11px;
  height: 29px;
}

.datos-grupo > div,
.datos-grupo > label {
  flex: 0 25%;
  text-align: center;
}

.datos-grupo i {
  margin: 0px 5px 0 0;
  color: #22c4c4;
}

.grupos-col.sticky {
  position: sticky;
  left: 0;
  z-index: 2;
}

.grupo-tiempoest-editar kendo-textbox.k-textbox {
  /*height: 19px;*/
  border-width: 0;
  width: 75px;
}

.grupo-tiempoest-editar kendo-textbox.k-textbox input {
  /*padding: 0 !important;*/
  text-align: center;
}

.grupo-tiempoest-editar kendo-textbox.k-textbox.k-state-focused {
  box-shadow: none;
}

button.bloquear-grupo {
  border-width: 0;
  background: #f7f7f5;
  border-radius: 100% 0 0 100%;
  position: absolute;
  left: -23px;
  z-index: 2;
  top: 60px;
  display: none;
}

button.bloquear-grupo {
  border-width: 0;
  background: #f7f7f5;
  border-radius: 100% 0 0 100%;
  position: absolute;
  left: -23px;
  z-index: 2;
  top: 60px;
  display: none;
}

.largo button.bloquear-grupo {
  background-color: #3289a8;
}

.corto button.bloquear-grupo {
  background-color: #C3E8EE;
}

.cont-grupo:hover button.bloquear-grupo {
  display: block;
}

button.bloquear-tarea {
  border-width: 0;
  background: #f7f7f5;
  border-radius: 100%;
  position: absolute;
  right: 50%;
  z-index: 2;
  top: -11px;
  border: solid 2px #ffffff;
  display: none;
}

.tarea-cont:hover button.bloquear-tarea {
  display: block;
}

.eliminar-elemento-grupo {
  top: 60px;
  z-index: 8;
  padding: 25px;
  text-align: center;
  position: fixed;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #ffffff;
  background-clip: border-box;
  border-radius: 0.25rem;
  width: 201px;
  font-size: 30px;
  left: 50%;
  box-shadow: -8px 12px 18px 0 rgba(25, 42, 70, 0.13);
  color: #f16a72;
}

.cdk-drag.sub-item.cont-grupo.cdk-drag-disabled {
  cursor: pointer;
}

.tarea-cont.bloqueado {
  background-image: linear-gradient(45deg, #f0f0f0 5%, #ffffff 5%, #ffffff 50%, #f0f0f0 50%, #f0f0f0 55%, #ffffff 55%, #ffffff 100%);
  background-size: 14.14px 14.14px;
}

.cont-opciones-plan {
  background-color: #ffffff;
  height: 176px;
  overflow: hidden;
  overflow-y: auto;
  margin: 7px;
  border-radius: 5px;
}

.cont-opciones-plan > div {
  float: left;
  width: 25%;
}

.cont-opciones-plan > div > label {
  background: #F7F7F5;
  width: calc(100% - 4px);
  border-radius: 5px;
  padding: 1px 2px;
  text-align: center;
  cursor: pointer;
}

.opcion-plan.largo label {
  background-color: #3289A8;
}

.opcion-plan.corto label {
  background-color: #C3E8EE;
}

.cont-opciones-plan > div > label > span {
  font-size: 10px;
}

.sindatos-x-cont {
  display: flex;
  flex-direction: column;
  position: absolute;
  right: 50%;
  text-align: center;
  -ms-transform: translate(50%, 0%);
  transform: translate(50%, 0%);
}
.sindatos-x-cont img {
  width: 142px;
}
.sindatos-x-cont label {
  text-align: center;
  font-size: 22px;
  color: #7C8593;
  background: white;
  border-radius: 12px;
  margin: -22px 0 0 0;
}

.sindatos-x-cont.sindatos-x-cont-horas {
  right: 20px;
  transform: none;
}

.sindatos {
  text-align: center;
  font-size: 22px;
  color: #7C8593;
}

/*scroll opciones plan*/
.cont-opciones-plan::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #F5F5F5;
}

.cont-opciones-plan::-webkit-scrollbar {
  height: 8px;
  background-color: #F5F5F5;
}

.cont-opciones-plan::-webkit-scrollbar-thumb {
  -webkit-box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.3);
  background-color: #cdcdcd;
}

.cont-opciones-plan::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.cont-opciones-plan::-webkit-scrollbar-track {
  background: #253341;
}

/* Handle */
.cont-opciones-plan::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
.cont-opciones-plan::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/*scroll opciones plan bukaera*/
/* GRUPOS PLANIFICADOR DRAG AND DROP AMAIERA */
/*tablet*/
/*escritorio*/
@media (min-width: 992px) {
  .plan-largo-colgrupo.col-lg-1 {
    flex: 0 0 4%;
    max-width: 4%;
  }

  .plan-largo-colgraf.col-lg-11 {
    flex: 0 0 96%;
    max-width: 96%;
  }
}
@media (min-width: 1400px) {
  .col-xxl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .col-xxl-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
}
@media (max-width: 1280px) {
  .home-contenedor-maquinas {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
}
@media (max-width: 1720px) {
  /*HOME BERRI BERRIA*/
  .home-contenedor-maq {
    flex: 50% 0 0;
    padding: 5px;
  }

  /*HOME BERRI BERRIA AMAIERA*/
  .ca-izq, .ca-der {
    width: 100%;
    max-width: 100%;
  }
}
@media (max-width: 1034px) {
  /*HOME BERRI BERRIA*/
  .home-contenedor-maquinas {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }

  .home-contenedor-maq {
    flex: 100% 0 0;
    padding: 5px;
  }

  /*HOME BERRI BERRIA AMAIERA*/
}
@media (max-width: 1500px) and (min-width: 1200px) {
  .form-horizontal.panelosoa {
    flex: 0 0 50%;
    max-width: 50%;
  }
}
/*solo móvil*/
@media (max-width: 768px) {
  /*HOME BERRI BERRIA*/
  .home-contenedor-maq {
    flex: 100% 0 0;
    padding: 5px;
  }

  /*HOME BERRI BERRIA AMAIERA*/
  .navbar-toggler-icon {
    color: #f7f7f7;
  }

  #usermenu {
    position: absolute;
    right: 50px;
    width: 145px;
    top: 0;
  }

  #usermenu ul.navbar-nav.navbar-nav-user.usermenu.mt-2 {
    display: block;
    margin: 0 !important;
  }

  #usermenu ul.navbar-nav.navbar-nav-user.usermenu.mt-2 li {
    width: 46px;
    display: inline-block;
    vertical-align: top;
  }

  .barramenu {
    height: 56px;
    overflow: hidden;
  }

  .barramenu:hover,
.barramenu.menu-zabalduta {
    width: 250px;
    height: 100vh;
    overflow-y: auto;
  }

  .menu-zabalera-blokeatu {
    /* display: none !important;*/
  }

  .contenedor.body-content {
    margin: 70px 0 0 0 !important;
    transition: 0.3s ease all;
  }

  #usermenu .dropdown-menu.dropdown-usuario.show {
    right: 0;
    left: auto;
    position: absolute;
  }

  .beheko-minimenu {
    width: 0;
    overflow: hidden;
  }
}