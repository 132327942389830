@font-face {
  font-family: 'zituikon';
  src:  url('fonts/zituikon.eot?q91rii');
  src:  url('fonts/zituikon.eot?q91rii#iefix') format('embedded-opentype'),
    url('fonts/zituikon.ttf?q91rii') format('truetype'),
    url('fonts/zituikon.woff?q91rii') format('woff'),
    url('fonts/zituikon.svg?q91rii#zituikon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'zituikon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-bonos:before {
  content: "\e9b2";
}
.icon-cabezal:before {
  content: "\e9b3";
}
.icon-calidad:before {
  content: "\e9b4";
}
.icon-contador:before {
  content: "\e9b5";
}
.icon-contador-manual:before {
  content: "\e9b6";
}
.icon-herramienta:before {
  content: "\e9b7";
}
.icon-hmi:before {
  content: "\e9b8";
}
.icon-iniciar-preparacin:before {
  content: "\e9b9";
}
.icon-mantenimiento:before {
  content: "\e9ba";
}
.icon-manuales:before {
  content: "\e9bb";
}
.icon-mensajera:before {
  content: "\e9bc";
}
.icon-observacin:before {
  content: "\e9bd";
}
.icon-parametro:before {
  content: "\e9be";
}
.icon-piezas-totales:before {
  content: "\e9bf";
}
.icon-alarma-1:before {
  content: "\e9c0";
}
.icon-alarma-2:before {
  content: "\e9c1";
}
.icon-camara:before {
  content: "\e9c2";
}
.icon-cambiar-alt:before {
  content: "\e9c3";
}
.icon-check-alt:before {
  content: "\e9c4";
}
.icon-cntrlasig:before {
  content: "\e9c5";
}
.icon-departamento:before {
  content: "\e9c6";
}
.icon-documento-alt:before {
  content: "\e9c7";
}
.icon-equis-alt-2:before {
  content: "\e9c8";
}
.icon-erp:before {
  content: "\e9c9";
}
.icon-flechaderecha-alt-3:before {
  content: "\e9ca";
}
.icon-of:before {
  content: "\e9cb";
}
.icon-operacion:before {
  content: "\e9cc";
}
.icon-pieza:before {
  content: "\e9cd";
}
.icon-piezas-malas:before {
  content: "\e9ce";
}
.icon-planif:before {
  content: "\e9cf";
}
.icon-play:before {
  content: "\e9d0";
}
.icon-rendimiento:before {
  content: "\e9d1";
}
.icon-subidadat:before {
  content: "\e9d2";
}
.icon-cuadros:before {
  content: "\e9a9";
}
.icon-cubo:before {
  content: "\e9a5";
}
.icon-listado:before {
  content: "\e9a6";
}
.icon-desplegar:before {
  content: "\e9a0";
}
.icon-equivalencia:before {
  content: "\e9a1";
}
.icon-peso:before {
  content: "\e9a2";
}
.icon-plegar:before {
  content: "\e9a3";
}
.icon-termometro2:before {
  content: "\e9a4";
}
.icon-cambiar:before {
  content: "\e98d";
}
.icon-desactivado:before {
  content: "\e98e";
}
.icon-editar-alt:before {
  content: "\e98f";
}
.icon-editguardar:before {
  content: "\e990";
}
.icon-eliminarlinea:before {
  content: "\e991";
}
.icon-filtroelimin:before {
  content: "\e992";
}
.icon-guardar:before {
  content: "\e993";
}
.icon-actividades:before {
  content: "\e994";
}
.icon-aadirnuevo:before {
  content: "\e995";
}
.icon-articulo:before {
  content: "\e996";
}
.icon-exclamacion:before {
  content: "\e997";
}
.icon-fax:before {
  content: "\e998";
}
.icon-home-alt:before {
  content: "\e999";
}
.icon-idiomas:before {
  content: "\e99a";
}
.icon-movil:before {
  content: "\e99b";
}
.icon-phone:before {
  content: "\e99c";
}
.icon-stock:before {
  content: "\e99d";
}
.icon-conectado-horizontal:before {
  content: "\e978";
}
.icon-conectado-vertical:before {
  content: "\e979";
}
.icon-conector-abajo:before {
  content: "\e97a";
}
.icon-conector-arriba:before {
  content: "\e97b";
}
.icon-conector-der:before {
  content: "\e97c";
}
.icon-conector-izq:before {
  content: "\e97d";
}
.icon-preparacion:before {
  content: "\e9d3";
}
.icon-ritmo:before {
  content: "\e9d4";
}
.icon-tiempo-actual:before {
  content: "\e9d5";
}
.icon-abrir:before {
  content: "\e9a7";
}
.icon-agregar:before {
  content: "\e9a8";
}
.icon-enlace-alt:before {
  content: "\e96f";
}
.icon-seleccionado:before {
  content: "\e983";
}
.icon-selector:before {
  content: "\e984";
}
.icon-instagram:before {
  content: "\e969";
}
.icon-facebook:before {
  content: "\e96a";
}
.icon-mensajear:before {
  content: "\e96b";
}
.icon-tiktok:before {
  content: "\e96c";
}
.icon-twitter:before {
  content: "\e96d";
}
.icon-youtube:before {
  content: "\e96e";
}
.icon-turnos:before {
  content: "\e9d6";
}
.icon-alerta:before {
  content: "\e962";
}
.icon-archivar:before {
  content: "\e963";
}
.icon-datosof:before {
  content: "\e964";
}
.icon-descarga:before {
  content: "\e965";
}
.icon-maquina:before {
  content: "\e966";
}
.icon-ritmoproduccion:before {
  content: "\e967";
}
.icon-tablero:before {
  content: "\e968";
}
.icon-ticket:before {
  content: "\e960";
}
.icon-adjunto:before {
  content: "\e953";
}
.icon-adjunto-alt:before {
  content: "\e954";
}
.icon-enlace:before {
  content: "\e955";
}
.icon-exportar:before {
  content: "\e956";
}
.icon-flechaabajo-alt-2:before {
  content: "\e957";
}
.icon-flechaarriba-alt-2:before {
  content: "\e958";
}
.icon-flechaderecha-alt-2:before {
  content: "\e959";
}
.icon-flechaizquierda-alt-2:before {
  content: "\e95a";
}
.icon-importar:before {
  content: "\e95b";
}
.icon-info:before {
  content: "\e95c";
}
.icon-pin:before {
  content: "\e95d";
}
.icon-novisible:before {
  content: "\e95e";
}
.icon-visible:before {
  content: "\e95f";
}
.icon-equis-alt:before {
  content: "\e99e";
}
.icon-usuario:before {
  content: "\e982";
}
.icon-consumo:before {
  content: "\e951";
}
.icon-temperatura:before {
  content: "\e952";
}
.icon-aforo:before {
  content: "\e94f";
}
.icon-euro:before {
  content: "\e950";
}
.icon-duplicar:before {
  content: "\e961";
}
.icon-telefono:before {
  content: "\e942";
}
.icon-ubicacion:before {
  content: "\e943";
}
.icon-cargando .path1:before {
  content: "\e9aa";
  color: rgb(83, 83, 83);
  opacity: 0.7;
}
.icon-cargando .path2:before {
  content: "\e9ab";
  margin-left: -0.93359375em;
  color: rgb(83, 83, 83);
  opacity: 0.6;
}
.icon-cargando .path3:before {
  content: "\e9ac";
  margin-left: -0.93359375em;
  color: rgb(83, 83, 83);
  opacity: 0.5;
}
.icon-cargando .path4:before {
  content: "\e9ad";
  margin-left: -0.93359375em;
  color: rgb(83, 83, 83);
  opacity: 0.4;
}
.icon-cargando .path5:before {
  content: "\e9ae";
  margin-left: -0.93359375em;
  color: rgb(83, 83, 83);
  opacity: 0.3;
}
.icon-cargando .path6:before {
  content: "\e9af";
  margin-left: -0.93359375em;
  color: rgb(83, 83, 83);
  opacity: 0.2;
}
.icon-cargando .path7:before {
  content: "\e9b0";
  margin-left: -0.93359375em;
  color: rgb(83, 83, 83);
  opacity: 0.1;
}
.icon-cargando .path8:before {
  content: "\e9b1";
  margin-left: -0.93359375em;
  color: rgb(83, 83, 83);
  opacity: 0.8;
}
.icon-campana:before {
  content: "\e99f";
}
.icon-editar:before {
  content: "\e97e";
}
.icon-sobre-alt:before {
  content: "\e97f";
}
.icon-sobre-alt-02:before {
  content: "\e980";
}
.icon-sobre-alt-03:before {
  content: "\e981";
}
.icon-sobre:before {
  content: "\e944";
}
.icon-flechaabajo-alt:before {
  content: "\e945";
}
.icon-flechaarriba-alt:before {
  content: "\e946";
}
.icon-flechaderecha-alt:before {
  content: "\e947";
}
.icon-flechaizquierda-alt:before {
  content: "\e948";
}
.icon-documento:before {
  content: "\e949";
}
.icon-excel:before {
  content: "\e94a";
}
.icon-pdf:before {
  content: "\e94b";
}
.icon-word:before {
  content: "\e94c";
}
.icon-responder:before {
  content: "\e94d";
}
.icon-carpeta:before {
  content: "\e94e";
}
.icon-circular-doble:before {
  content: "\e970";
}
.icon-circular-individual:before {
  content: "\e971";
}
.icon-grafico-barras:before {
  content: "\e972";
}
.icon-grafico:before {
  content: "\e973";
}
.icon-oee-barras:before {
  content: "\e974";
}
.icon-oee-individual:before {
  content: "\e975";
}
.icon-oee:before {
  content: "\e976";
}
.icon-tabla:before {
  content: "\e977";
}
.icon-clasificacion:before {
  content: "\e941";
}
.icon-agregaragente:before {
  content: "\e900";
}
.icon-ajustes:before {
  content: "\e901";
}
.icon-Apagar:before {
  content: "\e902";
}
.icon-bandera:before {
  content: "\e903";
}
.icon-bloqueado:before {
  content: "\e904";
}
.icon-buscar:before {
  content: "\e905";
}
.icon-buscar-alt:before {
  content: "\e906";
}
.icon-calendario:before {
  content: "\e907";
}
.icon-carrito:before {
  content: "\e908";
}
.icon-check:before {
  content: "\e909";
}
.icon-color:before {
  content: "\e90a";
}
.icon-columnas:before {
  content: "\e90b";
}
.icon-comentario-alt:before {
  content: "\e90c";
}
.icon-comentario:before {
  content: "\e90d";
}
.icon-compartir:before {
  content: "\e90e";
}
.icon-corazon-alt:before {
  content: "\e90f";
}
.icon-corazon:before {
  content: "\e910";
}
.icon-dependencias .path1:before {
  content: "\e911";
  color: rgb(0, 0, 0);
}
.icon-dependencias .path2:before {
  content: "\e912";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-dependencias .path3:before {
  content: "\e913";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-dependencias .path4:before {
  content: "\e914";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-dependencias .path5:before {
  content: "\e915";
  margin-left: -1em;
  color: rgb(235, 84, 104);
}
.icon-dependencias .path6:before {
  content: "\e916";
  margin-left: -1em;
  color: rgb(74, 211, 149);
}
.icon-dependencias .path7:before {
  content: "\e917";
  margin-left: -1em;
  color: rgb(117, 206, 248);
}
.icon-dependencias .path8:before {
  content: "\e918";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-dependencias .path9:before {
  content: "\e919";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-desbloqueado:before {
  content: "\e91a";
}
.icon-engranaje:before {
  content: "\e91b";
}
.icon-equis:before {
  content: "\e91c";
}
.icon-estrella-alt:before {
  content: "\e91d";
}
.icon-estrella:before {
  content: "\e91e";
}
.icon-filtro-alt:before {
  content: "\e91f";
}
.icon-filtro:before {
  content: "\e920";
}
.icon-flechaabajo:before {
  content: "\e921";
}
.icon-flechaarriba:before {
  content: "\e922";
}
.icon-flechaderecha:before {
  content: "\e923";
}
.icon-flechaizquierda:before {
  content: "\e924";
}
.icon-gota:before {
  content: "\e925";
}
.icon-hamburguer:before {
  content: "\e926";
}
.icon-home:before {
  content: "\e927";
}
.icon-label-alt:before {
  content: "\e928";
}
.icon-label:before {
  content: "\e929";
}
.icon-localizacion:before {
  content: "\e92a";
}
.icon-mas-alt:before {
  content: "\e92b";
}
.icon-mas:before {
  content: "\e92c";
}
.icon-menos-alt:before {
  content: "\e92d";
}
.icon-menos:before {
  content: "\e92e";
}
.icon-ordenar:before {
  content: "\e92f";
}
.icon-papelera:before {
  content: "\e930";
}
.icon-reloj:before {
  content: "\e931";
}
.icon-subnivel .path1:before {
  content: "\e932";
  color: rgb(221, 218, 236);
}
.icon-subnivel .path2:before {
  content: "\e933";
  margin-left: -1em;
  color: rgb(138, 194, 73);
}
.icon-subnivel .path3:before {
  content: "\e934";
  margin-left: -1em;
  color: rgb(43, 77, 102);
}
.icon-subnivel .path4:before {
  content: "\e935";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-subnivel .path5:before {
  content: "\e936";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-subnivel .path6:before {
  content: "\e937";
  margin-left: -1em;
  color: rgb(221, 218, 236);
}
.icon-subnivel .path7:before {
  content: "\e938";
  margin-left: -1em;
  color: rgb(1, 132, 191);
}
.icon-subnivel .path8:before {
  content: "\e939";
  margin-left: -1em;
  color: rgb(43, 77, 102);
}
.icon-subnivel .path9:before {
  content: "\e93a";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-subnivel .path10:before {
  content: "\e93b";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-subnivel .path11:before {
  content: "\e93c";
  margin-left: -1em;
  color: rgb(221, 218, 236);
}
.icon-subnivel .path12:before {
  content: "\e93d";
  margin-left: -1em;
  color: rgb(254, 203, 0);
}
.icon-subnivel .path13:before {
  content: "\e93e";
  margin-left: -1em;
  color: rgb(43, 77, 102);
}
.icon-subnivel .path14:before {
  content: "\e93f";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-subnivel .path15:before {
  content: "\e940";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-posicion:before {
  content: "\e985";
}
.icon-nivel:before {
  content: "\e986";
}
.icon-barometro:before {
  content: "\e987";
}
.icon-dato:before {
  content: "\e988";
}
.icon-nieve:before {
  content: "\e989";
}
.icon-objetivo:before {
  content: "\e98a";
}
.icon-presion:before {
  content: "\e98b";
}
.icon-termometro:before {
  content: "\e98c";
}
