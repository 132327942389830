* {
  /*font-family: 'Verdana', sans-serif;*/
}

div.machines-container {
  width: 190px!important;
  user-select: none;
  height: 100%;
}

  div.machines-container > div.machine {
    display: flex;
    white-space: nowrap;
    box-sizing: border-box;
    border-bottom: solid 1px #e5e5e5;
    padding-left: 7px;
    align-items: center;
    gap: 5px;
  }

    div.machines-container > div.machine:first-of-type {
      margin-top: 1px;
      border-top: solid 1px #e5e5e5;
    }

.gantt-chart-planificadorcorto .machine div:first-child {
  background-color: #22c4c442;
  height: 40px;
  /* padding: 6px 5px; */
  width: 50px;
  /* margin: 0 5px 0 0; */
  display: flex;
  align-items: center;
  border: solid 1px #22c4c4;
  justify-content: center;
}

.gantt-chart-planificadorcorto .machine div:nth-child(2) span {
  display: block;
  width: 113px;
  overflow: hidden;
  text-overflow: ellipsis;
}

div.machines-container > div.machine > div > img {
  width: auto;
  max-height: 32px;
  max-width: 40px;
}

div.machines-container > div.machine > div > span {
  color: #333333;
  font-size: 13px;
  font-weight: 600;
  white-space: nowrap;
  /* font-family: "Segoe UI", Arial, Helvetica, sans-serif; */
  font-family: "Roboto", sans-serif;
  margin-right: 15px;
}

svg.gantt-chart {
  user-select: none;
  overflow: visible;
  
}

  svg.gantt-chart > g.y.grid > g.tick > line,
  svg.gantt-chart > g.x.grid > g.tick > line,
  svg.gantt-chart > g.x.grid > path.domain {
    color: #e5e5e5;
    stroke: #e5e5e5;
  }

  .gantt-chart-planificadorcorto  g.x.grid g.tick line {
    stroke: #f7f7f7!important;
  }

  path.domain {
    stroke: #e5e5e5;
  }

  svg.gantt-chart > g.axis-bottom > g.tick > line {
    color: #e5e5e5;
    stroke: #e5e5e5;
    }

  svg.gantt-chart > g.task-container > g.not-allowed > rect {
    opacity: 0.20;
    cursor: no-drop;
  }

    svg.gantt-chart > g.task-container > g.not-allowed > rect:last-of-type {
      fill: #f44336;
    }

  svg.gantt-chart > g.task-container > g > rect:last-of-type {
    stroke: rgba(0,0,0,.3);
    stroke-width: .2;
  }

  svg.gantt-chart > g.task-container > g > rect:last-of-type.punto {
    stroke: rgba(0,0,0,0);
  }

  svg.gantt-chart > g.task-container > g > text.punto {
    fill: black;
  }

  /*  svg.gantt-chart > g.task-container > g > rect.resalt {
    stroke: #e91e63e6;
    stroke-width: 2px;
  }*/

  svg.gantt-chart > g.task-container > g {
    transition: opacity .2s;
  }

  .fondo-task{
    opacity: 0.3;
  }

  svg.gantt-chart > g.task-container > g.fadeout {
    opacity: 0.20;
  }


  svg.gantt-chart > g.task-container > g > text {
    /* font-family: Monaco, Menlo, Consolas, "Courier New", monospace; */
    font-family: "Roboto", sans-serif;
    pointer-events: none;
    text-anchor: start;
    font-size: 13px;
    fill: white;
  }
  
  .gantt-chart-planificadorcorto .gantt-chart{
    overflow: visible !important;
  }

  .gantt-chart-planificadorcorto svg.gantt-chart > g.task-container > g > text {
    /* font-family: Monaco, Menlo, Consolas, "Courier New", monospace; */
    font-family: "Roboto", sans-serif;
    pointer-events: none;
    text-anchor: start;
    font-size: 10px;
    fill: #3C3C3C;
    font-family: "Roboto Condensed";
    font-weight: 350;
  }

  .gantt-chart-planificadorcorto svg.gantt-chart > g.task-container > g > text.pieza {
    font-weight: 500;
  }

  svg.gantt-chart > g.task-container > g > text.refof {
    font-size: 11px;
    fill: white;
    font-weight: 500;
  }

  /* .parpadeo {
    animation-name: anim-parpadeo;
    animation-duration: 4s;
    animation-iteration-count: infinite;
  }

  @keyframes anim-parpadeo {
    0%   {opacity: 0}  
    50%  {opacity: 1}
    100% {opacity: 0}
  } */

  svg.gantt-chart > g.zoom-brush > g {
    font-size: 11px;
    pointer-events: none;
  }

  svg.gantt-chart > g.zoom-brush > image.handle--custom {
    cursor: ew-resize;
  }

  svg.gantt-chart > g.zoom-brush > rect.selection {
    cursor: grab;
  }

  svg.gantt-chart > g.zoom-brush > rect.overlay[cursor="move"] {
    cursor: grabbing;
  }

  svg.gantt-chart > g.zoom-brush > rect.bg {
    fill: #DFDFDF;
    stroke: #0000001a;
    stroke-width: 1px;
  }

  svg.gantt-chart > g.zoom-brush > rect.selection {
    stroke: none;
    fill: #EAEAEA;
    fill-opacity: 1;
  }

  svg.gantt-chart rect.zoom-rect {
    fill: #eaeaea7a;
    stroke: #00000085;
    stroke-width: 1px;
    stroke-dasharray: 9px;
  }

  svg.gantt-chart > g.show-all-button {
    cursor: pointer;
  }

    svg.gantt-chart > g.show-all-button:hover rect {
      opacity: 0.25;
    }

  /* TURNO DE NOCHE */
  svg.gantt-chart > g > g.delimiter.noche > rect {
    fill: #1D7076;
    stroke: #1D7076;
    fill-opacity: 0.1;
    stroke-opacity: 0.1;
  }

  svg.gantt-chart > g > g.delimiter.noche > line {
    stroke: #3e3e3e;
    stroke-width: 2;
    display: none;
  }
  /* TURNO DE TARDE */
  svg.gantt-chart > g > g.delimiter.tarde > rect {
    fill: #D9E497;
    stroke: #D9E497;
    fill-opacity: 0.15;
    stroke-opacity: 0.15;
  }

  svg.gantt-chart > g > g.delimiter.tarde > line {
    stroke: #3e3e3e;
    stroke-width: 2;
    display: none;
  }
  /* TURNO DE MAÑANA */
  svg.gantt-chart > g > g.delimiter.mañana > rect {
    fill: #99F8FF;
    stroke: #99F8FF;
    fill-opacity: 0.1;
    stroke-opacity: 0.1;
  }

  svg.gantt-chart > g > g.delimiter.mañana > line {
    stroke: #3e3e3e;
    stroke-width: 2;
    display: none;
  }

body div.tooltip-gantt {
  font-size: 11px;
  background-color: white;
  border: 3px solid #48328b;
  padding: 15px 5px 5px 5px;
  position: relative;
  z-index: 9;
}

body div.tooltip-gantt {
  z-index: 1501 !important;
}

.gantt-popup .gantt-chart-planificadorcorto {
  overflow: hidden;
}

body.iluna div.tooltip-gantt {
  background-color: #3b434e;
}

body div.tooltip-gantt > div.arrow {
  position: absolute;
  bottom: -20px;
  left: calc(50% - 20px);
  width: 0;
  height: 0;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  border-top: 20px solid #f00;
}
body div.tooltip-gantt {
  pointer-events: none;
}

body div.tooltip-gantt.bottom-reverse > div.arrow {
  top: -20px;
  transform: rotate(180deg);
}

body div.tooltip-gantt > p {
  line-height: 0px;
  margin: 0;
}

  body div.tooltip-gantt > p ul {
    list-style: none;
    margin: 0px;
    padding-left: 13px;
    margin-bottom: 6px;
  }

span.tooltip-title {
  color: #22c4c4;
}

.pointer-none {
  pointer-events: none;
}