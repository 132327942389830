@charset "UTF-8";
body.iluna {
  background-color: #323a46;
  color: #dee2e6;
}

body.iluna .barramenu {
  background-color: #3b434e;
  border-right: solid 1px #4a4e52;
}

body.iluna .card {
  background-color: #3b434e;
  color: #dee2e6;
}

body.iluna .cont-select-maquinas-out .cont-select-maquinas {
  background-color: #3b434e;
}

body.iluna .cont-select-maquinas-out .desplegar-selector-maquina {
  background-color: #3b434e;
}

body.iluna .text-select-maquinas {
  color: #dee2e6;
}

body.iluna a.btn {
  color: #dee2e6;
}

body.iluna .c3 path.c3-shape.c3-shape.c3-arc {
  stroke: #3b434e !important;
}

body.iluna .plegarpanel {
  border-bottom: solid 3px #c5c5c5;
  border-left: solid 3px #c5c5c5;
}

body.iluna .dropdown-menu {
  background-color: #323a46;
}

body.iluna .dropdown-menu {
  color: #dee2e6;
}

body.iluna .panelosoa .inner-panel {
  background-color: #3b434e;
}

body.iluna .panel-of {
  background-color: #52b8c71a;
}

body.iluna .estado-parada.contenedor-eje {
  background-color: rgba(231, 203, 104, 0.26);
}

.body.iluna .estado-alarma.contenedor-eje {
  background-color: rgba(211, 55, 55, 0.25);
}

body.iluna .estado-ejecucion.contenedor-eje {
  background-color: #bbe0d254;
}

body.iluna .datos-of-content li:nth-of-type(odd) {
  background: #323a46;
}

body.iluna .datos-of-content li:nth-of-type(2n) {
  background: #434b56;
}

body.iluna .panelosoa .inner-panel-izq .modelo-maquina {
  color: #dee2e6;
}

body.iluna .panelosoa .usuario img {
  filter: invert(1);
}

/*form*/
body.iluna .form-control {
  /*   color: $color-texto;
   background-color: $color-input;
  border: 1px solid $color-fondo;*/
}

/*body.iluna input,
body.iluna .k-input,
body.iluna input:hover,
body.iluna input:focus,*/
body.iluna .k-dropdown .k-dropdown-wrap,
body.iluna .k-dropdowntree .k-dropdown-wrap,
body.iluna textarea,
body.iluna textarea:hover,
body.iluna .k-upload,
body.iluna span.k-select,
body.iluna .k-animation-container,
body.iluna .k-numerictextbox .k-numeric-wrap,
body.iluna .k-textbox,
body.iluna .k-textbox:hover,
body.iluna .k-textbox.k-state-hover,
body.iluna .k-combobox > :hover,
body.iluna .k-combobox .k-state-hover,
body.iluna .k-combobox .k-dropdown-wrap {
  background-color: #434b56;
  border: 1px solid #4b5460 !important;
  color: #dee2e6;
  background-image: none;
}

body.iluna .k-numerictextbox .k-select .k-link:hover,
body.iluna .k-numerictextbox .k-select .k-link.k-state-hover,
body.iluna .k-combobox > :hover .k-select,
body.iluna .k-combobox .k-state-hover .k-select {
  background-color: #434b56;
}

body.iluna kendo-multiselect .k-button {
  background-color: #323a46;
}

body.iluna .k-content {
  /*border-color: rgba(0, 0, 0, 0.08);*/
  color: #dee2e6;
  background-color: #434b56 !important;
}

body.iluna .k-calendar-infinite .k-calendar-header {
  background-color: #434b56 !important;
}

body.iluna .k-calendar-infinite .k-calendar-view,
body.iluna .k-calendar-infinite .k-calendar-monthview {
  background-color: #434b56 !important;
  color: #dee2e6 !important;
}

body.iluna .k-multiselect-wrap.k-floatwrap kendo-searchbar.k-searchbar input.k-input {
  border-color: #434b56 !important;
  color: #dee2e6;
}

body.iluna .k-multiselect .k-multiselect-wrap::before,
body.iluna .k-dropdowntree .k-multiselect-wrap::before {
  color: #dee2e6;
}

body.iluna .k-button, body.iluna .k-button-secondary {
  color: #dee2e6 !important;
  /*background-color: $color-input !important;*/
  border-width: 0 !important;
}

body.iluna .k-dropdown-wrap > .k-input, body.iluna .k-dropdown .k-dropdown-wrap .k-select, body.iluna .k-list, body.iluna .k-reset {
  background-color: #434b56 !important;
  color: #dee2e6;
}

body.iluna ::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #dee2e6;
}

body.iluna :-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #dee2e6;
}

body.iluna ::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #dee2e6;
}

body.iluna kendo-datepicker.form-control.k-widget.k-datepicker,
body.iluna kendo-dropdownlist.form-control {
  background: transparent;
  border: 0;
}

body.iluna .form-control:focus {
  color: #dee2e6;
}

body.iluna .k-picker-wrap .k-dateinput .k-dateinput-wrap {
  color: #dee2e6;
  background-color: #434b56;
}

body.iluna .k-multiselect .k-multiselect-wrap,
body.iluna .k-dropdowntree .k-multiselect-wrap {
  background-color: #434b56;
}

body.iluna .k-upload .k-dropzone .k-upload-status,
body.iluna .k-upload .k-dropzone .k-dropzone-hint {
  color: #dee2e6;
}

body.iluna .form-control {
  /*  background-color: transparent;*/
}

body.iluna .k-button.k-upload-button {
  background: #3b434e;
  color: #dee2e6;
  margin: 0 13px 0 0 !important;
}

body.iluna .k-combobox.k-combobox-clearable .k-input::placeholder {
  color: #dee2e6;
  opacity: 0.5;
}

body.iluna .k-i-close::before {
  color: #dee2e6;
}

body.iluna .k-switch-off .k-switch-container:hover {
  background-color: #323a46;
}

body.iluna .k-switch-off .k-switch-container {
  background-color: #434b56;
}

body.iluna .k-switch-on .k-switch-handle,
body.iluna .k-switch-on:hover .k-switch-handle,
body.iluna .k-switch-on.k-state-hover .k-switch-handle,
body.iluna .k-switch-off .k-switch-handle,
body.iluna .k-switch-off .k-switch-handle:hover {
  background-color: #323a46;
  border-color: #434b56;
}

body.iluna .kendo-switch-tipo2.k-switch-on .k-switch-handle,
body.iluna .kendo-switch-tipo2.k-switch-on:hover .k-switch-handle,
body.iluna .kendo-switch-tipo2.k-switch-on.k-state-hover .k-switch-handle,
body.iluna .kendo-switch-tipo2.k-switch-off .k-switch-handle,
body.iluna .kendo-switch-tipo2.k-switch-off:hover .k-switch-handle,
body.iluna .kendo-switch-tipo2.k-switch-off.k-state-hover .k-switch-handle {
  border-color: rgba(0, 0, 0, 0);
  color: #ffffff;
  background-color: transparent;
}

/*form amaiera*/
/*filtro calendario*/
body.iluna .calendarFiltro-input i {
  color: #dee2e6;
}

body.iluna .calendarFiltro-input {
  background-color: #434b56;
}

body.iluna .calendarFiltro-calendario,
body.iluna .k-calendar {
  background-color: #434b56;
}

body.iluna .calendarFiltro-calendario .k-calendar .k-calendar-view .k-today span.k-link,
body.iluna .calendarFiltro-calendario .k-calendar .k-calendar-view .k-state-selected span.k-link {
  color: #dee2e6;
}

body.iluna .k-calendar .k-calendar-td:hover .k-link,
body.iluna .k-calendar .k-calendar-td.k-state-hover .k-link {
  background-color: #3b434e;
  color: #dee2e6;
}

/*filtro calendario amaiera*/
/*GRID*/
body.iluna .k-radio,
body.iluna .k-radio::before,
body.iluna input.k-checkbox {
  background: #434b56;
  border-color: #323a46;
}

body.iluna .k-grid .k-grid-filter,
body.iluna .k-grid .k-header-column-menu,
body.iluna .k-grid .k-hierarchy-cell .k-icon {
  color: #dee2e6;
}

body.iluna th.k-header {
  color: #dee2e6;
}

body.iluna .k-grid {
  background-color: #3b434e;
  color: #dee2e6;
}

body.iluna span.k-icon.k-i-filter,
body.iluna .k-grid th.k-header.k-filterable span.k-link {
  color: #dee2e6;
}

body.iluna .k-popup.k-grid-filter-popup {
  background-color: #3b434e;
}

body.iluna .k-grid tbody tr:hover,
body.iluna .k-grid tbody tr.k-state-hover,
body.iluna .k-grid-header .k-grid-filter:hover,
body.iluna .k-grid-header .k-header-column-menu:hover,
body.iluna .k-grid-header .k-hierarchy-cell .k-icon:hover {
  color: #dee2e6;
  background-color: #434b56;
}

body.iluna .k-grid-content {
  background-color: transparent;
}

body.iluna .k-pager-wrap {
  color: #dee2e6;
  background-color: #323a46;
}

body.iluna .k-toolbar {
  background-color: #323a46 !important;
}

body.iluna .bloques-info-aae {
  background-color: #39404b;
}

body.iluna .k-grid-header,
body.iluna .k-grouping-header,
body.iluna .k-grid-add-row,
body.iluna .k-grid-footer {
  color: #dee2e6 !important;
  background-color: #323a46 !important;
}

body.iluna .label-der,
body.iluna .label-izq {
  color: #dee2e6;
}

/*scroll bereziak grid*/
body.iluna .k-grid-content::-webkit-scrollbar-track {
  background: #434b56;
}

/*scroll bereziak grid amaiera*/
body.iluna .k-grid-table-wrap tr {
  background-color: #323a46;
}

body.iluna .k-grid-table-wrap tr.k-alt {
  background-color: #39404b;
}

body.iluna .k-grid tbody td {
  border-bottom: solid 1px #253341;
}

/*GRID amaiera*/
/*Gráficos*/
body.iluna tspan {
  fill: #dee2e6;
}

body.iluna .c3 line, body.iluna .c3 path {
  stroke: #dee2e6;
}

/*Gráficos amaiera*/
/*tabs*/
body.iluna .k-state-active span.k-link {
  color: #dee2e6;
}

body.iluna .nav-tabs .nav-item.show .nav-link,
body.iluna .nav-tabs .nav-link.active {
  color: #dee2e6;
  background-color: #3b434e;
  border-color: #4b5460;
}

body.iluna .k-tabstrip > .k-content {
  background-color: #3b434e;
  color: #dee2e6;
}

body.iluna .cont-graf {
  color: #dee2e6;
}

/*tabs*/
/*pop up*/
body.iluna .modal-content {
  background-color: #3b434e;
}

body.iluna .close {
  color: #dee2e6;
}

/*pop up*/
body.iluna .bloques-datos {
  background-color: #323a46;
  padding: 5px 10px;
  margin: 5px !important;
}

body.iluna div#gantt-chart .machines-container .machine span {
  color: #dee2e6;
}

body.iluna svg.gantt-chart > g.zoom-brush > rect.selection {
  stroke: none;
  fill: #3b434e;
  fill-opacity: 1;
}

body.iluna g.tick text {
  fill: #dee2e6;
}

body.iluna g.tick line {
  stroke: #434b56;
}

body.iluna g.task-container text {
  fill: #323a46 !important;
}

/* GRAFIKOAK */
body.iluna .c3-shapes.c3-bars > path {
  stroke: #3b434e !important;
}

body.iluna .c3-shapes.c3-lines > path {
  /* stroke: #FFFFFF !important;*/
  /*oeekoak bakoitza bere kolorea du*/
}

body.iluna g.c3-legend-item text {
  fill: #dee2e6 !important;
}

body.iluna text.c3-chart-arcs-title {
  fill: #dee2e6 !important;
}

body.iluna button.leyenda-chart-label {
  color: #dee2e6;
}

/* GRAFIKOAK AMAIERA */
body.iluna .leyendas-top {
  background-color: #3b434e;
}

body.iluna .bloques-info {
  /*background-color: $color-fondo;*/
  background-color: transparent;
}

body.iluna label.nombregrupo {
  background-color: #2c3440;
}

body.iluna .cont-inner-nombre-maquina {
  background-color: #323a46;
}

body.iluna label.nombremaquina {
  background-color: #323a46;
}

body.iluna .col-sm-10.col-md-10.col-lg-11:before {
  background-color: #323a46;
}

body.iluna .planificador-limite-proyecto-maquinas .maquinaLimites {
  background-color: #323a46;
}

body.iluna .informe-comparativa .card-body label {
  background-color: #323a46;
}

body.iluna .panel-herramientas {
  background-color: #3b434e;
}

body.iluna .comandoprogramas {
  background-color: #3b434e;
}

body.iluna .panel-procesos {
  background-color: #3b434e;
}

body.iluna .k-tabstrip-items .k-item.k-state-active,
body.iluna .k-tabstrip-items .k-item.k-state-selected {
  background-color: #3b434e;
}

body.iluna .k-tabstrip-items .k-item,
body.iluna .k-tabstrip-items .k-item {
  background-color: #2e343e;
  border-left: solid 1px #323a46;
  border-top: solid 1px #323a46;
}

body.iluna .outer-cont-donut {
  background-color: #2e343e;
}

body.iluna .outer-cont-donut label.valor-poten {
  color: #dee2e6;
}

body.iluna .outer-cont-donut text {
  fill: #dee2e6;
}

body.iluna .gridFilaRoja {
  background-color: #e89090 !important;
}

body.iluna .historial {
  background-color: #3b434e;
}

body.iluna div.machines-container > div.machine > div > span {
  color: #dee2e6;
}

body.iluna .valores-abajo {
  border-top: solid 1px #323a46;
  background: #3b434e;
}

body.iluna .modal-content {
  background: #3b434e;
}

body.iluna .tablas-comparativas {
  color: #ffffff;
}

body.iluna td.datos.colalt,
body.iluna .tablas-comparativas.titulo-tablas-comparativas .datos.colalt {
  background-color: #253341;
}

body.iluna .tablas-comparativas .datos {
  background-color: #3b434e;
  border-right: solid 5px #323a46;
}

body.iluna .filtro-seleccionados .k-textbox,
body.iluna .filtro-seleccionados .k-dropdown .k-dropdown-wrap,
body.iluna .filtro-seleccionados .k-dropdowntree .k-dropdown-wrap,
body.iluna .filtro-seleccionados .k-numerictextbox .k-numeric-wrap,
body.iluna .filtro-seleccionados .calendarFiltro-input,
body.iluna .filtro-seleccionados .k-multiselect .k-multiselect-wrap,
body.iluna .filtro-seleccionados .k-dropdowntree .k-multiselect-wrap {
  border-color: rgba(34, 196, 196, 0.5);
  background: rgba(34, 196, 196, 0.2);
}

body.iluna .tipo-grid-linea:nth-of-type(even) {
  background: #39404b;
}

body.iluna .tipo-grid-linea {
  border-bottom: solid 1px #253341;
  background-color: #323a46;
  padding: 5px;
}

body.iluna .contenedor-valores-tecnologicos-inyectora {
  background: #434b56;
}

body.iluna .ejecucion.home-contenedor-maq-inner,
body.iluna .preparacion.home-contenedor-maq-inner,
body.iluna .alarma.home-contenedor-maq-inner,
body.iluna .apagada.home-contenedor-maq-inner,
body.iluna .parada.home-contenedor-maq-inner,
body.iluna .mantenimiento.home-contenedor-maq-inner {
  color: #CCCCCC;
}
body.iluna .home-piezas-totales-operacion .valor,
body.iluna .home-contador-operacion .valor,
body.iluna .home-manual-operacion .valor,
body.iluna .home-malas-operacion .valor {
  color: #ffffff;
}
body.iluna label.home-tiempo,
body.iluna .home-valor-grande,
body.iluna .home-tiempo-grande:not(.home-tiempo-inferior) {
  color: #ffffff;
}
body.iluna .home-contenedor-maq-inner {
  background-color: #3C424E;
}
body.iluna .home-contenedor-maq-inner .home-informacion-maquina {
  border-color: #888888;
}
body.iluna .home-contenedor-maq-inner .home-nombre-maquina label,
body.iluna .home-contenedor-maq-inner .home-operario-maquina label,
body.iluna .home-contenedor-maq-inner .home-tiempo-estado-maquina label {
  color: #FFFFFF;
}
body.iluna .home-contenedor-maq-inner .home-informacion-operacion ul li {
  border-color: #888888;
}
body.iluna .home-contenedor-maq-inner .home-informacion-operacion.tiene-linea-inferior label {
  color: #CCCCCC;
}
body.iluna .home-contenedor-maq-inner .home-contenedor-porcentaje {
  background-color: #888888;
}
body.iluna .home-contenedor-maq-inner .home-barra-progreso {
  background-color: #CCCCCC;
}
body.iluna .home-contenedor-maq-inner label.home-label-progreso {
  color: #3C424E;
}
body.iluna .home-contenedor-maq-inner .home-circulo-porcentaje-cont:before {
  background-color: #3C424E;
}
body.iluna .home-contenedor-maq-inner .cont-titulo-actual:after,
body.iluna .home-contenedor-maq-inner .home-preparacion-pieza-lote:not(:last-child) .home-titulo-vertical:before,
body.iluna .home-contenedor-maq-inner .home-piezas-totales-operacion:after,
body.iluna .home-contenedor-maq-inner .home-contador-operacion:after,
body.iluna .home-contenedor-maq-inner .home-manual-operacion:after,
body.iluna .home-contenedor-maq-inner .cont-titulo-ritmo:before {
  background-color: #888888;
}
body.iluna .home-contenedor-maq-inner .etiqueta.home-fondo-rojo {
  background: rgba(255, 99, 71, 0.6);
  color: #ffffff;
}
body.iluna .home-contenedor-maq-inner .etiqueta.home-fondo-verde {
  background: rgba(0, 150, 128, 0.6);
  color: #ffffff;
}
body.iluna .home-contenedor-maq-inner .home-circulo-porcentaje-hora-cont.home-fondo-rojo .home-circulo-porcentaje,
body.iluna .home-contenedor-maq-inner .home-circulo-porcentaje-cont.home-fondo-verde .home-circulo-porcentaje {
  background: rgba(0, 150, 128, 0.6);
  color: #ffffff;
}
body.iluna .home-informacion-configurable,
body.iluna .home-preparacion-pieza-lote:not(:last-child),
body.iluna .home-cantidades-operacion,
body.iluna .home-datos-actual {
  border-color: #888888;
}
body.iluna .home-preparacion-pieza-lote .home-titulo-vertical .borde-fake.borde-degradado-top {
  background: linear-gradient(to top, rgba(136, 136, 136, 0) 0%, #888888 98%);
}
body.iluna .home-preparacion-pieza-lote .home-titulo-vertical .borde-fake.borde-degradado-bottom {
  background: linear-gradient(to bottom, rgba(136, 136, 136, 0) 0%, #888888 98%);
}
body.iluna .home-preparacion-pieza-lote .home-titulo-vertical .borde-fake {
  background: #888888;
}
body.iluna .home-cuatro-graficos.donuts-ilunak {
  display: block !important;
}
body.iluna .home-cuatro-graficos.donuts-argiak {
  display: none !important;
}
body.iluna .home-donut {
  background: radial-gradient(closest-side, #3c424e 79%, transparent 80% 100%), conic-gradient(transparent 95%, #3c424e 0), conic-gradient(#CCCCCC 0%, #888888 0);
}